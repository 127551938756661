import React from "react";
import { StyleSheet, View } from "react-native";
import { TextInput } from "react-native-picasso";
import { Colors } from "../../../theme";
import CFTButton from "../Button/cft-button.component";

export type NumericInputProps = {
  value: number;
  onValueChanged: (newValue: number) => void;
  maxLimit: number;
};

// Uncontrolled numeric input
export default function NumericInput({
  value,
  onValueChanged,
  maxLimit,
}: NumericInputProps) {
  const sanitizeInput = (input: string) => {
    // Remove anything that is not a digit and convert to a number
    const val = input.replace(/[^0-9]/gi, "");
    if (parseInt(val) > maxLimit) { return maxLimit; }
    return parseInt(val);
  };

  const onChange = (text: string) => {
    onValueChanged(sanitizeInput(text));
  };

  const increment = () => { if (value === maxLimit) { return; } onValueChanged(value + 1); }
  const decrement = () => { onValueChanged(value > 0 ? value - 1 : 0); }

  return (
    <View>
      <TextInput
        keyboardType="numeric"
        onChangeText={onChange}
        value={"" + value}
        style={styles.textInput}
        maxLength={10}
      />
      <View style={styles.buttons}>
        <CFTButton
          buttonStyle={styles.incDecButton}
          textColor={Colors.darkGrey}
          text="-"
          onPress={decrement}
        />
        <CFTButton
          buttonStyle={styles.incDecButton}
          textColor={Colors.iceBlue}
          text="+"
          onPress={increment}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  textInput: {
    flex: 1,
    minHeight: 40,
    padding: 10,
    paddingLeft: 15,
    borderRadius: 4,
    backgroundColor: Colors.white,
    color: Colors.darkGrey,
  },
  buttons: {
    position: "absolute",
    right: 0,
    top: -7,
    height: "100%",
    flexDirection: "row",
  },
  incDecButton: {
    backgroundColor: "transparent",
    borderRadius: 0,
    width: 30,
    height: 30,
    padding: 0,
    marginHorizontal: 0,
  },
});
