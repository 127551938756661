import React from 'react';

const Emoji = props => (
    <span
        role="img"
        aria-label={props.label ? props.label : ""}
        aria-hidden={props.label ? "false" : "true"}
        style={{
            fontSize: 20,
            padding: 5,
            fontFamily: "Poppins",
            color: "white"
        }}
    >
        {props.symbol}
    </span>);

export default Emoji;