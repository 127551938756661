import { View } from "react-native-picasso";
import React, { useContext } from "react";
import { Image, ImageBackground } from "react-native";
import { styles } from "./onboarding-common.styles";
import { PageSizeContext } from "../../../contexts/page-size-context";
import GradientBackground from "../../../components/Background/gradient-background.component";

export default function OnboardingBackground({ children }) {
  const { height } = useContext(PageSizeContext);

  // Prevent the ClimAct text from overflowing off the top of the screen on
  // small devices by ensuring the top section is at least 200px tall
  const topOffset = Math.max(height * 0.25, 200);
  const contentHeight = Math.min(height * 0.75, height - 200);

  return (
    <>
      <GradientBackground />
      <View style={[styles.content, { top: topOffset, height: contentHeight }]}>
        <ImageBackground
          source={require("../../../../assets/shapes-bg.png")}
          style={{ height: "100%" }}
        >
          <View style={styles.centerAlignContainer}>
            <Image
              source={require("../../../../assets/images/climact-logo.png")}
              style={styles.backgroundClimactLogo}
              resizeMode="contain"
            />
            <Image
              source={require("../../../../assets/penguin.png")}
              resizeMode="center"
              style={styles.backgroundPenguin}
            />
          </View>

          <View style={styles.children}>{children}</View>
        </ImageBackground>
      </View>
    </>
  );
}
