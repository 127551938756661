import { StyleSheet } from "react-native";
import { Colors } from "../../../theme";

export const styles = StyleSheet.create({
  textInputContainer: {
    flex: 0,
    flexBasis: 60,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: "transparent",
    color: "white",
    borderColor: "white",
    borderWidth: StyleSheet.hairlineWidth,
    paddingHorizontal: 10,
    marginBottom: 5,
    borderRadius: 16,
    minHeight: 35,
  },
  textInput: {
    color: Colors.white,
    flex: 1,
  },
  textInputIcon: {
    width: 22,
    height: 22,
    marginHorizontal: 10,
  },
});
