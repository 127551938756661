import React, { useState } from "react";
import { ScrollView, StyleSheet, FlatList, Image, Modal, Pressable } from "react-native";
import { Text, View } from "react-native-picasso";
import { Colors } from "../../../../theme";
import ChallengesBody from "../../Challenges/Common/challenges-body.component";
import { stringsEng } from "../../../../assets/text/strings-english";
import ChallengesBackground from "../../Challenges/Common/challenges-background.component";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/userSlice";
import { modalStyles } from "../../../components/Modal/modal.styles";
import Emoji from "../../../components/Emoji/emoji.component";
import GradientBackground from "../../../components/Background/gradient-background.component";
import PressableImage from "../../../components/Button/pressable-image.component";
import ClassService from "../../../services/class.service";

export function DropDown({ studentId, classCode, showStudent }) {
    const [open, setOpen] = useState(false);
    const [hoverB, setHoverB] = useState(false)

    function handleClick() {
        setOpen(!open)
    }

    return (
        <View style={styles.link}>
            <PressableImage onPress={() => { handleClick() }} imageStyle={styles.contextMenu}
                imageSource={require("../../../../assets/images/icons/icon-other.png")} />
            <View style={[styles.menu, open ? styles.menuopen : '']}>
                <View style={styles.list}>
                    <Text style={[styles.item, { background: hoverB ? Colors.lightGrey : Colors.white }]} onMouseEnter={() => setHoverB(true)} onMouseLeave={() => setHoverB(false)} onPress={async () => { await ClassService.removeStudent(classCode, studentId); showStudent(false) }}>Remove Student</Text>
                </View>
            </View>
        </View>
    )

}

export function StudentItem({ item, classCode }) {
    const [hideStudent, setHideStudent] = useState(true)
    return (<>
        <View style={[styles.listItem, { display: hideStudent ? "flex" : "none" }]}>
            <View style={styles.userInfo}>
                <Image style={styles.profilePic} source={require("../../../../assets/images/icons/avatar_student.png")}></Image>
                <Text style={styles.listItemText}>{item.firstName} {item.lastName}</Text>
            </View>
            <DropDown studentId={item.user} classCode={classCode} showStudent={setHideStudent} />
        </View>
    </>
    )

}


export default function ClassStudentList({ navigation }) {
    const user = useSelector(selectUser);

    //type error because i added temporary value teacherClassData; might make permanenet later
    let students = user.teacherClassData.students;
    let classCode = user.teacherClassData.class.classCode;

    students = [...students].sort((a, b) => a.lastName.toUpperCase() > b.lastName.toUpperCase())

    const [modalVisible, setModalVisible] = useState(false);
    const [modalText, setModalText] = useState("");
    const [modalEmoji, setModalEmoji] = useState("");

    function renderItem({ item }) {
        return (
            <StudentItem classCode={classCode} item={item} />
        )
    };

    return (
        <>
            <GradientBackground />
            <ChallengesBackground navigation={navigation}>
                <ChallengesBody backgroundColor={Colors.lightGrey}>
                    <ScrollView contentContainerStyle={styles.scroll}>

                        <View>
                            <View style={styles.headerBox}>
                                <Text style={styles.header}>{stringsEng.TEACHER.STUDENT_LIST.TEACHER}</Text>
                            </View>
                            <View style={styles.listItem}>
                                <View style={styles.userInfo}>
                                    <Image style={styles.profilePic} source={require("../../../../assets/images/icons/avatar_student.png")}></Image>
                                    <Text style={styles.listItemText}>{user.firstName} {user.lastName}</Text>
                                </View>
                            </View>
                        </View>

                        <View style={styles.studentContainer}>
                            <View style={styles.headerBox}>
                                <Text style={styles.header}>{stringsEng.TEACHER.STUDENT_LIST.STUDENTS}</Text>
                                <PressableImage onPress={() => {
                                    navigator.clipboard.writeText(classCode)
                                    setModalEmoji(
                                        stringsEng.TEACHER.STUDENT_LIST.MODAL_CONFIRM.concat(stringsEng.TEACHER.STUDENT_LIST.MODAL_EMOJI )
                                    );
                                    setModalText(stringsEng.TEACHER.STUDENT_LIST.MODAL_MESSAGE);
                                    setModalVisible(true);
                                }} imageStyle={styles.addStudent}
                                    imageSource={require("../../../../assets/images/icons/add-student.png")} />

                            </View>
                            <FlatList
                                data={students}
                                renderItem={renderItem}
                                keyExtractor={(item) => item.user}
                            />

                        </View>


                    </ScrollView>

                    <Modal
                        animationType="slide"
                        transparent={true}
                        visible={modalVisible}
                        onRequestClose={() => {
                            setModalVisible(!modalVisible);
                        }}
                    >
                        <View style={modalStyles.centeredView}>
                            <View style={modalStyles.modalView}>
                                <Text style={modalStyles.modalText}>{modalText}</Text>
                                <Pressable
                                    style={[modalStyles.button, modalStyles.buttonClose]}
                                    onPress={() => {
                                        setModalVisible(!modalVisible);
                                    }}
                                >
                                    <Emoji symbol={modalEmoji} />
                                </Pressable>
                            </View>
                        </View>
                    </Modal>
                </ChallengesBody>
            </ChallengesBackground>
        </>
    );
}

const styles = StyleSheet.create({
    studentContainer: {
        width: "100%",
        height: "100%",
        overflow: "visible"
    },
    scroll: {
        margin: 10,
        width: "100%"
    },
    profilePic: {
        backgroundColor: Colors.darkGrey,
        borderRadius: 100,
        height: 35,
        width: 35,
        zIndex: -1,
        alignSelf: "center"

    },
    headerBox: {
        borderColor: Colors.deepSkyBlue,
        borderBottomColor: Colors.deepSkyBlue,
        borderBottomWidth: 1,
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center"
    },
    listItem: {
        flexDirection: "row",
        flexWrap: "nowrap",
        justifyContent: "space-between",
        padding: 5,
        alignItems: "center",
        margin: 5,
        width: "100%"
    },
    userInfo: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    listItemText: {
        color: Colors.darkGrey,
        whiteSpace: "nowrap",
        zIndex: -10,
        position: "absolute",
        left: 50
    },
    header: {
        color: Colors.deepSkyBlue,
        fontSize: 24,
        fontFamily: "Poppins-semibold"
    },
    addStudent: {
        height: 20,
        width: 20,
        marginRight: 15
    },
    contextMenu: {
        transform: [{ rotate: "90deg" }],
        height: 25,
        width: 25,
        marginRight: 10,
        tintColor: Colors.darkGrey,
    },
    link: {
        textAlign: "center",
        cursor: "pointer",
    },

    menu: {
        opacity: 0,
        position: "absolute",
        right: 25,
        backgroundColor: Colors.white,
        padding: 10,
        textAlign: "center",
        transition: "all 200ms ease",
        borderRadius: 15,
        shadowColor: Colors.darkGrey,
        shadowOpacity: 0.3,
        shadowRadius: 5,
        zIndex: 10000000
    },

    menuopen: {
        opacity: 1,
        zIndex: 10,
    },

    item: {
        padding: 5,
        whiteSpace: "nowrap",
        zIndex: 10,
    },
    list: {
        margin: 0,
        padding: 0,
        textAlign: "left",
        zIndex: 10,

    },

});


