import { EquivalentData } from "../../src/pages/QuizResults/QuizResultsEquivalents/quiz-results-equivalents.page";
import { Colors } from "../../theme";
import { stringsEng } from "./strings-english";

export const Equivalences: Array<EquivalentData> = [
  //Charged Smartphones
  {
    bgColour: Colors.red,
    ratio: 110352,
    text1: stringsEng.QUIZ_RESULT.QUIZ_RESULTS_1.EQUIVALENCES.PHONE_1,
    text2: stringsEng.QUIZ_RESULT.QUIZ_RESULTS_1.EQUIVALENCES.PHONE_2,
  },
  //Km Driven in a Car
  {
    bgColour: Colors.red,
    ratio: 3624,
    text1: stringsEng.QUIZ_RESULT.QUIZ_RESULTS_1.EQUIVALENCES.CAR_1,
    text2: stringsEng.QUIZ_RESULT.QUIZ_RESULTS_1.EQUIVALENCES.CAR_2,
  },
  //Tree Seedlings Grown
  {
    bgColour: "#bdcb4f",
    ratio: 15,
    text1: stringsEng.QUIZ_RESULT.QUIZ_RESULTS_1.EQUIVALENCES.TREE_1,
    text2: stringsEng.QUIZ_RESULT.QUIZ_RESULTS_1.EQUIVALENCES.TREE_2,
  },
  //Bags of Waste Recycled
  {
    bgColour: "rgb(189,203,79)",
    ratio: 39.3,
    text1: stringsEng.QUIZ_RESULT.QUIZ_RESULTS_1.EQUIVALENCES.WASTE_1,
    text2: stringsEng.QUIZ_RESULT.QUIZ_RESULTS_1.EQUIVALENCES.WASTE_2,
  },
];
