import { StyleSheet } from "react-native";
import { Colors } from "../../../../theme";

export const styles = StyleSheet.create({
  createClassMainFlexContainer: {
    flex: 5,
    justifyContent: "space-between",
    paddingHorizontal: 30,
  },
  submitButton: {
    backgroundColor: Colors.white,
    marginTop: 10,
    width: "70%",
    alignSelf: "center",
    padding: 5,
  },
  backButton: {
    padding: 10,
    backgroundColor: "transparent",
    position: "absolute"
  },
});
