import MultiSlider, {
  LabelProps,
  MultiSliderProps,
} from "@ptomasroos/react-native-multi-slider";
import React, { useEffect, useState } from "react";
import { Text, View } from "react-native-picasso";
import { ScaleQuestion } from "../../types/quiz.types";
import { injectProps } from "../HOC/inject-props.component";
import { styles } from "./labelled-slider.styles";

// This is the label on top of the slider that shows its current value
function CustomLabel({ oneMarkerValue, unit }: LabelProps & { unit: string }) {
  return (
    <View style={styles.label}>
      <Text className="align-center bold" style={styles.labelText}>
        {oneMarkerValue + " " + unit}
      </Text>
    </View>
  );
}

//TODO: make part of scaleData?
const transformTrackLabel = (value: number, scaleData: ScaleQuestion) => {
  let res = "" + value;

  if (scaleData.scaleValuePrefix) res = scaleData.scaleValuePrefix + res;
  if (scaleData.scaleValueSuffix) res = res + scaleData.scaleValueSuffix;
  if (value === scaleData.max) res = res + " +"; // e.g. "100+"
  return res;
};

export type LabelledSliderProps = MultiSliderProps & {
  scaleData: ScaleQuestion;
  numTrackLabels?: number;
};

// Slider component with a label above showing its current value
export default function LabelledSlider({
  scaleData,
  numTrackLabels = 5, // Including 0
  ...sliderProps
}: LabelledSliderProps) {
  const [trackLabels, setTrackLabels] = useState<string[]>([]);

  useEffect(() => {
    // Draw labels
    const labels = [];
    const increment = scaleData.max / (numTrackLabels - 1);
    for (let i = 0; i < numTrackLabels; i++) {
      // e.g. for max = 100, this is [0, 25, 50, 75, 100]
      labels.push(transformTrackLabel(i * increment, scaleData));
    }
    setTrackLabels(labels);
  }, [scaleData]);

  return (
    <View>
      <MultiSlider
        values={[scaleData.value || 0]}
        enableLabel
        customLabel={injectProps(CustomLabel, { unit: scaleData.unit })}
        containerStyle={styles.sliderContainer}
        markerStyle={styles.sliderMarker}
        trackStyle={styles.sliderTrack}
        selectedStyle={styles.selectedSliderTrack}
        allowOverlap // Without this, android can't select the max value
        min={0}
        max={scaleData.max}
        step={1}
        {...sliderProps}
      />
      <View className="flex-1 flex-row justifycontent-between">
        {trackLabels.map((trackLabel, index) => (
          <Text key={index} className="color-white">
            {trackLabel}
          </Text>
        ))}
      </View>
    </View>
  );
}
