import React from "react";
import { Image } from "react-native";
import { Text, TouchableOpacity, View } from "react-native-picasso";
import { stringsEng } from "../../../../assets/text/strings-english";
import { CategoryColors, Colors } from "../../../../theme";
import ProgressBar from "../../../components/ProgressBar/progress-bar.component";
import { styles } from "./challenge-item.styles";
import { useNavigation } from "@react-navigation/native";
import { Challenge, ChallengeLevel, ChallengeProgress } from "../../../types/challenge.types";

export const difficultyImages = {
  [ChallengeLevel.Easy]: require("../../../../assets/images/icons/difficulty1.png"),
  [ChallengeLevel.Medium]: require("../../../../assets/images/icons/difficulty2.png"),
  [ChallengeLevel.Hard]: require("../../../../assets/images/icons/difficulty3.png"),
};

type ItemProps = { challenge: Challenge, challengeProgress: ChallengeProgress, challengeImpactScaled?:  number };

// One of two list items that can display a challenge. For the other see MyChallengeItem
export function ChallengeItem({ challenge, challengeProgress, challengeImpactScaled }: ItemProps) {
  const navigation = useNavigation();
  const categoryColor = CategoryColors[challenge.category];

  const navigateToDetails = () => {
    //@ts-ignore
    navigation.navigate("ChallengeDetails", { challenge: challenge, challengeProgress: challengeProgress });
  };

  return (
    <TouchableOpacity style={styles.itemContainer} onPress={navigateToDetails}>
      <View
        style={[styles.itemLeftContainer, { backgroundColor: categoryColor }]}
      >
        <Image
          source={require("../../../../assets/images/icons/icon-plus.png")}
          resizeMode="contain"
          style={styles.itemPlusIcon}
        />
      </View>

      <View style={styles.itemCenterContainer}>
        <View className="flex-1">
          <Text style={{ fontSize: 16, fontFamily: "Poppins-semibold" }}>
            {challenge.challengeName}
          </Text>
          <Text style={{ fontSize: 11, color: Colors.darkGrey }}>
            {stringsEng.COMMON.CHALLENGE_CATEGORIES[
              challenge?.category
            ]?.toUpperCase()}
          </Text>
        </View>
        <View className="flex-1 mt-md">
          <Text style={{ fontSize: 11 }}>Impact</Text>
          <ProgressBar
            progress={(challengeImpactScaled)} //replace with a value towards the highest impact challenge -> eventually add scaling?
            fillColor={categoryColor}
          />
        </View>
      </View>

      <View style={styles.itemRightContainer}>
        <View style={styles.itemRightTop}>
          <Text
            style={{
              color: categoryColor,
              fontSize: 24,
              fontFamily: "Poppins-semibold",
            }}
          >
            {challenge.climaPoints}
          </Text>
          <Text style={{ color: categoryColor, fontSize: 9, marginTop: -12 }}>
            {stringsEng.COMMON.CLIMAPOINTS}
          </Text>
        </View>
        <View style={styles.itemRightBottom}>
          <Image
            source={difficultyImages[challenge.level]}
            resizeMode="contain"
            style={[styles.difficultyImage, { tintColor: categoryColor }]}
          />
          <Text style={{ color: categoryColor, fontSize: 9, marginTop: -8 }}>
            {stringsEng.COMMON.DIFFICULTY}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
}
