import GradientBackground from "../../../components/Background/gradient-background.component";
import ChallengesBackground from "../../Challenges/Common/challenges-background.component";
import React, { useEffect, useState } from "react";
import { TouchableOpacity, View, Text } from "react-native-picasso";
import ChallengesBody from "../../Challenges/Common/challenges-body.component";
import { Colors } from "../../../../theme";
import { ClassItem } from "../../../components/Teacher/ClassItem/class-item.component";
import { styles } from "../home/teacherhome.style";
import { FlatList, ScrollView } from "react-native";
import PressableImage from "../../../components/Button/pressable-image.component";
import { useIsFocused } from "@react-navigation/native";
import { useSelector } from "react-redux";
import { selectUser, storeTeacherData, updateUserData } from "../../../redux/userSlice";
import TeacherService from "../../../services/teacher.service";
import { getAllAssignments } from "../../../services/assignment"
import { getUserData } from "../../../services/auth"
import Overlay from "../../../components/LoadingOverlay/overlay.component";
import { useAppDispatch } from "../../../redux/store";




export default function TeacherDashboard({ navigation }) {

    const [loading, setLoading] = useState(true);
    const [classData, setClassData] = useState(Object);
    const isFocused = useIsFocused()
    const user = useSelector(selectUser);
    const dispatch = useAppDispatch();
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await TeacherService.getTeacherClasses(user.id).then((classes) => {
                classes = classes.filter(element => element.archived === false || element.archived === undefined);
                setClassData(classes)
                
            }).finally(() => {
                setTimeout(() => {
                    setLoading(false);
                }, 500);
            })

        }

        isFocused && fetchData().catch((err) => console.log(err));
    }, [isFocused])

    function navigateToNew(item) {
        const classParams = {
            class: item,
            assignments: item.assignments,
            students: item.students

        }
        //dispatch this data into redux
        dispatch(storeTeacherData(classParams));
        navigation.navigate("TeacherDashboardBottomTabs", { screen: "ClassDetails" });
    }

    function renderItem({ item }) {
        return (<ClassItem startDate={new Date(item.classStartDate)}
            endDate={new Date(item.classEndDate)}
            classCode={item.classCode}
            className={item.className}
            activeAssignments={item.assignments.filter(
                (a) => (new Date(a.dueDate).getTime() >= new Date().getTime())
            ).length}
            numStudents={item.students.length}
            onPress={() => { navigateToNew(item) }}
        />
        )
    };

    if (loading) return <Overlay display={true} />;
    if (!loading && classData) return (
        <>
            <GradientBackground />
            <ChallengesBackground navigation={navigation}>
                <ChallengesBody backgroundColor={Colors.lightGrey}>
                    <FlatList
                        data={classData}
                        renderItem={renderItem}
                        keyExtractor={(item) => item.classCode}
                    />
                    <PressableImage onPress={() => { navigation.navigate("TeacherHomeScreen") }} imageStyle={styles.newButton}
                        imageSource={require("../../../../assets/images/icons/plus-button.png")} />
                </ChallengesBody>

            </ChallengesBackground >
        </>
    );
}
