import { StyleSheet } from "react-native";
import { Colors } from "../../../../theme";

export const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: Colors.white,
    borderRadius: 10,
    padding: 10,
    marginBottom: 10,
  },
  contentContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  textContainer: {
    flex: 1,
    justifyContent: "center",
    marginLeft: 15,
  },
  titleText: {
    fontSize: 16,
    fontFamily: "Poppins-semibold",
  },
  subText: {
    color: Colors.darkGrey,
    fontSize: 11,
    marginVertical: 2,
  },
  image: {
    width: 80,
    height: 80,
  },
});
