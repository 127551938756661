import { CategoryData } from "../../src/pages/QuizResults/QuizResultsBreakdown/category-pop-up.component";
import { stringsEng } from "./strings-english";

export const Category: Array<CategoryData> = [
  //Food
  {
    imageSource: require("../food-category.png"),
    category: stringsEng.QUIZ_RESULT.QUIZ_RESULTS_2.CATEGORIES.FOOD,
    text: stringsEng.QUIZ_RESULT.QUIZ_RESULTS_2.CATEGORIES.FOOD_TEXT,
  },
  //Transportation
  {
    imageSource: require("../transportation-category.png"),
    category: stringsEng.QUIZ_RESULT.QUIZ_RESULTS_2.CATEGORIES.TRANSPORTATION,
    text: stringsEng.QUIZ_RESULT.QUIZ_RESULTS_2.CATEGORIES.TRANSPORTATION_TEXT,
  },
  //Housing
  {
    imageSource: require("../housing-category.png"),
    category: stringsEng.QUIZ_RESULT.QUIZ_RESULTS_2.CATEGORIES.HOUSING,
    text: stringsEng.QUIZ_RESULT.QUIZ_RESULTS_2.CATEGORIES.HOUSING_TEXT,
  },
  //Stuff
  {
    imageSource: require("../stuff-category.png"),
    category: stringsEng.QUIZ_RESULT.QUIZ_RESULTS_2.CATEGORIES.STUFF,
    text: stringsEng.QUIZ_RESULT.QUIZ_RESULTS_2.CATEGORIES.STUFF_TEXT,
  },
  //Other
  {
    imageSource: require("../other-category.png"),
    category: stringsEng.QUIZ_RESULT.QUIZ_RESULTS_2.CATEGORIES.OTHER,
    text: stringsEng.QUIZ_RESULT.QUIZ_RESULTS_2.CATEGORIES.OTHER_TEXT,
  },
];
