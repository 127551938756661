import { api } from "./api";
import { AssignmentType } from "../types/assignment.types";

//get all the assignmets of the class by call the geT ALL ASSIGNMENTS API
export const getAllAssignments = (classId: string) => {
  return api
    .get<{ assignments: AssignmentType[] }>(`/class/${classId}/assignments`)
    .then((res) => res.data.assignments)
    .catch((e) => {
      console.log("Couldn't load assignments", e);
      return [];
    });
};
