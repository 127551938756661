import { View } from "react-native-picasso";
import { Image, StyleSheet } from "react-native";
import React from "react";
import { TextInput } from "react-native-picasso";
import { Colors } from "../../../theme";

type SearchBarProps = {
  placeholderText: string;
  onChange: (text: string) => void;
};
export function SearchBar({ placeholderText, onChange }: SearchBarProps) {
  return (
    <View style={styles.searchBar}>
      <Image
        source={require("../../../assets/images/icons/icon-search.png")}
        resizeMode="contain"
        style={styles.icon}
      />
      <TextInput
        style={styles.textInput}
        placeholder={placeholderText}
        onChangeText={onChange}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  searchBar: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "nowrap",
    borderRadius: 50,
    borderColor: Colors.skyBlue,
    borderWidth: 2,
    backgroundColor: Colors.white,
    paddingVertical: 8,
    paddingHorizontal: 20,
  },
  icon: {
    width: 20,
    height: 20,
    tintColor: Colors.darkGrey,
  },
  textInput: {
    flex: 1,
    marginLeft: 10,
  },
});
