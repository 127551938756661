import { Resolver } from "react-hook-form";
import { CreateAssignmentFormData } from "./CreateAssignmentForm.component";
import { stringsEng } from "../../../../assets/text/strings-english";

const resolver: Resolver<CreateAssignmentFormData> = async (values) => {
  return {
    values: values.assignmentName
      ? values
      : values.dueDate
      ? values
      : values.level
      ? values
      : values.associatedClassCode
      ? values
      : values.pointsGoal
      ? values
      : values.description
      ? values
      : {},
    errors: !values.assignmentName
      ? {
          assignmentName: {
            type: "required",
            message: stringsEng.TEACHER.CREATE_ASSIGNMENT.ERRORS.NAME,
          },
        }
      : !values.dueDate
      ? {
          dueDate: {
            type: "required",
            message: stringsEng.TEACHER.CREATE_ASSIGNMENT.ERRORS.DUE_DATE,
          },
        }
      : new Date(values.dueDate).toString() === "Invalid Date"
      ? {
          dueDate: {
            type: "invalid",
            message: stringsEng.TEACHER.CREATE_ASSIGNMENT.ERRORS.INVALID_DUE_DATE,
          },
        }
      : !values.level
      ? {
          level: {
            type: "required",
            message: stringsEng.TEACHER.CREATE_ASSIGNMENT.ERRORS.LEVEL,
          },
        }
        : (values.level !== "easy" && values.level !== "medium" && values.level !== "hard")
      ? {
          level: {
            type: "invalid",
            message: stringsEng.TEACHER.CREATE_ASSIGNMENT.ERRORS.INVALID_LEVEL,
          },
        }
      : !values.associatedClassCode
      ? {
          associatedClassCode: {
            type: "required",
            message: stringsEng.TEACHER.CREATE_ASSIGNMENT.ERRORS.CLASSCODE,
          },
        }
      : !values.pointsGoal
      ? {
          pointsGoal: {
            type: "required",
            message: stringsEng.TEACHER.CREATE_ASSIGNMENT.ERRORS.POINTS,
          },
        }
        : parseInt(values.pointsGoal) <= 0
      ? {
          pointsGoal: {
            type: "invalid",
            message: stringsEng.TEACHER.CREATE_ASSIGNMENT.ERRORS.INVALID_POINTS,
          },
        }
      : !values.description
      ? {
          description: {
            type: "required",
            message: stringsEng.TEACHER.CREATE_ASSIGNMENT.ERRORS.DESCRIPTION,
          },
        }
      : {},
  };
};

export default resolver;
