import { StyleSheet } from "react-native";
import { Colors } from "../../../../theme";

export const styles = StyleSheet.create({
  classSubmitButton: {
    backgroundColor: Colors.white,
    margin: 0,
  },
  classInfoText: {
    backgroundColor: Colors.cornflowerBlue,
    borderRadius: 13,
    fontSize: 16,
    paddingVertical: 8,
  },
  byAcceptingList: {
    fontSize: 12,
  },
});
