import classService from "../../services/class.service";
import { CreateAccountFormData } from "./CreateAccount/create-account-form.component";
import { registerTeacher, registerUser } from "../../services/auth";
import SchoolService from "../../services/school.service";

export default class OnboardingService {
  // Check if the class exists
  // to do : refactor code, add return types
  static checkIfClassExists = async (classCode: string) => {
    const classData = await classService.getClassData(classCode);
    try {
      return classData.classStatus;
    } catch (err) {
      console.log("Cannot get classData: " + err);
    }
  };

  // ToDO : Register new user and join the class
  static createAccountAndJoinClass = async (
    formData: CreateAccountFormData,
    classCode: String
  ) => {
    const res = await registerUser(formData, classCode);
    return res;
  };

  static createTeacherAccountAndJoinSchool = async (
    formData: CreateAccountFormData,
    schoolCode: String
  ) => {
    const res = await registerTeacher(formData, schoolCode);
    return res;
  };

  // check if school exists
  static checkIfSchoolExists = async (schoolCode: string) => {
    const schoolData = await SchoolService.getSchoolData(schoolCode);
    try {
      return schoolData;
    } catch (err) {
      console.log("Cannot get schoolData: " + err);
    }
  };
}
