import React from "react";
import { Image, Linking } from "react-native";
import { Text, View } from "react-native-picasso";
import { Colors } from "../../../theme";
import { OpenURL } from "../../pages/Challenges/ChallengeDetails/challenge-details.component";
import PressableText from "../Button/pressable-text.component";
import { styles } from "./fun-fact.styles";

export type FunFactType = {
  fact: string;
  sourceLink: URL[];
};

export type FunFactProps = FunFactType;
export default function FunFact(fact: FunFactProps) {
  return (
    <View style={styles.container}>
      <Image
        source={require("../../../assets/images/did-you-know.png")}
        resizeMode="contain"
        style={{ width: "60%", aspectRatio: 4 }}
      />
      <Text style={styles.factText} className="weight-bold align-center">{fact.fact}</Text>
      <View style={styles.sourceText}>
        <Text className="color-darkGrey size-xxs weight-semibold">
          Source:{" "}
        </Text>
        <View style={styles.sourceButtons}>
          {fact.sourceLink?.map((source, i) => (
            <OpenURL url={source} categoryColor={Colors.skyBlue}>
              {i + 1}
            </OpenURL>
          ))}
        </View>
      </View>
    </View>
  );
}
