import React from "react";
import { ImageBackground, Image, ImageSourcePropType } from "react-native";
import { Text, View } from "react-native-picasso";
import { styles as generalStyle } from "./quiz-results.styles";
import { styles } from "./quiz-results.styles";
import HeaderBanner from "../../../components/HeaderBanner/header-banner.component";
import { stringsEng } from "../../../../assets/text/strings-english";
import SkipToChallengesButton from "./skip-to-challenges-button.component";
import PressableImage from "../../../components/Button/pressable-image.component";
import NextResultButton from "./next-result-button.component";

export type BackgroundProps = {
  navigation: Navigator;
  children: any;
  backgroundImage: ImageSourcePropType;
  footprint: number;
  upArrow: boolean;
  sectionHeader: string;
  downArrow: boolean;
  nextPageText?: string;
  skipButton: boolean;
  navigateToNextResult: Function;
  navigateToPreviousResult: Function;
};

export default function ResultsBackground({
  navigation,
  children,
  backgroundImage,
  footprint,
  upArrow,
  sectionHeader,
  downArrow,
  nextPageText = "",
  skipButton,
  navigateToNextResult,
  navigateToPreviousResult,
}: BackgroundProps) {
  return (
    <>
      <ImageBackground
        source={backgroundImage}
        style={[
          { width: "100%", height: "100%", position: "absolute" },
          { zIndex: -3 },
        ]}
      />
      <HeaderBanner />
      <Text className="h3 color-white align-center mt-md">
        {stringsEng.QUIZ_RESULT.YOUR_FOOTPRINT} {"\n"}
        {stringsEng.QUIZ_RESULT.IS} {footprint}{" "}
        {stringsEng.QUIZ_RESULT.YOUR_FOOTPRINT_UNITS}
      </Text>
      <Image
        source={require("../../../../assets/break.png")}
        style={styles.break}
      />
      {upArrow ? (
        <PressableImage
          onPress={() => navigateToPreviousResult()}
          imageSource={require("../../../../assets/previous-result-arrow.png")}
          imageStyle={styles.upArrow}
        />
      ) : null}

      <Text
        className="color-white h4 align-center mt-s mb-lg"
        style={styles.sectionHeader}
      >
        {sectionHeader}
      </Text>

      {children}

      {downArrow ? (
        <NextResultButton
          navigateToNextResult={() => navigateToNextResult()}
          text={nextPageText}
        />
      ) : null}

      {skipButton ? (
        <View style={styles.skipContainer}>
          <SkipToChallengesButton navigation={navigation} />
        </View>
      ) : null}
    </>
  );
}
