import React from "react";
import {
  Image,
  TouchableOpacity,
  ImageSourcePropType,
  ColorValue,
} from "react-native";
import { Text, View } from "react-native-picasso";
import { styles } from "./quiz-results-breakdown.styles";

export type ButtonProps = {
  onPress: Function;
  imageSource: ImageSourcePropType;
  bgColour: ColorValue;
  percentage: number;
};

export default function CategoryButton({
  onPress,
  imageSource,
  bgColour,
  percentage,
}: ButtonProps) {
  return (
    <TouchableOpacity
      onPress={() => onPress()}
      style={[{ backgroundColor: bgColour }, styles.categoryButton]}
    >
      <View style={styles.categoryButtonImageContainer}>
        <Image
          source={imageSource}
          resizeMode="contain"
          style={styles.categoryButtonImage}
        />
      </View>
      <Text
        className="size-md color-white align-center bold"
        style={{ marginTop: -10 }}
      >
        {Math.round(percentage)}%
      </Text>
    </TouchableOpacity>
  );
}
