import { FlatList, StyleSheet } from "react-native";
import React, { useEffect, useState } from "react";
import ChallengesBody from "../Challenges/Common/challenges-body.component";
import { AssignmentType } from "../../types/assignment.types";
import AssignmentItem from "./AssignmentItem/assignment-item.component";
import { Colors } from "../../../theme";
import {
  Filter,
  FilterTabs,
  useFilters,
} from "../../components/FilterTabs/filter-tabs.component";
import { getAllAssignments } from "../../services/assignment";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/userSlice";
import { useIsFocused } from "@react-navigation/native";
import { getUserData } from "../../services/auth";
import { updateUserData } from "../../redux/userSlice";
import { useAppDispatch } from "../../redux/store";
import ClassService from "../../services/class.service"
import Overlay from "../../components/LoadingOverlay/overlay.component";

const filters: Filter<AssignmentType>[] = [
  {
    title: "In Progress",
    filterFn: (item) => item.status !== "completed",
  },
  { title: "Done", filterFn: (item) => item.status === "completed" },
];

export default function Assignments({ navigation }) {
  //get all assignments on load of the page and
  // To Do store it in the redux store, remove the call to the api again and again
  const isFocused = useIsFocused();
  const [classAssignments, setClassAssignmets] = useState<AssignmentType[]>([]);
  //const [loaded, setLoaded] = useState(false);

  const user = useSelector(selectUser);
  const classId = user.userData.class[0];
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getAssignmentsData = async () => {
      const userData = await getUserData(user.id);
      dispatch(updateUserData(userData.data.userData));
      const allClassAssignments = await getAllAssignments(classId);
      const classAssignments = [];
      allClassAssignments.forEach((assignment) => {
        const assignmentProgress =
          userData.data.userData.assignedAssignmentsProgress.find(
            (assignmentProgress) =>
              assignmentProgress.assignmentId === assignment._id
          );
        if (assignmentProgress) {
          // convert the dueDate in the assignment to a date object
          assignment.dueDate = new Date(assignment.dueDate);
          classAssignments.push({ ...assignment, ...assignmentProgress });
        }
      });
      setClassAssignmets(classAssignments);
    };
    isFocused &&
      getAssignmentsData()
        // make sure to catch any error
        .catch(console.error);
  }, [isFocused]);

  const filterData = useFilters(classAssignments, filters, 0);
  const renderItem = ({ item }: { item: AssignmentType }) => (
    <AssignmentItem
      // TODO: unique assignment key
      // key={item.challengeCode}
      assignment={item}
      userId={user.id}
      classId={classId}

    // onPress={navigateToDetails}
    />
  );

  const curFilterResults =
    filterData.filterResults[filterData.selectedFilterIndex];

  //if (!loaded) return <Overlay display={true} />;

  return (
    <>
      <FilterTabs {...filterData} />
      <ChallengesBody backgroundColor={Colors.lightGrey}>
        <FlatList
          data={curFilterResults.filteredItems}
          renderItem={renderItem}
        // TODO: unique assignment key
        // keyExtractor={}
        />
      </ChallengesBody>
    </>
  );
}

const styles = StyleSheet.create({});
