import React from "react";
import { Controller, useForm } from "react-hook-form";
import { View, Text } from "react-native-picasso";
import { stringsEng } from "../../../../assets/text/strings-english";
import CFTButton from "../../../components/Button/cft-button.component";
import { Colors } from "../../../../theme";
import CFTTextInput from "../../../components/TextInput/text-input.component";
import { styles } from "./forgot-password.styles";
import resolver from "./forgot-password-resolver";
import { errorStyle } from "../Common/error-styles";
import { useState } from "react";
import { forgotPassword } from "../../../services/auth";
import Overlay from "../../../components/LoadingOverlay/overlay.component";
import { ActivityIndicator } from "react-native";

export type FormData = {
  email: string;
  validEmail: boolean;
};

export default function ForgotPasswordForm({ navigation }) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      email: "",
      validEmail: true,
    },
    resolver,
  });

  const [loader, setLoader] = useState(false);
  const [validEmail, setEmail] = useState(false);

  const submit = handleSubmit(async (data) => {
    setLoader(true);
    const response = await forgotPassword(data.email);
    if (response) {
      setTimeout(() => {
        setLoader(false);
        navigation.navigate("SubmitCode", {
          name: "SubmitCode",
          email: data.email,
        });
      }, 500);
    } else {
      setTimeout(() => {
        setLoader(false);
        control._formState.errors.validEmail = {

          type: 'required',
          message: stringsEng.ONBOARDING.FORGOT_PASSWORD.ERRORS.INVALID_EMAIL,

        }
        setEmail((validEmail) => !validEmail);
      }, 500);
    }
  });

  if (loader)
    return (
      <View style={{ flex: 1, justifyContent: "center", padding: 10 }}>
        <ActivityIndicator size={40} />
      </View>
    );

  return (
    <>
      <Controller
        name="email"
        control={control}
        rules={{ required: true }}
        render={({ field: { onBlur, onChange, value } }) => (
          <CFTTextInput
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            placeholderTextColor="white"
            autoCorrect={false}
            placeholder={stringsEng.ONBOARDING.FORGOT_PASSWORD.EMAIL}
            iconSource={require("../../../../assets/images/icons/icon_msg.png")}
            iconColor={Colors.white}
          />
        )}
      />
      <View>
        <CFTButton
          text={stringsEng.ONBOARDING.SUBMIT}
          buttonStyle={styles.passwordSubmitButton}
          buttonSize="lg"
          iconSource={require("../../../../assets/next-page-arrow.png")}
          onPress={submit}
          textColor={Colors.iceBlue}
        />
        <View style={{ height: 15, padding: 5 }}>
          {errors.email && (
            <Text style={errorStyle.error}>{errors.email.message}</Text>
          )}
          {errors.validEmail && <Text style={errorStyle.error}>{errors.validEmail.message}</Text>}
        </View>
      </View>
    </>
  );
}
