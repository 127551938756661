import React from "react";
import { View, Text } from "react-native-picasso";
import { stringsEng } from "../../../../assets/text/strings-english";
import { Colors } from "../../../../theme";
import GradientBackground from "../../../components/Background/gradient-background.component";
import CFTButton from "../../../components/Button/cft-button.component";
import HeaderBanner from "../../../components/HeaderBanner/header-banner.component";
import { styles } from "./createClass.styles";
import CreateClassForm from "./createClassForm.component";

export default function CreateClass({ navigation }) {
  return (
    <>
      <GradientBackground />
      <HeaderBanner />
      <CFTButton
        iconSource={require("../../../../assets/images/icons/icon-arrow.png")}
        onPress={() => navigation.goBack()}
        textColor={Colors.white}
        buttonStyle={styles.backButton}
      />
      <View style={styles.createClassMainFlexContainer}>
        <Text className="color-white align-center h3">
          {stringsEng.TEACHER.HOME.CREATE_CLASS}
        </Text>
        <CreateClassForm />
      </View>
    </>
  );
}
