const MS_PER_MIN = 60 * 1000;
const MS_PER_HOUR = 60 * MS_PER_MIN;
const MS_PER_DAY = 24 * MS_PER_HOUR;

// Helper function to calculate the number of days, hours, and minutes between
// two dates. The order of the passed dates won't change the result
export const differenceBetweenDates = (
  a: Date,
  b: Date
): { days: number; hours: number; minutes: number } => {
  // https://stackoverflow.com/a/15289883
  const aUTC = Date.UTC(
    a.getFullYear(),
    a.getMonth(),
    a.getDate(),
    a.getHours(),
    a.getMinutes()
  );
  const bUTC = Date.UTC(
    b.getFullYear(),
    b.getMonth(),
    b.getDate(),
    b.getHours(),
    b.getMinutes()
  );

  // Difference between the two dates in milliseconds
  let msDelta = Math.abs(bUTC - aUTC);

  // Find the whole number of days and keep the remaining time (hours, mins) in msDelta
  const days = Math.floor(msDelta / MS_PER_DAY);
  msDelta -= days * MS_PER_DAY;

  const hours = Math.floor(msDelta / MS_PER_HOUR);
  msDelta -= hours * MS_PER_HOUR;

  const minutes = Math.floor(msDelta / MS_PER_MIN);

  return { days, hours, minutes };
};
