import React from "react";
import {
  ColorValue,
  GestureResponderEvent,
  Image,
  ImageSourcePropType,
  StyleProp,
  ViewStyle,
} from "react-native";
import { Text, TouchableOpacity } from "react-native-picasso";
import { styles } from "./button.styles";

// Use one of the size strings for a preset size, or a number for a custom width/height
type CFTButtonSize = "md" | "lg" | "xl" | number;

export type ButtonProps = {
  text?: string;
  onPress: (event: GestureResponderEvent) => void;
  iconSource?: ImageSourcePropType;
  buttonStyle?: StyleProp<ViewStyle>;
  textColor?: ColorValue;
  buttonSize?: CFTButtonSize;
  disabled?: boolean;
};

const iconStyle = (buttonSize: CFTButtonSize) =>
  typeof buttonSize === "number"
    ? { width: buttonSize, height: buttonSize }
    : buttonSize === "md"
    ? styles.buttonIconMd
    : buttonSize === "lg"
    ? styles.buttonIconLg
    : buttonSize === "xl"
    ? styles.buttonIconXl
    : {};

// Custom button component so we can control its style. Including the iconSource
// prop will render the icon, or don't include it if you don't want an image.
export default function CFTButton({
  text,
  onPress,
  iconSource,
  buttonStyle,
  textColor,
  buttonSize = "md",
  disabled = false,
}: ButtonProps) {
  const buttonStyles: Array<any> = [
    styles.button,
    ...(buttonStyle ? [buttonStyle] : []),
    ...(disabled ? [styles.buttonDisabled] : []),
  ];
  const textStyles: Array<any> = [
    styles.buttonText,
    ...(textColor ? [{ color: textColor }] : []),
    ...(buttonSize === "lg"
      ? [styles.buttonTextLg]
      : typeof buttonSize === "number"
      ? [{ fontSize: buttonSize / 2 }]
      : []),
    ...(disabled ? [styles.buttonTextDisabled] : []),
  ];
  const iconStyles: Array<any> = [
    iconStyle(buttonSize),
    ...(textColor ? [{ tintColor: textColor }] : []),
    // If there is no text, remove the button's margin to center it
    ...(text === undefined ? [{ marginRight: 0 }] : []),
  ];

  return (
    <TouchableOpacity
      className=""
      onPress={disabled ? undefined : onPress}
      style={buttonStyles}
    >
      {iconSource ? (
        <Image source={iconSource} resizeMode="contain" style={iconStyles} />
      ) : null}
      {text ? <Text style={textStyles}>{text}</Text> : null}
    </TouchableOpacity>
  );
}
