import React, { useState, useContext } from "react";
import { StyleSheet, Dimensions } from "react-native";
import { Text, View } from "react-native-picasso";
import { PieChart } from "react-native-svg-charts";
import { Text as TextSvg } from "react-native-svg";
import { CategoryColors, Colors } from "../../../theme";

export enum ChallengesCategory {
  Food = "Food",
  Transport = "Transport",
  Housing = "Housing",
  Stuff = "Stuff",
  Other = "Other",
}

function getChallengeData(userData) {
  const reqData = {
    totalImpact: userData.carbonImpact.toFixed(3),
    totalChallenges: userData.challengesCompleted,
    CategoricalData: {
      transport: {
        // challengeCount: 0,
        impact: 0,
      },
      stuff: {
        // challengeCount: 0,
        impact: 0,
      },
      food: {
        // challengeCount: 0,
        impact: 0,
      },
      housing: {
        // challengeCount: 0,
        impact: 0,
      },
      other: {
        // challengeCount: 0,
        impact: 0,
      },
    },
  };
  if (userData.challengesCompleted > 0) {
    const stat = userData.challengesStat;
    const catData = reqData.CategoricalData;
    catData.transport.impact = stat.transit.toFixed(3);
    catData.stuff.impact = stat.stuff.toFixed(3);
    catData.food.impact = stat.food.toFixed(3);
    catData.housing.impact = stat.housing.toFixed(3);
    catData.other.impact = stat.other.toFixed(3);
  }

  return reqData;
}

function roundTo100(categoricalData, reqData) {

  let sum = 0;
  let roundedDownValues = [];
  Object.keys(categoricalData).forEach((key) => {
    let newVal = Math.floor(
      (categoricalData[key].impact / reqData.totalImpact) * 100
    );
    categoricalData[key].impact = (categoricalData[key].impact / reqData.totalImpact) * 100
    roundedDownValues.push(newVal)
    sum += newVal;
  });

  let difference = 100 - sum;
  if (difference === 0) {
    return categoricalData;
  }

  let flattened = Object.keys(categoricalData).reduce(function (r, k) { return r.concat(categoricalData[k]); }, []);
  flattened.sort((a, b) => (b.impact - Math.floor(b.impact)) - (a.impact - Math.floor(a.impact)));
  for (var i = 0; i < flattened.length;) {
    if (i >= flattened.length && difference !== 0) {
      i = 0;
    }
    if (difference > 0) {
      flattened[i].impact += 1;
    } else {
      flattened[i].impact -= 1;
      if (flattened[i].impact < 0) {
        flattened[i].impact = 0;
      }
    }

    flattened[i].impact = Math.floor(flattened[i].impact)
    i++;
    if (difference > 0) {
      difference--;
    } else {
      difference++;
    }

  }

  return categoricalData;
}

export default function ChallengesStat({ userData }) {
  const reqData = getChallengeData(userData);
  const categoricalData = reqData.CategoricalData;

  let finalizedValues = roundTo100(categoricalData, reqData)

  const data = Object.keys(categoricalData).map((key) => {
    const categoryColor = CategoryColors[key];
    return {
      key,
      amount: finalizedValues[key].impact,
      svg: { fill: categoryColor },
      arc: {
        cornerRadius: 5,
      },
    };
  });

  const Labels = ({ slices, height, width }) => {
    return slices.map((slice, index) => {
      const { labelCentroid, pieCentroid, data } = slice;
      return (
        <TextSvg
          key={index}
          x={pieCentroid[0]}
          y={pieCentroid[1]}
          fill={"black"}
          textAnchor={"middle"}
          alignmentBaseline={"middle"}
          fontSize={18}
          fontFamily={"Poppins"}
        >
          {data.amount >= 4 && data.amount + "%"}
        </TextSvg>
      );
    });
  };

  return (
    <View style={styles.container}>
      <Text className="h4" style={styles.title}>
        Challenges Breakdown
      </Text>
      <View style={{ justifyContent: "center", flex: 1 }}>
        <PieChart
          valueAccessor={({ item }) => item.amount}
          data={data}
          outerRadius={"70%"}
          innerRadius={60}
          style={{ height: 350 }}
        >
          <Labels />
        </PieChart>
        <View
          style={{
            position: "absolute",
            textAlign: "center",
            alignSelf: "center",
            width: 95,
          }}
        >
          <Text className="h3">{`${reqData.totalImpact}Kg  `}</Text>
          <Text style={styles.smallText}>Total carbon emmision saved</Text>
        </View>
      </View>
      <LegendsGrid data={categoricalData} />
    </View>
  );
}

export function LegendsGrid({ data }) {
  const Row = ({ children }) => (
    <View style={styles.legendRow}>{children}</View>
  );

  const Col = ({ children }) => {
    return <View style={styles.legendCol}>{children}</View>;
  };

  const ColorBlockText = ({ bColor, categoryName }) => {
    return (
      <Row>
        <View style={[styles.legendColorBlock, { backgroundColor: bColor }]} />
        <Text className="ml-sm" style={styles.legendText}>
          {categoryName}
        </Text>
      </Row>
    );
  };

  return (
    <View style={styles.legendsGrid}>
      <Row>
        <Col>
          <View className="m-sm" style={styles.legendTextRow}>
            <ColorBlockText
              bColor={CategoryColors.transport}
              categoryName={ChallengesCategory.Transport}
            />
            <Text className="ml-md" style={styles.legendValue}>
              {data.transport?.impact + " kg CO2e"}
            </Text>
          </View>
          <View className="m-sm" style={styles.legendTextRow}>
            <ColorBlockText
              bColor={CategoryColors.food}
              categoryName={ChallengesCategory.Food}
            />
            <Text className="ml-md" style={styles.legendValue}>
              {data.food?.impact + " kg CO2e"}
            </Text>
          </View>
          <View className="m-sm" style={styles.legendTextRow}>
            <ColorBlockText
              bColor={CategoryColors.other}
              categoryName={ChallengesCategory.Other}
            />
            <Text className="ml-md" style={styles.legendValue}>
              {data.other?.impact + " kg CO2e"}
            </Text>
          </View>
        </Col>
        <Col>
          <View className="m-sm" style={styles.legendTextRow}>
            <ColorBlockText
              bColor={CategoryColors.stuff}
              categoryName={ChallengesCategory.Stuff}
            />
            <Text className="ml-md" style={styles.legendValue}>
              {data.stuff?.impact + " kg CO2e"}
            </Text>
          </View>
          <View className="m-sm" style={styles.legendTextRow}>
            <ColorBlockText
              bColor={CategoryColors.housing}
              categoryName={ChallengesCategory.Housing}
            />
            <Text className="ml-md" style={styles.legendValue}>
              {data.housing?.impact + " kg CO2e"}
            </Text>
          </View>
        </Col>
      </Row>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
    marginBottom: 10,
    marginTop: 20,
    marginHorizontal: 10,
    borderRadius: 12,
    backgroundColor: Colors.white,
    justifyContent: "center",
  },
  smallText: {
    color: Colors.darkGrey,
    fontSize: 8,
  },
  title: {
    color: Colors.cornflowerBlue,
  },
  legendsGrid: {
    marginHorizontal: "auto",
  },
  legendRow: {
    flexDirection: "row",
  },
  legendCol: {
    flexDirection: "column",
  },
  legendColorBlock: {
    width: 10,
    height: 10,
    borderRadius: 2,
    alignSelf: "center",
  },
  legendText: {},
  legendValue: {
    fontSize: 10,
    color: Colors.darkGrey,
    alignSelf: "center",
  },
  legendTextRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});
