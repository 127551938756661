import React from "react";
import { Image, StyleSheet } from "react-native";
import { View } from "react-native-picasso";
import GradientBackground from "../../../components/Background/gradient-background.component";
import HamburgerDrawerButton from "./hamburger-drawer-button.component";

export default function ChallengesBackground({ children, navigation }) {
  return (
    <>
      <GradientBackground vertical />

      <View style={styles.logoContainer}>
        <Image
          source={require("../../../../assets/images/climact-logo.png")}
          style={styles.logo}
          resizeMode="contain"
        />
      </View>

      <View style={styles.top}>
        <HamburgerDrawerButton navigation={navigation} />
      </View>
      {children}
    </>
  );
}

const styles = StyleSheet.create({
  bg: {
    width: "100%",
    height: "100%",
    position: "absolute",
  },
  top: {
    alignItems: "flex-end",
    marginEnd: 20,
    marginTop: 30,
    marginBottom: 20,
  },
  logoContainer: {
    ...StyleSheet.absoluteFillObject,
    alignItems: "center",
  },
  logo: {
    width: "30%",
    top: -40,
    aspectRatio: 0.7,
  },
});
