import React from "react";
import { View } from "react-native-picasso";
import { stringsEng } from "../../../../assets/text/strings-english";
import { Colors } from "../../../../theme";
import ResultsBackground from "../Common/results-background.component";
import CategoryButton from "./category-button.component";
import CategoryPopUp from "./category-pop-up.component";
import { styles } from "./quiz-results-breakdown.styles";

export default function QuizResultsBreakdown({ navigation, route }) {
  let footprint = route.params.footprint;

  //This part allows you to switch the modal content
  //Update array
  const categoryFootprints = [
    footprint.food,
    footprint.transit,
    footprint.housing,
    footprint.stuff,
    footprint.other,
  ];

  const [modalOpen, toggleModal] = React.useState(false);
  const [categoryIndex, changeCategoryIndex] = React.useState(0);
  function closeModal() {
    toggleModal(false);
  }
  function switchFood() {
    changeCategoryIndex(0);
    toggleModal(true);
  }
  function switchTransportation() {
    changeCategoryIndex(1);
    toggleModal(true);
  }
  function switchHousing() {
    changeCategoryIndex(2);
    toggleModal(true);
  }
  function switchStuff() {
    changeCategoryIndex(3);
    toggleModal(true);
  }
  function switchOther() {
    changeCategoryIndex(4);
    toggleModal(true);
  }

  //For navigation to the next results page
  //Change navigation destination
  function navigateToNextResult() {
    navigation.navigate("QuizPages", {screen:"QuizResultsAverages", params: { footprint }});
  }
  function navigateToPreviousResult() {
    navigation.navigate("QuizPages", {screen:"QuizResultsEquivalents", params: { footprint }});
  }

  return (
    <ResultsBackground
      navigation={navigation}
      backgroundImage={require("../../../../assets/gradient-bg.png")}
      footprint={footprint.total}
      upArrow={true}
      sectionHeader={
        stringsEng.QUIZ_RESULT.QUIZ_RESULTS_2.SECTION_HEADER_LINE1 +
        "\n" +
        stringsEng.QUIZ_RESULT.QUIZ_RESULTS_2.SECTION_HEADER_LINE2
      }
      downArrow={true}
      nextPageText={
        stringsEng.QUIZ_RESULT.QUIZ_RESULTS_2.NEXT_PAGE_LINE1 +
        "\n" +
        stringsEng.QUIZ_RESULT.QUIZ_RESULTS_2.NEXT_PAGE_LINE2
      }
      skipButton={true}
      navigateToNextResult={navigateToNextResult}
      navigateToPreviousResult={navigateToPreviousResult}
    >
      <CategoryPopUp
        onClose={closeModal}
        categoryIndex={categoryIndex}
        isOpen={modalOpen}
        categoryFootprint={categoryFootprints[categoryIndex]}
      />

      <View style={styles.buttonContainer}>
        <View style={styles.buttonRow}>
          {/* Food */}
          <CategoryButton
            percentage={(categoryFootprints[0] / footprint.total) * 100}
            onPress={switchFood}
            bgColour={Colors.red}
            imageSource={require("../../../../assets/images/icons/icon-food.png")}
          />
          {/* Transportation */}
          <CategoryButton
            percentage={(categoryFootprints[1] / footprint.total) * 100}
            onPress={switchTransportation}
            bgColour={Colors.mossGreen}
            imageSource={require("../../../../assets/images/icons/icon-transportation.png")}
          />
        </View>

        <View style={styles.buttonRow}>
          {/* Housing */}
          <CategoryButton
            percentage={(categoryFootprints[2] / footprint.total) * 100}
            onPress={switchHousing}
            bgColour={Colors.yellow}
            imageSource={require("../../../../assets/images/icons/icon-housing.png")}
          />
          {/* Stuff */}
          <CategoryButton
            percentage={(categoryFootprints[3] / footprint.total) * 100}
            onPress={switchStuff}
            bgColour={Colors.orange}
            imageSource={require("../../../../assets/images/icons/icon-stuff.png")}
          />
        </View>

        <View style={styles.buttonRow}>
          {/* Other */}
          <CategoryButton
            percentage={(categoryFootprints[4] / footprint.total) * 100}
            onPress={switchOther}
            bgColour={Colors.skyBlue}
            imageSource={require("../../../../assets/images/icons/icon-other.png")}
          />
        </View>
      </View>
    </ResultsBackground>
  );
}
