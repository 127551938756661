import React, { useEffect, useState, useCallback } from "react";
import { Alert, ScrollView, StyleSheet, Linking, Modal, Pressable } from "react-native";
import { Text, View } from "react-native-picasso";
import { CategoryColors, Colors } from "../../../../theme";
import ChallengesBody from "../Common/challenges-body.component";
import { funFacts } from "../../../../assets/text/fun-facts";
import { stringsEng } from "../../../../assets/text/strings-english";
import CFTButton from "../../../components/Button/cft-button.component";
import FunFact from "../../../components/FunFact/fun-fact.component";
import {
  CarbonOffsetBox,
  ClimaPointsBox,
} from "../../../components/NumberValueBoxes/number-value-boxes.component";
import ChallengesBackground from "../Common/challenges-background.component";
import ProgressNumbers from "../../../components/ProgressNumbers/progress-numbers.component";
import {
  challengeDetailsMessages,
  defaultMessage,
} from "../../../../assets/text/challenge-details-messages-english";
import { Challenge, ChallengeProgress } from "../../../types/challenge.types";
import { enrollInChallenge, logChallenge } from "../../../services/challenge";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/userSlice";
import { useIsFocused } from '@react-navigation/native';
import { TouchableOpacity } from "react-native-picasso";
import { modalStyles } from "../../../components/Modal/modal.styles";
import Emoji from "../../../components/Emoji/emoji.component";

type Props = {
  challenge: Challenge;
  numberCompleted: number;
};
function ChallengeDetailsMessage({ challenge, numberCompleted }: Props) {
  // challengeDetailsMessages[numberCompleted] resolves to undefined if
  // numberCompleted is not a key
  const messageParts =
    challengeDetailsMessages[numberCompleted] || defaultMessage;

  return (
    // Wrapping it in a text keeps it all on one line (but still wraps as
    // expected if the text is long)
    <Text style={{ textAlign: "center" }}>
      {messageParts.map((messagePart, i) => {
        const messageText =
          typeof messagePart.text === "string"
            ? messagePart.text
            : messagePart.text({ challenge, numberCompleted });

        return (
          <Text
            key={i}
            style={{
              color: Colors.darkGrey,
              fontWeight: messagePart.bold ? "800" : "normal",
            }}
          >
            {messageText + (i === messageParts.length - 1 ? "" : " ")}
          </Text>
        );
      })}
    </Text>
  );
}

export function OpenURL({ url, categoryColor, children }) {
  const handlePress = useCallback(async () => {
    // Checking if the link is supported for links with custom URL scheme.
    const supported = await Linking.canOpenURL(url);

    if (supported) {
      // Opening the link with some app, if the URL scheme is "http" the web link should be opened
      // by some browser in the mobile
      await Linking.openURL(url);
    } else {
      Alert.alert(`Don't know how to open this URL: ${url}`);
    }
  }, [url]);

  return (
    <TouchableOpacity
      key={children}
      onPress={handlePress}
      style={[styles.linkBox, { backgroundColor: categoryColor }]}
    >
      <Text style={styles.numberText}>{children}</Text>
    </TouchableOpacity>
  );
}

export default function ChallengeDetails({ navigation, route }) {
  const challenge: Challenge = route.params.challenge;
  const challengeProgress: ChallengeProgress = route.params.challengeProgress;
  const user = useSelector(selectUser);

  var originalNumHighlighted = 0; //From the API call
  let started = false;
  let completed = false;
  let enrolled = false;

  const [disabled, setDisabled] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalText, setModalText] = useState("");
  const [modalEmoji, setModalEmoji] = useState("");
  const [newlyEnrolled, setNewlyEnrolled] = useState(false);
  const isFocused = useIsFocused();

  if (challengeProgress === undefined) {
    // check if the challenge is enrolled
    const enrolledChallengeProgress = user.userData.inrolledchallenges.find(
      (c) => {
        if (c.challengeCode === challenge.challengeCode) {
          enrolled = true;
          return c;
        }
      }
    );
    enrolledChallengeProgress
      ? (originalNumHighlighted = enrolledChallengeProgress.logCounter)
      : (originalNumHighlighted = 0);
  } else {
    originalNumHighlighted = challengeProgress.logCounter;
    if (
      challengeProgress.status === "inprogress" ||
      challengeProgress.status === "inrolled"
    ) {
      started = true;
    } else if (challengeProgress.status === "completed") {
      completed = true;
    }
  }

  const categoryColor = CategoryColors[challenge.category];
  const categoryText =
    stringsEng.COMMON.CHALLENGE_CATEGORIES[challenge.category];

  const { id } = useSelector(selectUser);
  const [numButtonsHighlighted, setHighlighted] = useState(0); //The number of logs clicked while the current details component is open
  const didMount = React.useRef(false);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }

    if (numButtonsHighlighted + originalNumHighlighted === 3 && (!disabled || !started)) {
      setModalText(stringsEng.CHALLENGE_MODALS.COMPLETED.TEXT);
      setModalEmoji(stringsEng.TEACHER.STUDENT_LIST.MODAL_CONFIRM + stringsEng.CHALLENGE_MODALS.COMPLETED.EMOJI);
    }

  }, [isFocused, numButtonsHighlighted, setHighlighted]);


  const onPressStartChallenge = async () => {
    var isRepeat = false;
    if (challengeProgress !== undefined) {
      isRepeat = challengeProgress.status === "completed";
    }
    const didEnroll = await enrollInChallenge(id, challenge.challengeCode, isRepeat);
    setHighlighted(0);
    if (didEnroll) {
      // Success TODO: redirect to dashboard and make the new challenge
      // obviously visible, maybe by making it slide in from the bottom
      setDisabled(true);
      setModalText(stringsEng.CHALLENGE_MODALS.ENROLLED.TEXT);
      setModalEmoji(stringsEng.TEACHER.STUDENT_LIST.MODAL_CONFIRM + stringsEng.CHALLENGE_MODALS.ENROLLED.EMOJI)
      setNewlyEnrolled(true)

    } else {
      // Failure: for now, the only error message says already completed challenge in the same day
      setModalText(stringsEng.CHALLENGE_MODALS.FAILED.TEXT);
      setModalEmoji(stringsEng.TEACHER.STUDENT_LIST.MODAL_CONFIRM + stringsEng.CHALLENGE_MODALS.FAILED.EMOJI)
    }
    setModalVisible(!modalVisible);
  };

  const onLogChallenge = async (event) => {
    const didLog = await logChallenge(id, challenge.challengeCode);

    if (didLog) {
      setModalText(stringsEng.CHALLENGE_MODALS.LOGGED.TEXT);
      setModalEmoji(stringsEng.TEACHER.STUDENT_LIST.MODAL_CONFIRM + stringsEng.CHALLENGE_MODALS.LOGGED.EMOJI)
      setHighlighted(numButtonsHighlighted + 1);
      setModalVisible(!modalVisible);
      setNewlyEnrolled(false)
      //Here, it will go to useEffect and log the challenge
    }
    else {
      // Failure: cannot log more than once
      setModalText("Cannot log a challenge more than once a day!");
      setModalEmoji(stringsEng.TEACHER.STUDENT_LIST.MODAL_CONFIRM + stringsEng.CHALLENGE_MODALS.FAILED.EMOJI)
    }
    setModalVisible(!modalVisible);
  };

  return (
    <ChallengesBackground navigation={navigation}>
      <ChallengesBody>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          {/* The zIndex makes it pressable when absolutely positioned */}
          <View style={{ position: "absolute", zIndex: 5 }}>
            <CFTButton
              iconSource={require("../../../../assets/images/icons/icon-arrow.png")}
              onPress={() => {
                setDisabled(false)
                setHighlighted(0);
                navigation.goBack();
              }}
              textColor={Colors.primary}
              buttonStyle={styles.backButton}
            />
          </View>
          <View style={{ flex: 1, marginLeft: 40 }}>

            <Modal
              animationType="slide"
              transparent={true}
              visible={modalVisible}
              onRequestClose={() => {
                setModalVisible(!modalVisible);
              }}
            >
              <View style={modalStyles.centeredView}>
                <View style={modalStyles.modalView}>
                  <Text style={modalStyles.modalText}>{modalText}</Text>
                  <Pressable
                    style={[modalStyles.button, modalStyles.buttonClose]}
                    onPress={() => { setModalVisible(!modalVisible); if(newlyEnrolled){navigation.goBack();} }}
                  >
                    <Emoji symbol={modalEmoji} />
                  </Pressable>
                </View>
              </View>
            </Modal>

            <Text className="h3" style={{ color: Colors.darkGrey }}>
              {challenge.challengeName}
            </Text>
            <Text
              style={{
                color: categoryColor,
                fontWeight: "700",
              }}
            >
              {categoryText}
            </Text>
          </View>
        </View>

        <ScrollView style={{ flex: 1, marginTop: 10, paddingBottom: 20 }}>
          <Text style={styles.sectionHeader}>
            {stringsEng.CHALLENGE_DETAILS.ABOUT}
          </Text>
          <Text style={styles.sectionText}>{challenge.description}</Text>

          <Text style={styles.sectionHeader}>
            {stringsEng.CHALLENGE_DETAILS.ASSUMPTIONS}
          </Text>
          <Text style={styles.sectionText}>{challenge.assumptions}</Text>

          <Text style={styles.sectionHeader}>
            {stringsEng.CHALLENGE_DETAILS.SOURCES}
          </Text>
          {/* <Text style={styles.sectionText}>{challenge.source}</Text> */}
          <View style={styles.sourceLinkscontainer}>
            {challenge.challengeDisplayData?.assumptionSource.map((source, i) => (
              <OpenURL url={source} categoryColor={categoryColor} key={i}>
                {i + 1}
              </OpenURL>
            ))}
          </View>

          <Text style={styles.sectionHeader}>
            {stringsEng.CHALLENGE_DETAILS.IMPACT}
          </Text>

          <View style={styles.numberBoxContainer}>
            <CarbonOffsetBox numberValue={challenge.carbonImpact} />
            <ClimaPointsBox numberValue={challenge.climaPoints} />
          </View>

          {/* TODO: hardcoded number */}
          <ChallengeDetailsMessage challenge={challenge} numberCompleted={0} />

          <ProgressNumbers
            numberOfValues={3}
            numberOfHighlighted={(disabled || started || enrolled) ? numButtonsHighlighted + originalNumHighlighted : 3}
            highlightColor={categoryColor}
            onClick={onLogChallenge}
          />
          <View>
            <CFTButton
              disabled={started || enrolled || disabled}
              text={
                completed
                  ? stringsEng.CHALLENGE_DETAILS.COMPLETED
                  : enrolled
                    ? stringsEng.CHALLENGE_DETAILS.ENROLLED
                    : started
                      ? stringsEng.CHALLENGE_DETAILS.LOG_CHALLENGE
                      : stringsEng.CHALLENGE_DETAILS.START
              }
              onPress={onPressStartChallenge}
              buttonStyle={[
                styles.startButton,
                { backgroundColor: categoryColor },
              ]}
            />
          </View>
          <View style={{ marginVertical: 60 }}>
            <FunFact fact={challenge.challengeDisplayData?.fact} sourceLink={challenge.challengeDisplayData?.source} />
          </View>
        </ScrollView>
      </ChallengesBody>
    </ChallengesBackground>
  );
}

const styles = StyleSheet.create({
  backButton: {
    alignSelf: "flex-start",
    margin: 0,
    padding: 10,
    backgroundColor: "transparent",
  },
  sectionHeader: {
    fontSize: 18,
    fontWeight: "700",
    color: Colors.darkGrey,
  },
  sectionText: {
    color: Colors.mediumGrey,
  },
  numberBoxContainer: {
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: 16,
  },
  startButton: {
    borderRadius: 100,
    marginHorizontal: 30,
    marginTop: 20,
  },
  sourceLinkscontainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    marginVertical: 3,
  },
  linkBox: {
    width: 20,
    height: 30,
    borderRadius: 10,
    marginHorizontal: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  numberText: { color: Colors.white, fontSize: 16 },
});
