import { ColorValue } from "react-native";
import { Theme } from "react-native-picasso/build/styles/defaultTheme";
import { QuestionCategory } from "./src/types/quiz.types";

export const Colors = {
  primary: "#222",
  white: "white",
  whiteSmoke: "#f5f5f5",
  desktopBackground: "#2B3D41",
  darkTurquoise: "#167897",
  cornflowerBlue: "#6495ED",
  deepCornflowerBlue: "#0493c8",
  mediumBlue: "#3380d0",
  // lochmara: "#2F7B99", // Darker blue-green
  limeGreen: "#8AD100",
  turquoise: "#00C4AD",
  skyBlue: "#00beec",
  deepSkyBlue: "#1fbfe5",
  summerSkyBlue: "#2CA2D0",
  iceBlue: "#6BB3DE",
  navyBlue: "#017DC9",
  purple: "#7E5EE5",
  lightBlueGrey: "#BAD6DD",
  darkGrey: "#435053",
  mediumGrey: "#7f7f7e",
  lightGrey: "#e4e4e4",
  mossGreen: "#bdcb4f",
  greenSmoke: "#9bb557",
  olive: "#698C0E",
  lightRed: "#E8777F",
  red: "#ff5265",
  orange: "#ff8b56",
  yellow: "#ffc100",
  icePurple: "#5586e3",
  darkRed: "#b50000",
  pastelBlue: "#e6edfa"
};

export const CategoryColors: Record<
  QuestionCategory | "recommended",
  ColorValue
> = {
  recommended: Colors.cornflowerBlue,
  [QuestionCategory.Food]: Colors.red,
  [QuestionCategory.Housing]: Colors.yellow,
  [QuestionCategory.Transport]: Colors.mossGreen,
  [QuestionCategory.Stuff]: Colors.orange,
  [QuestionCategory.Other]: Colors.deepSkyBlue,
};

export const theme: Theme = {
  ///// Overriding default classes
  textColors: {
    primary: Colors.primary,
    white: Colors.white,
    darkGrey: Colors.darkGrey,
  },
  font: {
    // family: "DINRoundPro-medium",
    family: "Poppins",
    sizes: {
      xxs: 12,
      xs: 14,
      sm: 16,
      md: 18,
      lg: 22,
      xl: 28,
      xxl: 34,
    },
  },
  spacing: {
    // allow zero padding or margin, i.e. className="p-0" or "pt-0" for paddingTop
    "0": 0,
  },

  ///// New classes
  h1: {
    fontFamily: "Poppins-semibold",
    fontSize: 34,
  },
  h2: {
    fontFamily: "Poppins-semibold",
    fontSize: 28,
  },
  h3: {
    fontFamily: "Poppins-semibold",
    fontSize: 22,
  },
  h4: {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: 18,
  },
  bold: {
    fontWeight: "bold",
  },
};
