import { api } from "./api";
import { FeedbackType } from "../Pages/Assignments/AssignmentReflection/assignment-reflection.component";

export const submitFeedback = (
  userId: string,
  assignmentId: string,
  classId: string,
  feedbackReq: FeedbackType
) => {
  return api
    .post(`/user/${userId}/class/${classId}/feedback`, {
      assignmentId,
      feedback: feedbackReq.feedback,
      assignmentRating: feedbackReq.difficulty,
    })
    .then((res) => {
      if (res.status === 200) {
        console.log("Feedback submitted successfully");
      }
    })
    .catch((e) => {
      console.log("Couldn't submit feedback", e);
    });
};
