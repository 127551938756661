import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  averageBar: {
    padding: 10,
    marginBottom: 25,
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
  },
});
