import { StyleSheet } from "react-native";
import React, { useEffect, useState } from "react";
import { View, Text } from "react-native-picasso";
import { stringsEng } from "../../../assets/text/strings-english";
import { Colors } from "../../../theme";
import { differenceBetweenDates } from "../../helpers/datetime";

type TimerBox = { num: number; label: string };

// pad the given number to 2 digits (e.g. 6 becomes 06)
const pad = (num: number): string => {
  return ("" + num).padStart(2, "0");
};

// Shows counts for days, hours, and minutes remaining before the deadline, or
// all zeros if the deadline has passed.
type Props = { deadline: Date; large?: boolean };
export function CountdownTimer({ deadline, large = false }: Props) {
  const [timeRemaining, setTimeRemaining] = useState<{
    days: number;
    hours: number;
    minutes: number;
  }>(null);

  // This runs once per component mount. Makes the timer update.
  useEffect(() => {
    const now = new Date();
    if (deadline.getTime() < now.getTime()) {
      // The deadline has passed, don't update the timer
      setTimeRemaining({ days: 0, hours: 0, minutes: 0 });
      return;
    }

    // Initial update for the timer while it's waiting for the loop to start
    setTimeRemaining(differenceBetweenDates(deadline, now));

    // The timer is set after the number of seconds left in the current minute.
    // Makes the component update every minute right at the boundary between
    // minutes
    const secondsLeftThisMin = (60000 - (now.getTime() % 60000)) / 1000;
    let timerInterval = null;

    // Starts the loop on the next minute boundary
    setTimeout(() => {
      // Starts the loop that updates every minute
      timerInterval = setInterval(() => {
        const now = new Date();

        if (deadline.getTime() < now.getTime()) {
          // The timer has reached 0, set it to 0 and stop updating it
          clearInterval(timerInterval);
          setTimeRemaining({ days: 0, hours: 0, minutes: 0 });
          return;
        } else {
          // Update the time remaining
          const diff = differenceBetweenDates(deadline, now);
          setTimeRemaining(diff);
        }
      }, 1000);
    }, secondsLeftThisMin);

    return () => clearInterval(timerInterval);
  }, []);

  // Don't render the components if the time remaining hasn't been set yet (to
  // prevent showing all zeros at first which could be confusing)
  if (timeRemaining === null) return null;

  const { days, hours, minutes } = timeRemaining;
  const boxes: TimerBox[] = [
    { num: days, label: stringsEng.COMMON.DATE_TIME.DAYS },
    { num: hours, label: stringsEng.COMMON.DATE_TIME.HOURS },
    { num: minutes, label: stringsEng.COMMON.DATE_TIME.MINUTES },
  ];

  return (
    <View style={styles.rowContainer}>
      {boxes.map(({ num, label }, i) => (
        <View key={i} style={styles.itemContainer}>
          <View style={styles.box}>
            <Text style={large ? styles.numberTextLg : styles.numberText}>
              {pad(num)}
            </Text>
          </View>
          <Text style={large ? styles.datetimeTextLg : styles.datetimeText}>
            {label}
          </Text>
        </View>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  rowContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  itemContainer: {
    alignItems: "center",
    marginRight: 5,
  },
  box: {
    paddingHorizontal: 8,
    borderRadius: 5,
    // TODO: gradient
    backgroundColor: Colors.cornflowerBlue,
  },
  numberText: {
    fontSize: 18,
    color: Colors.white,
  },
  numberTextLg: {
    fontSize: 28,
    color: Colors.white,
  },
  datetimeText: {
    fontSize: 8,
    color: Colors.mediumGrey,
  },
  datetimeTextLg: {
    fontSize: 10,
    color: Colors.mediumGrey,
  },
});
