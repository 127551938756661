
import { StyleSheet } from "react-native";
import { Colors } from "../../../../theme";

export const errorStyle = StyleSheet.create({
    error: {
        color: Colors.darkRed,
        textAlign: "center",
        fontSize: 12
    }

});