import React, { useState } from "react";
import { ActivityIndicator, Modal, Pressable } from "react-native";
import { Controller, useForm } from "react-hook-form";
import { View, Text } from "react-native-picasso";
import { stringsEng } from "../../../../assets/text/strings-english";
import CFTButton from "../../../components/Button/cft-button.component";
import { Colors } from "../../../../theme";
import CFTTextInput from "../../../components/TextInput/text-input.component";
import resolver from "./createClass.resolver";
import { styles } from "./createClass.styles";
import { errorStyle } from "../../Onboarding/Common/error-styles";
import TeacherService from "../../../services/teacher.service";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/userSlice";
import { modalStyles } from "../../../components/Modal/modal.styles";
import Emoji from "../../../components/Emoji/emoji.component";

export type CreateClassFormData = {
  className: string;
  classCode: string;
  classDescription: string;
  classStartDate: string; //Must be converted to date later
  classEndDate: string; //Must be converted to date later
  schoolCode: string;
  archived: boolean;
};

export default function CreateClassForm({}) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateClassFormData>({
    defaultValues: {
      className: "",
      classCode: "",
      classDescription: "",
      classStartDate: "",
      classEndDate: "",
      schoolCode: "",
      archived: false
    },
    resolver,
  });

  const [loader, setLoader] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalText, setModalText] = useState("");
  const [modalEmoji, setModalEmoji] = useState("");

  const user = useSelector(selectUser);

  const onSubmit = handleSubmit(async (data) => {
    setLoader(true);

    try {
      await TeacherService.createClass(data, user.id);
      setTimeout(() => {
        setLoader(false);
        setModalEmoji(stringsEng.TEACHER.STUDENT_LIST.MODAL_CONFIRM + stringsEng.TEACHER.CREATE_CLASS.MODAL.SUCCESS.EMOJI);
        setModalText(stringsEng.TEACHER.CREATE_CLASS.MODAL.SUCCESS.TEXT);
        setModalVisible(true);
      }, 500);
    } catch (error) {
      console.log(error);
      setModalEmoji(stringsEng.TEACHER.STUDENT_LIST.MODAL_CONFIRM + stringsEng.TEACHER.CREATE_CLASS.MODAL.FAILURE.EMOJI);
      setModalText(stringsEng.TEACHER.CREATE_CLASS.MODAL.FAILURE.TEXT);
      setModalVisible(true);
    }
  });

  return (
    <>
      <Controller
        name="className"
        control={control}
        rules={{ required: true }}
        render={({ field: { onBlur, onChange, value } }) => (
          <CFTTextInput
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            placeholderTextColor={Colors.white}
            autoCorrect={false}
            placeholder={stringsEng.TEACHER.CREATE_CLASS.NAME}
            iconColor={Colors.white}
          />
        )}
      />
      <Controller
        name="classCode"
        control={control}
        rules={{ required: true }}
        render={({ field: { onBlur, onChange, value } }) => (
          <CFTTextInput
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            placeholderTextColor={Colors.white}
            autoCorrect={false}
            placeholder={stringsEng.TEACHER.CREATE_CLASS.CODE}
            iconColor={Colors.white}
          />
        )}
      />
      <Controller
        name="classDescription"
        control={control}
        rules={{ required: true }}
        render={({ field: { onBlur, onChange, value } }) => (
          <CFTTextInput
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            placeholderTextColor={Colors.white}
            autoCorrect={false}
            placeholder={stringsEng.TEACHER.CREATE_CLASS.DESCRIPTION}
            iconColor={Colors.white}
          />
        )}
      />
      <Controller
        name="classStartDate"
        control={control}
        rules={{ required: true }}
        render={({ field: { onBlur, onChange, value } }) => (
          <CFTTextInput
            onBlur={onBlur}
            onChangeText={onChange}
            value={value.toString()}
            placeholderTextColor={Colors.white}
            autoCorrect={false}
            placeholder={stringsEng.TEACHER.CREATE_CLASS.START}
            iconColor={Colors.white}
          />
        )}
      />
      <Controller
        name="classEndDate"
        control={control}
        rules={{ required: true }}
        render={({ field: { onBlur, onChange, value } }) => (
          <CFTTextInput
            onBlur={onBlur}
            onChangeText={onChange}
            value={value.toString()}
            placeholderTextColor={Colors.white}
            autoCorrect={false}
            placeholder={stringsEng.TEACHER.CREATE_CLASS.END}
            iconColor={Colors.white}
          />
        )}
      />
      <Controller
        name="schoolCode"
        control={control}
        rules={{ required: true }}
        render={({ field: { onBlur, onChange, value } }) => (
          <CFTTextInput
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            placeholderTextColor={Colors.white}
            autoCorrect={false}
            placeholder={stringsEng.TEACHER.CREATE_CLASS.SCHOOL}
            iconColor={Colors.white}
          />
        )}
      />
      <View style={{ height: 15 }}>
        {errors.className && (
          <Text style={errorStyle.error}>{errors.className.message}</Text>
        )}
        {errors.classCode && (
          <Text style={errorStyle.error}>{errors.classCode.message}</Text>
        )}
        {errors.classDescription && (
          <Text style={errorStyle.error}>
            {errors.classDescription.message}
          </Text>
        )}
        {errors.classStartDate && (
          <Text style={errorStyle.error}>{errors.classStartDate.message}</Text>
        )}
        {errors.classEndDate && (
          <Text style={errorStyle.error}>{errors.classEndDate.message}</Text>
        )}
        {errors.schoolCode && (
          <Text style={errorStyle.error}>{errors.schoolCode.message}</Text>
        )}
      </View>
      {loader ? (
        <View style={{ flex: 1, justifyContent: "center", padding: 10 }}>
          <ActivityIndicator size={40} />
        </View>
      ) : (
        <View>
          <CFTButton
            text={stringsEng.TEACHER.CREATE_CLASS.SUBMIT}
            buttonStyle={styles.submitButton}
            buttonSize="md"
            onPress={onSubmit}
            textColor={Colors.iceBlue}
          />
        </View>
      )}
      <View style={{ flex: 1, marginLeft: 40 }}>
        <Modal
          animationType="slide"
          transparent={true}
          visible={modalVisible}
          onRequestClose={() => {
            setModalVisible(!modalVisible);
          }}
        >
          <View style={modalStyles.centeredView}>
            <View style={modalStyles.modalView}>
              <Text style={modalStyles.modalText}>{modalText}</Text>
              <Pressable
                style={[modalStyles.button, modalStyles.buttonClose]}
                onPress={() => {
                  setModalVisible(!modalVisible);
                }}
              >
                <Emoji symbol={modalEmoji} />
              </Pressable>
            </View>
          </View>
        </Modal>
      </View>
    </>
  );
}
