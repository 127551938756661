import { StyleSheet } from "react-native";
import { Colors } from "../../../../theme";

export const styles = StyleSheet.create({
    top: {
        alignItems: "flex-end",
        marginEnd: 20,
        marginTop: 30,
    },
    logoContainer: {
        ...StyleSheet.absoluteFillObject,
        alignItems: "center",
    },
    logo: {
        width: "30%",
        top: -40,
        aspectRatio: 0.7,
    },
 

});
