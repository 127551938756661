import {
  applyMiddleware,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import AsyncStorage from "@react-native-async-storage/async-storage";
import thunkMiddleware from "redux-thunk";
import challengesSlice from "./challengesSlice";
import userSlice from "./userSlice";

// Main resource used for structuring the redux logic: https://redux.js.org/tutorials/fundamentals/part-8-modern-redux

// Used by redux-persist. By default, this uses a one-level auto merge state
// reconciler. See here for what that means:
// https://www.npmjs.com/package/redux-persist#state-reconciler
const persistConfig = {
  key: "root",
  version: 1,
  storage: AsyncStorage,
};

// Combine reducers and allow them to be persisted
const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    user: userSlice,
    challenge: challengesSlice,
  })
);

// Enable thunk middleware
const middlewareEnhancer = applyMiddleware(thunkMiddleware);

// This is used to access all of the data stored in redux. Wherever needed, you
// can import this and access any store data with store.getState(), but often
// it's better to access data in the store through a selector with the
// useSelector hook (see userSlice.ts for selector examples)
export const store = configureStore({
  reducer: persistedReducer,
  enhancers: [middlewareEnhancer],
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // Ignore actions from redux persist when serializing
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Used by redux-persist in the PersistGate
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Hook to access dispatch (types it correctly for our store)
export const useAppDispatch: () => AppDispatch = useDispatch;
