import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  bgImage: {
    width: "100%",
    height: "100%",
    position: "absolute",
  },
  quizContent: {
    flex: 1,
    justifyContent: "space-between",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "100%",
    paddingHorizontal: 20,
    margin: 15,
  },
  buttonContainer: {
    width: "60%",
    marginHorizontal: 8,
    alignSelf: "center",
    height: 115,
    position: "absolute",
    bottom: 0,
    marginTop: 25
  },
  choiceButton: {
    marginVertical: 4,
    padding: 15,
    borderRadius: 10,
    backgroundColor: "#fffffff0",
  },
  choiceButtonSelected: {
    backgroundColor: "#007CBE",
  },
  funFactContainer: {
    justifyContent: "space-between",
    flexDirection: "column",
    flexWrap: "nowrap",
    marginTop: 50,
    flexBasis: 1
  },
});
