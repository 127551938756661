import { StyleSheet } from "react-native";
import { Colors } from "../../../../theme";

export const styles = StyleSheet.create({
  content: {
    paddingHorizontal: 40,
    backgroundColor: Colors.iceBlue,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  centerAlignContainer: {
    width: "100%",
    height: 0,
    overflow: "visible",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 70,
  },
  backgroundClimactLogo: {
    position: "absolute",
    top: -220,
    height: 200,
    width: "80%",
  },
  backgroundPenguin: {
    top: -18,
    width: 150,
    height: 200,
  },
  children: {
    flex: 1,
    justifyContent: "center",
  },
  mainFlexContainer: {
    flex: 5,
    justifyContent: "center",
  },
  backButtonFlexContainer: {
    flex: 1,
    justifyContent: "flex-end",
  },
  backButton: {
    opacity: 0.5,
    width: 100,
    height: 40,
    borderRadius: 16,
    alignSelf: "center",
    marginBottom: 10,
  },
});
