import React from "react";
import { TouchableOpacity } from "react-native-picasso";
import {
  Image,
  ImageSourcePropType,
  StyleProp,
  ImageStyle,
  GestureResponderEvent,
} from "react-native";

export type PressableImageProps = {
  onPress: (event: GestureResponderEvent) => void;
  imageStyle: StyleProp<ImageStyle>;
  imageSource: ImageSourcePropType;
};

export default function PressableImage({
  onPress,
  imageStyle,
  imageSource,
}: PressableImageProps) {
  return (
    <TouchableOpacity onPress={onPress}>
      <Image source={imageSource} style={imageStyle} resizeMode="contain" />
    </TouchableOpacity>
  );
}
