import React, { useEffect, useState } from "react";
import { TouchableOpacity, View, Text } from "react-native-picasso";
import { Colors } from "../../../theme";
import { StyleSheet } from "react-native";

// A Filter, visually, is one of the tabs that the user can press to see a
// different set of items (e.g. In Progress, Done). The filterFn is a function
// that is used to select the items to be shown for that filter. It should
// should return true if that item should be included in the filter, or false if
// it should be excluded.
export type Filter<T> = { title: string; filterFn: (item: T) => boolean };
export type FilterResult<T> = { title: string; filteredItems: T[] };

type UseFiltersReturn<ItemT> = {
  filterResults: FilterResult<ItemT>[];
  selectedFilterIndex: number;
  // Updater function from useState
  setSelectedFilterIndex: React.Dispatch<React.SetStateAction<number>>;
};

// Hook to apply the filters to the given list of items in preparation for the
// FilterTabs component
export function useFilters<ItemT>(
  items: ItemT[],
  filters: Filter<ItemT>[],
  defaultFilterIndex: number = 0
): UseFiltersReturn<ItemT> {
  const [selectedFilterIndex, setSelectedFilterIndex] =
    useState(defaultFilterIndex);

  const [filterResults, setFilterResults] = useState<FilterResult<ItemT>[]>(
    filters.map((filter) => ({ title: filter.title, filteredItems: [] }))
  );

  useEffect(() => {
    // Filter the given items by each filter function
    setFilterResults(
      filters.map((filter) => ({
        ...filter,
        filteredItems: items.filter(filter.filterFn),
      }))
    );
  }, [items, filters]);

  return { selectedFilterIndex, setSelectedFilterIndex, filterResults };
}

type Props<ItemT> = UseFiltersReturn<ItemT>;
export function FilterTabs<ItemT = any>({
  filterResults,
  selectedFilterIndex,
  setSelectedFilterIndex,
}: Props<ItemT>) {
  return (
    <View style={styles.container}>
      {filterResults.map(({ title, filteredItems }, i) => (
        <TouchableOpacity
          key={i}
          style={[
            styles.filter,
            i === selectedFilterIndex ? styles.filterSelected : {},
          ]}
          onPress={() => setSelectedFilterIndex(i)}
        >
          <Text style={styles.filterText}>{title}</Text>
          <View style={styles.filterCountBox}>
            <Text style={styles.filterCount}>{filteredItems.length}</Text>
          </View>
        </TouchableOpacity>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    marginBottom: 16,
  },
  filter: { flexDirection: "row", alignItems: "center" },
  filterSelected: { borderColor: Colors.white, borderBottomWidth: 2 },
  filterText: { color: Colors.white, marginBottom: 5, marginRight: 5 },
  filterCountBox: {
    width: 20,
    height: 20,
    backgroundColor: Colors.purple,
    borderRadius: 3,
    justifyContent: "center",
    alignItems: "center",
    margin: 5,
  },
  filterCount: { fontSize: 10, color: Colors.white },
});
