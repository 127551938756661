import React, { useEffect, useState, useCallback, useContext } from "react";
import { Alert, ScrollView, StyleSheet, Linking, Modal, Pressable, FlatList, Image } from "react-native";
import { Text, View } from "react-native-picasso";
import { CategoryColors, Colors } from "../../../../theme";
import ChallengesBody from "../../Challenges/Common/challenges-body.component";
import { funFacts } from "../../../../assets/text/fun-facts";
import { stringsEng } from "../../../../assets/text/strings-english";
import ChallengesBackground from "../../Challenges/Common/challenges-background.component";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/userSlice";
import { useIsFocused } from '@react-navigation/native';
import { TouchableOpacity } from "react-native-picasso";
import { modalStyles } from "../../../components/Modal/modal.styles";
import Emoji from "../../../components/Emoji/emoji.component";
import GradientBackground from "../../../components/Background/gradient-background.component";
import { ClassItem } from "../../../components/Teacher/ClassItem/class-item.component";
import { PageSizeContext } from "../../../contexts/page-size-context";
import * as classItemStyles from "../../../components/Teacher/ClassItem/class-item.styles";
import * as assignmentBoxStyles from "../../Assignments/AssignmentItem/assignment-item.styles"
import { CountdownTimer } from "../../../components/CountdownTimer/countdown-timer.component";
import { format } from "date-fns";

export function ClassStat({ statData }) {
    const { width } = useContext(PageSizeContext);
    const items = [
        {
            text: stringsEng.PROFILE.ASSIGNMENTS,
            value: statData.assignmentCompleted,
        },
        {
            text: stringsEng.TEACHER.CLASS_DETAILS.FOOTPRINT_AVG,
            value: String(Math.round(parseInt(statData.averageFootprint))),
        },
        {
            text: stringsEng.TEACHER.CLASS_DETAILS.SAVED,
            value: String(Math.round(parseInt(statData.saved))),
        },
    ];

    return (
        <View className="mt-sm" style={styles.container}>
            <FlatList
                data={items}
                contentContainerStyle={[styles.gridView, { width: width }]}
                numColumns={3}
                keyExtractor={(item) => item.text}
                renderItem={({ item }) => (
                    <View style={styles.itemContainer} className="ml-sm mr-sm">
                        <Text className="h3 align-center" style={styles.text}>
                            {item.value}
                        </Text>
                        <Text className="size-xxs align-center" style={styles.text}>
                            {item.text}
                        </Text>
                    </View>
                )}
            />
        </View>
    );
}



export default function ClassDetails({ navigation, route }) {
    const user = useSelector(selectUser);

    //type error because i added temporary value teacherClassData; might make permanenet later
    const classData = user.teacherClassData.class
    const assignments = user.teacherClassData.assignments;
    let students = user.teacherClassData.students;
    students = [...students].sort((a, b) => a.climaPointsEarned < b.climaPointsEarned)

    function getDateString(date) {
        date = new Date(date)
        var changedDate = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
        return changedDate;
    }

    return (
        <>
            <GradientBackground />
            <ChallengesBackground navigation={navigation}>
                <ChallengesBody backgroundColor={Colors.lightGrey}>
                    <ScrollView>
                        <ClassItem startDate={new Date(classData.classStartDate)}
                            endDate={new Date(classData.classEndDate)}
                            classCode={classData.classCode}
                            className={classData.className}
                            activeAssignments={0}
                            numStudents={students.length}
                            onPress={() => { }}
                            detailsPage={true}
                        />

                        <ClassStat statData={{ assignmentCompleted: classData.assignmentsCompleted, averageFootprint: classData.averageFootprint + " kg", saved: classData.co2Saved + " kg" }} />

                        <View>
                            <View style={[classItemStyles.styles.classBox, { backgroundColor: Colors.cornflowerBlue }]}>
                                <View style={{ flexDirection: "row", justifyContent: "space-between" }}>

                                    <Text style={classItemStyles.styles.header}>{stringsEng.TEACHER.CLASS_DETAILS.LEADERBOARD}</Text>
                                </View>
                                <Text style={classItemStyles.styles.subtitle}>{stringsEng.TEACHER.CLASS_ITEM.FROM}{getDateString(classData.classStartDate)}{stringsEng.TEACHER.CLASS_ITEM.TO}{getDateString(classData.classEndDate)}</Text>
                                <View>
                                    <FlatList
                                        data={students}
                                        contentContainerStyle={[styles.topStudentsContainer]}
                                        keyExtractor={(item) => students.indexOf(item) + 1}
                                        renderItem={({ item }) => (
                                            <View style={styles.student} className="ml-sm mr-sm">
                                                <Text className="size-xxs align-center" style={styles.rankNumber}>
                                                    #{students.indexOf(item) + 1}   
                                                </Text>
                                                <View style={styles.profilePicBox}>
                                                    <Image style={styles.profilePic} source={require("../../../../assets/images/icons/panda.png")}></Image>
                                                </View>
                                                <Text className="size-xxs align-center" style={styles.studentName}>
                                                    {item.firstName}
                                                </Text>
                                            </View>
                                        )}
                                    />
                                </View>
                            </View>
                        </View>

                        <View>

                            <FlatList
                                data={assignments}
                                contentContainerStyle={[]}
                                keyExtractor={(item) => item.assignmentName}
                                renderItem={({ item }) => (

                                    <TouchableOpacity style={[assignmentBoxStyles.styles.container, styles.assignmentItem]}>
                                        <Image
                                            style={assignmentBoxStyles.styles.image}
                                            resizeMode="contain"
                                            source={require("../../../../assets/images/assignment.png")}
                                        />
                                        <View style={assignmentBoxStyles.styles.contentContainer}>

                                            <View style={assignmentBoxStyles.styles.textContainer}>
                                                <Text style={assignmentBoxStyles.styles.titleText}>{item.assignmentName}</Text>
                                                {item.status === "completed" ? (
                                                    // View when assignment is completed shows "completed on" date
                                                    <Text style={assignmentBoxStyles.styles.subText}>
                                                        {stringsEng.ASSIGNMENT.ASSIGNMENT_ITEM.COMPLETED_ON}{" "}
                                                        {/* TODO: i18n for date; displays something like "August 2" */}
                                                        {format(item.updatedAt, "MMMM d")}
                                                    </Text>
                                                ) : (
                                                    // View when assignment is not completed: shows time remaining
                                                    <>
                                                        <Text style={assignmentBoxStyles.styles.subText}>
                                                            {stringsEng.ASSIGNMENT.ASSIGNMENT_ITEM.TIME_REMAINING}
                                                        </Text>
                                                        <CountdownTimer deadline={new Date(item.dueDate)} />
                                                    </>
                                                )}
                                            </View>
                                        </View>


                                    </TouchableOpacity>

                                )}
                            />

                        </View>

                    </ScrollView>
                </ChallengesBody>
            </ChallengesBackground>
        </>
    );
}

const styles = StyleSheet.create({
    assignmentItem: {
        marginTop: 15
    },
    profilePicBox: {
        backgroundColor: Colors.limeGreen,
        borderRadius: 100,
        height: 45,
        width: 45,
        zIndex: -1,
        alignSelf: "center",
        
    },

    profilePic: {
        height: 35,
        width: 30,
        alignSelf: "center",
        top: 10

    },
    topStudentsContainer: {
        justifyContent: "flex-start",
        flexDirection: "row",
        marginTop: 5
    },
    student: {
    },
    rankNumber: {
        color: Colors.white,
        top: 0,
        position: "absolute"
    },
    studentName: {
        color: Colors.white,
        textAlign: "center"
    },
    gridView: {
        flex: 1,
        justifyContent: "center"
    },
    itemContainer: {
        borderRadius: 10,
        padding: 10,
        backgroundColor: Colors.white,
        width: "27%",
    },
    text: {
        color: Colors.cornflowerBlue,
    },
    container: {
        justifyContent: "center",
        alignContent: "center"
    }
});


