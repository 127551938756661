import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { View, Text } from "react-native-picasso";
import { stringsEng } from "../../../../assets/text/strings-english";
import { Colors } from "../../../../theme";
import CFTButton from "../../../components/Button/cft-button.component";
import PressableText from "../../../components/Button/pressable-text.component";
import CFTTextInput from "../../../components/TextInput/text-input.component";
import { useAppDispatch } from "../../../redux/store";
import { login } from "../../../redux/userSlice";
import { loginUser } from "../../../services/auth";
import resolver from "./login-resolver";
import { styles } from "./login.styles";
import { errorStyle } from "../Common/error-styles";

export type LoginFormData = {
  username: string;
  password: string;
  validLogin: boolean;
};

export default function LoginForm({ navigation }) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormData>({
    defaultValues: {
      username: "",
      password: "",
      validLogin: false,
    },
    resolver,
  });

  const dispatch = useAppDispatch();
  const [hidePass, setHidePass] = useState(true);

  const onSubmit = handleSubmit(async (data) => {
    // Don't have to manually navigate to dashboard; automatically switches to
    // dashboard from authentication pages when logged in (see logic in
    // navigation.stack)
    const userData = await loginUser(data);
    if (userData !== null) {
      dispatch(login(userData));
    } else {
      //When a user isn't found, display appropriate error msg
      control._formState.errors.validLogin = {
        type: "required",
        message: stringsEng.ONBOARDING.LOGIN.ERRORS.NOT_FOUND,
      };
    }
  });

  const onPressForgotPassword = () => {
    navigation.navigate("ForgotPassword", { name: "ForgotPassword" });
  };

  return (
    <View>
      <Controller
        name="username"
        control={control}
        rules={{ required: true }}
        render={({ field: { onBlur, onChange, value } }) => (
          <CFTTextInput
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            placeholderTextColor="white"
            autoCorrect={false}
            textContentType="username"
            placeholder={stringsEng.ONBOARDING.LOGIN.USERNAME}
            iconSource={require("../../../../assets/images/icons/icon_person.png")}
            iconColor={Colors.white}
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        rules={{ required: true }}
        render={({ field: { onBlur, onChange, value } }) => (
          <>
            <CFTTextInput
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              placeholderTextColor="white"
              autoCorrect={false}
              textContentType="password"
              placeholder={stringsEng.ONBOARDING.LOGIN.PASSWORD}
              iconSource={
                hidePass
                  ? require("../../../../assets/images/icons/icon_eye-slash.png")
                  : require("../../../../assets/images/icons/icon_eye.png")
              }
              iconColor={Colors.white}
              secureTextEntry={hidePass ? true : false}
              onIconPress={() => setHidePass(!hidePass)}
            />
          </>
        )}
      />

      <CFTButton
        text={stringsEng.ONBOARDING.HOME_SCREEN.LOGIN}
        textColor={Colors.iceBlue}
        buttonStyle={styles.loginButton}
        buttonSize="lg"
        onPress={onSubmit}
        iconSource={require("../../../../assets/images/icons/icon_lock.png")}
      />

      {/*TODO: make this more efficient*/}
      {errors.username && (
        <Text style={errorStyle.error}>{errors.username.message}</Text>
      )}
      {errors.password && (
        <Text style={errorStyle.error}>{errors.password.message}</Text>
      )}
      {errors.validLogin && (
        <Text style={errorStyle.error}>{errors.validLogin.message}</Text>
      )}

      {/* TODO:*/}
      <PressableText
        className="color-white pt-0"
        onPress={onPressForgotPassword}
      >
        {stringsEng.ONBOARDING.LOGIN.FORGOT_PASSWORD}
      </PressableText>
    </View>
  );
}
