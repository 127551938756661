import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
  },
  buttonRow: {
    flex: 1,
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  //Equivalence button styles
  equivalenceButton: {
    flex: 1,
    alignContent: "center",
    justifyContent: "center",
    margin: 10,
    borderRadius: 3,
    aspectRatio: 1,
  },
  equivalenceButtonImageContainer: {
    padding: 5,
    margin: 5,
    borderRadius: 3,
  },
  equivalenceButtonImage: {
    resizeMode: "center",
    width: "100%",
    height: "100%",
    aspectRatio: 1,
  },
  equivalentExplanationTextContainer: {
    padding: 40,
  },
  equivalentExplanationText: {
    fontSize: 16,
  },
});
