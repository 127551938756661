import { Resolver } from "react-hook-form";
import { stringsEng } from "../../../../assets/text/strings-english";
import { LoginFormData } from "./login-form.component";

const resolver: Resolver<LoginFormData> = async (values) => {
    return {
        values: values.username ? values : values.password ? values : {},
        errors: !values.username
            ? {
                username: {
                    type: 'required',
                    message: stringsEng.ONBOARDING.LOGIN.ERRORS.USERNAME
                },
            } :
            !values.password ? {
                password: {
                    type: 'required',
                    message: stringsEng.ONBOARDING.LOGIN.ERRORS.PASSWORD
                }
            } :

                {},
    };
};

export default resolver;