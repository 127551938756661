import React, { useContext, useState } from "react";
import { GestureResponderEvent, ImageBackground } from "react-native";
import { SafeAreaView, Text, View } from "react-native-picasso";
import CFTButton from "../../components/Button/cft-button.component";
import PressableText from "../../components/Button/pressable-text.component";
import FunFact from "../../components/FunFact/fun-fact.component";
import HeaderBanner from "../../components/HeaderBanner/header-banner.component";
import { PageSizeContext } from "../../contexts/page-size-context";
import QuizMultiSelect from "./quiz-multi-select.component";
import { styles } from "./quiz.styles";

import { quizData } from "../../../assets/text/quiz-questions";
import {
  QuizAnswer,
  QuizItem,
  QuizQuestion,
  QuizAnswers,
} from "../../types/quiz.types";
import QuizScale from "./quiz-scale.component";
import { Colors } from "../../../theme";
import QuizNumberInputs from "./quiz-number-inputs.component";

function transformQuizItems(items: QuizItem<QuizQuestion>[]) {
  const results: QuizAnswers = {
    food: {},
    transport: {},
    housing: {},
    stuff: {},
    other: {},
  };

  for (let item of items) {
    let value: {} | number = null;
    switch (item.type) {
      case "MultiSelect":
        if (item.choices.length === 1) {
          // If only one choice, just use the multiselect key (e.g. so you don't
          // have to do food.compost.compost)
          value = Number(Boolean(item.choices[0].selected));
        } else {
          value = {};
          for (let choice of item.choices) {
            // Each value is either 0 or 1. Convert to bool first because
            // Number(undefined) == NaN
            value[choice.key] = Number(Boolean(choice.selected));
          }
        }
        break;
      case "NumberInputs":
        value = {};
        for (let numberInput of item.inputs) {
          value[numberInput.key] = numberInput.value;
        }
        break;
      case "Scale":
        value = item.value;
        break;
    }
    if (item.type === "Scale" && item.groupKey) {
      // Collect answers of a single groupKey
      let groupObj = results[item.category][item.groupKey];
      if (!groupObj) groupObj = results[item.category][item.groupKey] = {};
      groupObj[item.key] = value;
    } else {
      results[item.category][item.key] = value;
    }
  }

  return results;
}

export default function Quiz({ navigation }) {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  // The value of selectedChoices[0] is the index of the user's choice, 0 to 3,
  //  for the first question (0th index). Initialized with empty slots
  //  (undefined)
  const [quizItems, setQuizItems] = useState<QuizItem<QuizQuestion>[]>(
    quizData.slice()
  );

  const currentQuizQuestion = quizItems[currentQuestionIndex];
  const isLastQuestion = currentQuestionIndex === quizItems.length - 1;

  const navigateToResults = () => {
    navigation.navigate("QuizPages", {
      screen: "LoadingQuizResults", params: {
        quizResults: transformQuizItems(quizItems),
      }
    });
  };

  const setAnswer = (answer: Partial<QuizAnswer>) => {
    setQuizItems((items) => {
      const item = items[currentQuestionIndex];
      if (item.type === answer.type) {
        // @ts-ignore this is typesafe because of the type check
        items[currentQuestionIndex] = { ...item, ...answer };
      } else {
        console.error("Could not set answer: question/answer type mismatch");
      }
      return [...items];
    });
  };

  const onNextPressed = (event: GestureResponderEvent) => {
    if (isLastQuestion) {
      navigateToResults();
    } else {
      setCurrentQuestionIndex((cur) => cur + 1);
    }
  };
  const onPrevPressed = (event: GestureResponderEvent) => {
    if (currentQuestionIndex === 0) return;
    setCurrentQuestionIndex((cur) => cur - 1);
  };

  const onAveragePressed = (event: GestureResponderEvent) => {
    // Set the current quiz question to its average value and go to the next
    // question
    switch (currentQuizQuestion.type) {
      case "MultiSelect":
        setAnswer({
          type: "MultiSelect",
          key: currentQuizQuestion.key,
          choices: currentQuizQuestion.choices.map((choice) => ({
            ...choice,
            selected: choice.averageSelected,
          })),
        });
        break;
      case "Scale":
        setAnswer({
          type: "Scale",
          key: currentQuizQuestion.key,
          value: currentQuizQuestion.averageValue,
        });
        break;
      case "NumberInputs":
        setAnswer({
          type: "NumberInputs",
          key: currentQuizQuestion.key,
          inputs: currentQuizQuestion.inputs.map((input) => ({
            ...input,
            value: input.averageValue,
          })),
        });
        break;
    }

    onNextPressed(event);
  };

  const { height, width } = useContext(PageSizeContext);

  if (!currentQuizQuestion) return null;

  return (
    <>
      <ImageBackground
        style={styles.bgImage}
        source={require("../../../assets/gradient-bg.png")}
      />
      <ImageBackground
        style={{
          width: "60%",
          aspectRatio: 1,
          left: 0,
          bottom: -80,
          // marginLeft: -50,
          // marginBottom: -80,
          // overflow: "hidden",
          position: "absolute",
        }}
        source={require("../../../assets/images/polar-bear-question.png")}
        resizeMode="contain"
      />
      <HeaderBanner />
      <SafeAreaView className="flex-1">
        <View style={styles.quizContent}>
          <Text>
            Question {currentQuestionIndex + 1} of {quizData.length}
          </Text>
          <Text className="h4">{currentQuizQuestion.question}</Text>
          <View className="flex-0.55 justifycontent-center alignitems-center mt-md">
            {currentQuizQuestion.type === "MultiSelect" ? (
              <QuizMultiSelect
                currentQuizQuestion={currentQuizQuestion}
                setAnswer={setAnswer}
              />
            ) : currentQuizQuestion.type === "Scale" ? (
              <QuizScale
                currentQuizQuestion={currentQuizQuestion}
                setAnswer={setAnswer}
              />
            ) : currentQuizQuestion.type === "NumberInputs" ? (
              <QuizNumberInputs
                currentQuizQuestion={currentQuizQuestion}
                setAnswer={setAnswer}
                maxLimit={currentQuizQuestion.max}
              />
            ) : null}
          </View>

          <View style={styles.funFactContainer}>
            <PressableText
              touchableOpacityClassName="alignself-center"
              className="pt-0"
              onPress={onAveragePressed}
            >
              Skip and use average
            </PressableText>
          </View>

          {/* <View style={styles.funFactContainer}>
          <FunFact {...currentQuizQuestion.funFact} />
        </View> */}
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <View style={styles.buttonContainer}>
              <CFTButton
                text={isLastQuestion ? "Done" : "Next"}
                onPress={onNextPressed}
                buttonStyle={{
                  backgroundColor: Colors.iceBlue,
                  borderRadius: 50,
                  marginBottom: 0,
                }}
              />
              <CFTButton
                text={"Previous"}
                disabled={currentQuestionIndex === 0}
                onPress={onPrevPressed}
                buttonStyle={{
                  backgroundColor: Colors.cornflowerBlue,
                  borderRadius: 50,
                }}
              />
            </View>
          </View>
        </View>
      </SafeAreaView>
    </>
  );
}
