import { FunFactType } from "../components/FunFact/fun-fact.component";

export type QuizItem<QuestionType extends QuizQuestion> = {
  question: string;
  category: QuestionCategory;
  funFact: FunFactType;
} & QuestionType;

export enum QuestionCategory {
  Food = "food",
  Transport = "transport",
  Housing = "housing",
  Stuff = "stuff",
  Other = "other",
}

export type QuizQuestion =
  | MultiSelectQuestion
  | ScaleQuestion
  | NumberInputsQuestion;
export type QuizAnswer = MultiSelectAnswer | ScaleAnswer | NumberInputsAnswer;

export type MultiSelectOption = {
  label: string;
  unselectedLabel?: string;
  key: string;
  averageSelected: boolean; // On average, does this apply to a given person?
  selected?: boolean; // Whether this choice has been selected by the user (or defaultSelected and not changed)
  defaultSelected?: boolean; // Whether this choice should appear selected by default
};
export type MultiSelectQuestion = MultiSelectAnswer;
export type MultiSelectAnswer = {
  type: "MultiSelect";
  key: string;
  choices: MultiSelectOption[];
};

export type ScaleQuestion = {
  unit: string; // e.g. "hours"
  averageValue: number;
  max: number;
  scaleValuePrefix?: string; // e.g. "$" for CAD
  scaleValueSuffix?: string; // e.g. "h" for hours
} & ScaleAnswer;
export type ScaleAnswer = {
  type: "Scale";
  key: string;
  groupKey?: string; // e.g. for grouping car, bus etc into "motor-vehicles"
  value?: number; // Whatever number the user enters
};

export type NumberInput = {
  label: string;
  key: string;
  averageValue: number;
  value?: number;
};
export type NumberInputsQuestion = {} & NumberInputsAnswer;
export type NumberInputsAnswer = {
  type: "NumberInputs";
  key: string;
  inputs: NumberInput[];
  max?: number;
};

export type QuizAnswers = Record<
  QuestionCategory,
  { [key: string]: number | { [key: string]: number } }
>;

// tons CO2e for each category. Total footprint is the sum of values
export type QuizResults = Record<QuestionCategory, number>;
