import React from "react";
import { Text, TouchableOpacity } from "react-native-picasso";
import { styles } from "./button.styles";

export default function PressableText({
  children,
  onPress,
  className = "",
  touchableOpacityClassName = "",
}) {
  return (
    <TouchableOpacity className={touchableOpacityClassName} onPress={onPress}>
      <Text className={className} style={styles.pressableText}>
        {children}
      </Text>
    </TouchableOpacity>
  );
}
