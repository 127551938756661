import { FunFactType } from "../../src/components/FunFact/fun-fact.component";

export const funFacts: { readonly [key: string]: FunFactType } = {
  farmAnimals: {
    fact: "Farm animals account for about half of the world's heat-trapping gas emissions.",
    sourceLink: [new URL(
      "https://kids.nationalgeographic.com/science/article/climate-change"
    ),]
  },
  foodProduction: {
    fact: "Food production is responsible for 25% of all greenhouse gas emissions.",
    sourceLink: [new URL(
      "https://www.wwf.org.uk/updates/10-myths-about-climate-change"
    ),]
  },
  treeMass: {
    fact: "Trees are made almost entirely out of air! Well, specifically Carbon Dioxide (CO2). A tree gets 95% of its mass by taking CO2 out of the air and using it to build its branches, leaves, and roots.",
    sourceLink: [new URL(
      "https://peachtreearborists.com/where-do-trees-get-their-mass-from/"
    ),]
  },
  cups: {
    fact: "At least 58 billion disposable cups are used each year globally. It takes about 32 million trees each year to create these cups and they emit as much greenhouse gasses as half a million cars!",
    sourceLink: [new URL("https://onetreeplanted.typeform.com/to/D24GZj")],
  },
  plastic: {
    fact: "There are more plastic pieces in the sea than stars in the sky? According to estimates, by 2050, oceans will have more plastic than fish if present trends continue.",
    sourceLink: [new URL ("https://www.unep.org/publications-data"),]
  },
  humanCausedMyth: {
    fact: "There is a myth that humans don't contribute significantly to climate change. Global temperatures are now at their highest since records began. This rapid warming corresponds to the increasing level of carbon dioxide in the atmosphere caused by humans.",
    sourceLink: [new URL(
      "https://www.wwf.org.uk/updates/10-myths-about-climate-change"
    ),]
  },
  greenland: {
    fact: "In the past decade, 3.5 trillion tons of ice has melted from the surface of Greenland. This is enough to cover the entire UK with meltwater around 45 feet deep!",
    sourceLink: [new URL(
      "https://www.greenpeace.org/usa/issues/climate-change-impacts/ "
    ),]
  },
  availableWater: {
    fact: "Less than 1% of all the water on Earth can (realistically) be used by people. The rest is either frozen or salt water; that is why it's important to use it wisely.",
    sourceLink: [new URL("https://www.epa.gov/watersense/watersense-kids"),]
  },
  handWashing: {
    fact: "When you're washing dishes... Hand washing uses 5 times as much water as an efficient dishwasher",
    sourceLink: [new URL("https://onetreeplanted.typeform.com/to/D24GZj"),]
  },
  oxygen: {
    fact: "Between 50% and 80% of the oxygen we breathe is produced by the oceans! Most of this is from microscopic plants called phytoplankton.",
    sourceLink: [new URL(
      "https://oceanservice.noaa.gov/facts/ocean-oxygen.html"
    ),]
  },
};
