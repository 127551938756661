import { api } from "./api";
import { CreateClassFormData } from "../pages/Teacher/CreateClass/createClassForm.component";

// class for the all the api calls related to the teacher
export default class TeacherService {
  // create a new class
  static createClass = async (createClassData: CreateClassFormData, userId) => {
    try {
      const res = await api.post(`/user/${userId}/classes/create`, {
        ...createClassData,
      });
      if (res.data.success) {
        return res.data;
      } else {
        console.log("Could not create class.");
        return null;
      }
    } catch (err) {
      console.log("Could not create class. " + err);
      return null;
    }
  };

  // create a new assignment
  static createAssignment = async (createAssignmentData, userId) => {
    try {
      const res = await api.post(`/user/${userId}/assignment/create`, {
        ...createAssignmentData,
      });
      if (res.data.success) {
        return res.data;
      } else {
        console.log("Could not create assignment.");
        return null;
      }
    } catch (err) {
      console.log("Could not create assignment. " + err);
      return null;
    }
  };

  // edit assignment
  static editAssignment = async (assignmentData, assignmentId, userId) => {
    try {
      const res = await api.put(
        `/user/${userId}/assignment/${assignmentId}/edit`,
        { ...assignmentData }
      );
      if (res.data.success) {
        return res.data;
      } else {
        console.log("Could not edit assignment.");
        return null;
      }
    } catch (err) {
      console.log("Could not edit assignment. " + err);
      return null;
    }
  };

  static deleteAssignment = async (assignmentId, classCode, userId) => {
    try {
      const res = await api.delete(
        `/user/${userId}/class/${classCode}/assignment/${assignmentId}`
      );
      if (res.data.success) {
        return true;
      } else {
        console.log("Could not delete assignment.");
        return null;
      }
    } catch (err) {
      console.log("Could not delete assignment. " + err);
      return null;
    }
  };

  //get all of the classes that one teacher created
  static getTeacherClasses = async (teacherId) => {
    try {
      const res = await api.get(`/class/teacher/${teacherId}`);
      if (res.data) {
        return res.data;
      } else {
        console.log("Could not get teacher classes.");
        return null;
      }
    } catch (err) {
      console.log("Could not get teacher classes. " + err);
      return null;
    }
  };
}
