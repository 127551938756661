import { ImageBackground, StyleSheet } from "react-native";
import React from "react";

const bg = require("../../../assets/gradient-bg.png");
const verticalBg = require("../../../assets/images/background-vertical.png");

// If vertical is true, the "vertical" version of the background image is used
// which has denser shapes
export default function GradientBackground({
  vertical = false,
}: {
  vertical?: boolean;
}) {
  return (
    <ImageBackground source={vertical ? verticalBg : bg} style={styles.bg} />
  );
}

const styles = StyleSheet.create({
  bg: {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: -2,
  },
});
