import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  bgImage: {
    width: "100%",
    height: "100%",
    position: "absolute",
  },
  speechContainer: {
    width: "80%",
    position: "absolute",
    alignSelf: "center",
  },
  speechTextLg: {
    marginTop: 20,
    marginBottom: 5,
    width: "62.5%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  speechTextSm: {
    fontFamily: "Poppins-semibold",
    marginBottom: 5,
    width: "62.5%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  startButton: {
    backgroundColor: "white",
    alignSelf: "center",
    padding: 10,
    marginBottom: 48,
  },
});
