import { StyleSheet } from "react-native";
import { Colors } from "../../../../theme";

export const styles = StyleSheet.create({
  backButton: {
    alignSelf: "flex-start",
    margin: 0,
    marginBottom: 10,
    padding: 10,
    backgroundColor: "transparent",
  },
  title: {
    fontSize: 30,
    color: Colors.summerSkyBlue,
    fontFamily: "Poppins-semibold",
  },
  goalText: {
    fontSize: 14,
    color: Colors.summerSkyBlue,
  },
  timeRemainingContainer: {
    flexDirection: "row",
    marginTop: 15,
  },
  timeRemainingBox: {
    padding: 2,
    marginRight: 10,
    marginTop: 5,
    borderRadius: 4,
    backgroundColor: Colors.lightGrey,
    alignSelf: "flex-start",
  },
  timeRemainingText: {
    fontSize: 10,
    color: Colors.summerSkyBlue,
    fontFamily: "Poppins-semibold",
  },
  yourAchievementsTextContainer: {
    marginTop: 10,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  medalIcon: {
    width: 30,
    height: 30,
    tintColor: Colors.cornflowerBlue,
  },
  yourAchievementsText: {
    color: Colors.cornflowerBlue,
    fontSize: 14,
    fontFamily: "Poppins-semibold",
  },
  achievement: {
    borderRadius: 15,
    padding: 5,
    marginBottom: 15,
    flexDirection: "row",
    alignItems: "center",
  },
  achievementBox: {
    margin: 10,
    width: 60,
    height: 60,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 15,
  },
  achievementBoxNumber: {
    fontSize: 26,
    fontFamily: "Poppins-semibold",
    color: Colors.white,
  },
  achievementTextContainer: {
    flex: 1,
    justifyContent: "center",
    marginLeft: 5,
    marginRight: 20,
  },
});
