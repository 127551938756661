import { StyleSheet } from "react-native";
import { Colors } from "../../../../theme";

export const styles = StyleSheet.create({
  titleContainer: { flexDirection: "row", alignItems: "center" },
  title: {
    color: Colors.cornflowerBlue,
    fontSize: 16,
    fontFamily: "Poppins-semibold",
  },
  titleIcon: {
    width: 20,
    height: 20,
  },
  difficultyBox: {
    flex: 1,
    padding: 10,
    borderRadius: 10,
    borderWidth: StyleSheet.hairlineWidth,
    justifyContent: "center",
    alignItems: "center",
  },
  difficultyText: { fontSize: 16, fontFamily: "Poppins-semibold" },
  inputWrapper: {
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 10,
    borderColor: Colors.cornflowerBlue,
    borderWidth: StyleSheet.hairlineWidth,
    marginTop: 5,
  },
  inputIcon: {
    width: 20,
    height: 20,
    marginHorizontal: 20,
    tintColor: Colors.cornflowerBlue,
  },
  input: { flex: 1, height: 60, color: Colors.cornflowerBlue, fontSize: 14 },
  submitButton: {
    backgroundColor: Colors.cornflowerBlue,
    marginTop: 10,
    width: "100%",
    alignSelf: "center",
    padding: 5,
  },
});
