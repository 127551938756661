import { StyleSheet, Image, ColorValue } from "react-native";
import { Text, View } from "react-native-picasso";
import React, { useState } from "react";
import { difficultyImages } from "./challenge-item.component";
import BouncyCheckbox from "react-native-bouncy-checkbox";
import { stringsEng } from "../../../../assets/text/strings-english";
import { ChallengeLevel } from "../../../types/challenge.types";

export type DifficultyFilterSetter = (
  difficulty: ChallengeLevel,
  checked: boolean
) => void;

type ChallengeDifficultyFilterProps = {
  color: ColorValue;
  difficulty: ChallengeLevel;
  defaultChecked: boolean;
  setFilter: DifficultyFilterSetter;
};
function ChallengeDifficultyFilter({
  color,
  defaultChecked,
  difficulty,
  setFilter,
}: ChallengeDifficultyFilterProps) {
  const [checked, setChecked] = useState<boolean>(defaultChecked);

  // Defining image component here so it can be passed to the checkbox's
  // textComponent field so that clicking on the image also toggles the
  // checkbox
  const imageComponent = (
    <Image
      source={difficultyImages[difficulty]}
      resizeMode="contain"
      style={styles.difficultyImg}
    />
  );

  const onCheckboxPress = () => {
    setFilter(difficulty, !checked);
    setChecked(!checked);
  };

  return (
    <View key={difficulty} style={styles.checkboxContainer}>
      <BouncyCheckbox
        useNativeDriver={false}
        disableBuiltInState
        isChecked={checked}
        onPress={onCheckboxPress}
        fillColor={color as string}
        textComponent={imageComponent}
      />
    </View>
  );
}

type ChallengeDifficultyFiltersProps = {
  color: ColorValue;
  setFilter: DifficultyFilterSetter;
};
export function ChallengeDifficultyFilters(
  props: ChallengeDifficultyFiltersProps
) {
  return (
    <>
      <Text style={{ fontFamily: "Poppins-semibold" }}>
        {stringsEng.COMMON.DIFFICULTY}
      </Text>
      <ChallengeDifficultyFilter
        {...props}
        difficulty={ChallengeLevel.Easy}
        defaultChecked
      />
      <ChallengeDifficultyFilter
        {...props}
        difficulty={ChallengeLevel.Medium}
        defaultChecked
      />
      <ChallengeDifficultyFilter
        {...props}
        difficulty={ChallengeLevel.Hard}
        defaultChecked
      />
    </>
  );
}

const styles = StyleSheet.create({
  difficultyImg: { width: 55, height: 55, margin: -15 },
  checkboxContainer: {
    flexDirection: "row",
    marginBottom: 5,

    // Setting overflow to hidden here prevents the overflowing parts of the
    // image from being pressable (and potentially stealing taps from other
    // nearby touchables)
    overflow: "hidden",
  },
});
