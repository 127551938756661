import { useFonts } from "expo-font";
import { StatusBar } from "expo-status-bar";
import React, { useEffect, useState } from "react";
import { ImageBackground, useWindowDimensions } from "react-native";
import { ThemeProvider, View } from "react-native-picasso";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { PageSize, PageSizeContext } from "./src/contexts/page-size-context";
import NavigationStack from "./src/pages/navigation.stack";
import { persistor, store } from "./src/redux/store";
import { Colors, theme } from "./theme";

export default function App() {
  const [loaded] = useFonts({
    CiutadellaRounded: require("./assets/fonts/CiutadellaRounded/CiutadellaRounded-Rg.ttf"),
    "CiutadellaRounded-bold": require("./assets/fonts/CiutadellaRounded/CiutadellaRounded-Rg.ttf"),
    DINRoundPro: require("./assets/fonts/DINRoundPro/DINRoundPro.ttf"),
    "DINRoundPro-medium": require("./assets/fonts/DINRoundPro/DINRoundPro-Medi.ttf"),
    "DINRoundPro-bold": require("./assets/fonts/DINRoundPro/DINRoundPro-Bold.ttf"),
    Poppins: require("./assets/fonts/Poppins/Poppins-Regular.ttf"),
    "Poppins-semibold": require("./assets/fonts/Poppins/Poppins-SemiBold.ttf"),
    "Poppins-bold": require("./assets/fonts/Poppins/Poppins-Bold.ttf"),
  });

  // Update values for page size context
  const { height, width } = useWindowDimensions();
  const [pageSize, setPageSize] = useState<PageSize>();

  // Clamp page size to a fixed width
  useEffect(() => {
    // Clamp the page width between 335 and 450
    const min = 335;
    const max = 450;
    const clampedWidth = Math.min(Math.max(width, min), max);
    setPageSize({ width: clampedWidth, height });
  }, [width, height]);

  //TODO: loading fonts. Show a loading indicator?
  if (!loaded) return null;

  return (
    //{/* Allows access to redux store */}
    <Provider store={store}>
      {/* (from redux-persist) delays rendering until redux state retrieved. Can specify a loading component  */}
      <PersistGate loading={null} persistor={persistor}>
        {/* Use theme from theme.ts */}
        <ThemeProvider theme={theme}>
          {/* Allows accessing actual (mobile-sized) page size  */}
          <PageSizeContext.Provider value={pageSize}>
            <ImageBackground
              resizeMode="cover"
              style={{
                width,
                height,
                backgroundColor: Colors.desktopBackground,
              }}
              source={require("./assets/images/desktop-shapes.png")}
            >
              {/* Container to make the app mobile-sized on desktop */}
              <View
                style={{
                  flex: 1,
                  width: pageSize.width,
                  height: pageSize.height,
                  overflow: "hidden",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <NavigationStack />
                <StatusBar
                  style="auto"
                  // For android
                  translucent={false}
                  backgroundColor="#fff"
                />
              </View>
            </ImageBackground>
          </PageSizeContext.Provider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}
