import React from "react";
import { Controller, useForm } from "react-hook-form";
import { View, Text } from "react-native-picasso";
import { stringsEng } from "../../../../assets/text/strings-english";
import CFTButton from "../../../components/Button/cft-button.component";
import { Colors } from "../../../../theme";
import CFTTextInput from "../../../components/TextInput/text-input.component";
import { styles } from "./join-class.styles";
import OnboardingService from "../onboarding.service";
import { errorStyle } from "../Common/error-styles";
import { ScrollView } from "react-native";
import resolver from "./join-resolver";

export type FormData = {
  classCode: string;
  validCode: boolean;
};

export default function JoinClassForm({ navigation }) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: { classCode: "", validCode: false },
    resolver
  });

  const submit = handleSubmit(async (data) => {
    //TODO: uncomment once backend is connected
    if (data !== null) {
      let classStatus = await OnboardingService.checkIfClassExists(
        control._formValues.classCode
      );
      if (classStatus) {
        control._formValues.validCode = true;
        navigation.navigate("CreateAccount", {
          classCode: control._formValues.classCode,
        });
      } else {
        control._formValues.validCode = false;
        control._formState.errors.validCode = {

          type: 'required',
          message: stringsEng.ONBOARDING.JOIN_CLASS.ERRORS.INVALID_CODE,

        }

      }
    }

  });

  return (
    <>
      <Controller
        name="classCode"
        control={control}
        rules={{ required: true }}
        render={({ field: { onBlur, onChange, value } }) => (
          <CFTTextInput
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            placeholderTextColor="white"
            autoCorrect={false}
            placeholder={stringsEng.ONBOARDING.JOIN_CLASS.CLASS_ID}
            iconSource={require("../../../../assets/images/icons/icon_join.png")}
            iconColor={Colors.white}
          />
        )}
      />

      <View>
        <CFTButton
          text={stringsEng.ONBOARDING.SUBMIT}
          buttonSize="lg"
          buttonStyle={styles.classSubmitButton}
          iconSource={require("../../../../assets/next-page-arrow.png")}
          textColor={Colors.iceBlue}
          onPress={submit}
        />
      </View>

      <View style={{ height: 20 }}>
        {errors.classCode && <Text style={errorStyle.error}>{errors.classCode.message}</Text>}
        {errors.validCode && <Text style={errorStyle.error}>{errors.validCode.message}</Text>}
      </View>

    </>
  );
}
