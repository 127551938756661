import React from "react";
import { View } from "react-native-picasso";
import { stringsEng } from "../../../../assets/text/strings-english";
import { CategoryColors, Colors } from "../../../../theme";
import CFTButton from "../../../components/Button/cft-button.component";
import { QuestionCategory } from "../../../types/quiz.types";
import { styles } from "./challenge-category-filters.styles";

export type CategoryFilter = QuestionCategory | "recommended";
export type CategoryFilterSetter = (selectedCategory: CategoryFilter) => void;

function ChallengeCategoryFilterButton({
  category,
  selected,
  onCategorySelected,
}: {
  category: CategoryFilter;
  selected: boolean;
  onCategorySelected: CategoryFilterSetter;
}) {
  const categoryColor = CategoryColors[category];

  return (
    <View style={styles.filterButtonContainer}>
      <CFTButton
        text={stringsEng.COMMON.CHALLENGE_CATEGORIES[category]}
        textColor={selected ? categoryColor : Colors.white}
        onPress={() => onCategorySelected(category)}
        buttonSize={28}
        buttonStyle={[
          styles.filterButton,
          { borderColor: categoryColor },
          selected
            ? { backgroundColor: Colors.white }
            : { backgroundColor: categoryColor },
        ]}
      />
    </View>
  );
}

const categories: CategoryFilter[] = [
  "recommended",
  ...Object.values(QuestionCategory),
];

export function ChallengeCategoryFilters({
  selectedCategory,
  onCategorySelected,
}: {
  selectedCategory: CategoryFilter;
  onCategorySelected: CategoryFilterSetter;
}) {
  return (
    <View
      style={{ flexDirection: "row", flexWrap: "wrap", marginVertical: 10 }}
    >
      {categories.map((category, i) => (
        <ChallengeCategoryFilterButton
          key={i}
          category={category}
          onCategorySelected={onCategorySelected}
          selected={category === selectedCategory}
        />
      ))}
    </View>
  );
}
