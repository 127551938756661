import { StyleSheet } from "react-native";
import { Colors } from "../../../../theme";

export const styles = StyleSheet.create({
  subtitle: {
    fontSize: 12,
    color: Colors.white,
    fontFamily: "Poppins",
    float: "left",
  },
  header: {
    color: Colors.white,
    fontSize: 16,
    fontFamily: "Poppins-semibold",
    float: "left",
  },
  classBox: {
    margin: 10,
    borderRadius: 15,
    padding: 15,
    height: "90%",
  },
  contextMenu: {
    transform: [{ rotate: "90deg" }],
    height: 25,
    width: 25,
    float: "right",
    marginLeft: "auto",
    flexGrow: 10,
  },
  assignmentsBox: {
    borderRadius: 12,
    padding: 12,
    shadowColor: Colors.darkGrey,
    shadowOpacity: 0.3,
    shadowRadius: 5,
    backgroundColor: Colors.white,
    marginTop: -18,
    zIndex: -1,
    width: "88%",
    alignSelf: "center",
    marginBottom: 0,
  },
  assignments: {
    fontSize: 12,
    color: Colors.mediumGrey,
    fontFamily: "Poppins",
  },
  nav: {
    backgroundColor: Colors.white,
    paddingTop: 0,
  },

  link: {
    width: 150,
    textAlign: "center",
    position: "relative",
    cursor: "pointer",
  },

  menu: {
    opacity: 0,
    position: "absolute",
    top: 25, // same as your nav height
    left: 0,
    backgroundColor: Colors.white,
    padding: 10,
    textAlign: "center",
    transition: "all 200ms ease",
    borderRadius: 15,
    shadowColor: Colors.darkGrey,
    shadowOpacity: 0.3,
    shadowRadius: 5,
  },

  menuopen: {
    opacity: 1,
  },

  item: {
    padding: 5,
  },
  list: {
    margin: 0,
    padding: 0,
    textAlign: "left",
  },
});
