import { StyleSheet } from "react-native";
import { Colors } from "../../../theme";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    marginVertical: 5,
  },
  bubble: {
    width: 40,
    height: 40,
    borderRadius: 50,
    marginHorizontal: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  numberText: { color: Colors.white, fontSize: 16 },
});
