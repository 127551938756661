import React from "react";
import { TouchableOpacity } from "react-native";
import { Text } from "react-native-picasso";
import { stringsEng } from "../../../../assets/text/strings-english";
import { styles } from "./quiz-results.styles";

export default function SkipToChallengesButton({ navigation }) {
  const navigateToChallenges = () => {
    navigation.navigate("DashboardMenuDrawer", {
      screen: "DashboardTabs",
      params: {
        screen: 'NewChallenge',
      }
    });
  };

  return (
    <TouchableOpacity
      onPress={() => navigateToChallenges()}
      style={styles.skipButton}
    >
      <Text className="color-accent1" style={styles.skipButtonText}>
        {stringsEng.QUIZ_RESULT.SKIP_TO_CHALLENGES}
      </Text>
    </TouchableOpacity>
  );
}
