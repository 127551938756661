import TextPage from "./Common/text-page.component"
import { stringsEng } from "../../../assets/text/strings-english";


export default function AboutUs({ navigation }) {
    return (
        <>
            <TextPage
                navigation={navigation}
                headerA={stringsEng.ABOUT_US.ABOUTUS}
                headerB={stringsEng.ABOUT_US.MISSION}
                bodyA={stringsEng.ABOUT_US.INFO_ABOUT}
                bodyB={stringsEng.ABOUT_US.INFO_MISSION}
            />
        </>
    );
}

