import { Resolver } from "react-hook-form";
import { FormData } from "./submit-code-form.component";
import { stringsEng } from "../../../../assets/text/strings-english";
import { validPassword, validCode } from "../Common/regex";

const resolver: Resolver<FormData> = async (values) => {
  return {
    values: values.newPassword
      ? values
      : values.confirmNewPassword
      ? values
      : values.code
      ? values
      : {},
    errors: !values.code
      ? {
          code: {
            type: "required",
            message: stringsEng.ONBOARDING.FORGOT_PASSWORD_CODE.ERRORS.CODE,
          },
        }
      : !validCode.test(values.code)
      ? {
          code: {
            type: "regex",
            message:
              stringsEng.ONBOARDING.FORGOT_PASSWORD_CODE.ERRORS.CODE_LENGTH,
          },
        }
      : !values.newPassword
      ? {
          newPassword: {
            type: "required",
            message: stringsEng.ONBOARDING.CREATE_ACCOUNT.ERRORS.PASSWORD,
          },
        }
      : !validPassword.test(values.newPassword)
      ? {
          newPassword: {
            type: "regex",
            message: stringsEng.ONBOARDING.FORGOT_PASSWORD_CODE.ERRORS.PASSWORD,
          },
        }
      : !values.confirmNewPassword
      ? {
          confirmNewPassword: {
            type: "required",
            message:
              stringsEng.ONBOARDING.CREATE_ACCOUNT.ERRORS.CONFIRM_PASSWORD,
          },
        }
      : values.confirmNewPassword !== values.newPassword
      ? {
          confirmNewPassword: {
            type: "match",
            message: stringsEng.ONBOARDING.CREATE_ACCOUNT.ERRORS.PASSWORD_MATCH,
          },
        }
      : {},
  };
};

export default resolver;
