import { QuizAnswers, QuizResults } from "../types/quiz.types";
import { api } from "./api";

export const sendQuizResults = async (
  userId: string,
  quizAnswers: QuizAnswers
): Promise<QuizResults> => {
  return api
    .post<QuizResults>(`/user/${userId}/quiz/calculate`, quizAnswers)
    .then((res) => res.data)
    .catch((e) => {
      console.log("Couldn't send quiz results", e);
      return null;
    });
};
