import { Colors } from "../../../../theme";
import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  filterButtonContainer: {
    flexBasis: "33%",
    alignItems: "stretch",
    paddingHorizontal: 10,
    paddingVertical: 3,
  },
  filterButton: {
    borderRadius: 20,
    borderWidth: 2,
    paddingVertical: 8,
    margin: 0,
    paddingHorizontal: 5,
  },
});
