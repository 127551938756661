import React from "react";
import { ColorValue } from "react-native";
import { View } from "react-native-picasso";
import { Equivalences } from "../../../../assets/text/equivalences";
import { stringsEng } from "../../../../assets/text/strings-english";
import { Colors } from "../../../../theme";
import ResultsBackground from "../Common/results-background.component";
import EquivalenceButton from "./equivalence-button.component";
import EquivalentExplanation from "./equivalent-explanation.component";
import { styles } from "./quiz-results-equivalents.styles";

//Type for data in equivalence explanation
export type EquivalentData = {
  bgColour: ColorValue;
  ratio: number;
  text1: String;
  text2: String;
};

export default function QuizResultsEquivalents({ navigation, route }) {
  let footprintObject = route.params?.quizResults?.footprint;
  if (!footprintObject && route.params?.footprint) {
    footprintObject = route.params?.footprint;
  }
  let footprint = footprintObject.total;

  //This allows you to change which equivalence is showing
  const [equivalenceIndex, changeEquivalenceIndex] = React.useState(0);
  const switchPhone = () => {
    changeEquivalenceIndex(0);
  };
  const switchCar = () => {
    changeEquivalenceIndex(1);
  };
  const switchTree = () => {
    changeEquivalenceIndex(2);
  };
  const switchWaste = () => {
    changeEquivalenceIndex(3);
  };

  //For navigation to the next results page
  //Change navigation destination
  function navigateToNextResult() {
    navigation.navigate("QuizPages", {screen: "QuizResultsBreakdown", params: { footprint: footprintObject }});
  }

  return (
    <ResultsBackground
      navigation={navigation}
      backgroundImage={require("../../../../assets/gradient-bg.png")}
      footprint={footprint}
      upArrow={false}
      sectionHeader={
        stringsEng.QUIZ_RESULT.QUIZ_RESULTS_1.SEE_YOUR +
        "\n" +
        stringsEng.QUIZ_RESULT.QUIZ_RESULTS_1.EQUIVALENT
      }
      downArrow={true}
      nextPageText={
        stringsEng.QUIZ_RESULT.QUIZ_RESULTS_1.SEE_YOUR_FOOTPRINT +
        "\n" +
        stringsEng.QUIZ_RESULT.QUIZ_RESULTS_1.BREAKDOWN
      }
      skipButton={true}
      navigateToNextResult={navigateToNextResult}
      navigateToPreviousResult={null}
    >
      <View style={{ flex: 1, justifyContent: "flex-end" }}>
        <View style={styles.buttonContainer}>
          <View style={styles.buttonRow}>
            <EquivalenceButton
              bgColour={Colors.red}
              imageSource={require("../../../../assets/smartphone.png")}
              changeEquivalence={switchPhone}
              isSelected={equivalenceIndex === 0}
            />
            <EquivalenceButton
              bgColour={Colors.red}
              imageSource={require("../../../../assets/bus.png")}
              changeEquivalence={switchCar}
              isSelected={equivalenceIndex === 1}
            />
          </View>
          <View style={styles.buttonRow}>
            <EquivalenceButton
              bgColour={"#bdcb4f"}
              imageSource={require("../../../../assets/tree-outlined.png")}
              changeEquivalence={switchTree}
              isSelected={equivalenceIndex === 2}
            />
            <EquivalenceButton
              bgColour={"rgb(189,203,79)"}
              imageSource={require("../../../../assets/trash-outlined.png")}
              changeEquivalence={switchWaste}
              isSelected={equivalenceIndex === 3}
            />
          </View>
        </View>
      </View>
      <View style={{ flex: 1 }}>
        <EquivalentExplanation
          {...{ ...Equivalences[equivalenceIndex], footprint }}
        />
      </View>
    </ResultsBackground>
  );
}
