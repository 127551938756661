import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  //results-background
  break: {
    width: 180,
    height: 10,
    resizeMode: "stretch",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 8,
    marginBottom: 40,
  },
  upArrow: {
    width: 40,
    height: 40,
    alignSelf: "center",
    marginBottom: 8,
    marginTop: 0,
  },
  sectionHeader: {
    lineHeight: 20,
  },
  skipContainer: {
    height: 50,
  },
  //Skip-button
  skipButton: {
    backgroundColor: "white",
    width: 130,
    alignItems: "center",
    borderTopRightRadius: 3,
    borderBottomRightRadius: 3,
  },
  skipButtonText: {
    textTransform: "uppercase",
    fontSize: 10,
    paddingVertical: 3,
  },
  //Next-result-button
  nextArrowContainer: {
    marginTop: 20,
    height: 70,
    alignSelf: "center",
  },
  textNextPage: {
    lineHeight: 12,
  },
  downArrow: {
    width: 50,
    height: 50,
    marginHorizontal: 12,
    marginVertical: 8,
    alignSelf: "center",
  },
});
