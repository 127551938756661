import {
  View,
  Text,
  Pressable,
  StyleProp,
  ViewStyle,
  StyleSheet,
  TextStyle,
} from "react-native";
import {
  createNavigatorFactory,
  DefaultNavigatorOptions,
  ParamListBase,
  CommonActions,
  TabActionHelpers,
  TabNavigationState,
  TabRouter,
  TabRouterOptions,
  useNavigationBuilder,
} from "@react-navigation/native";
import { FunctionComponent } from "react";

// Props accepted by the view
type TabNavigationConfig = {
  WrapperComponent: FunctionComponent;
  navigate: any;
  contentStyle?: StyleProp<ViewStyle>;
  tabBarStyle?: StyleProp<ViewStyle>;
  tabStyle?: StyleProp<ViewStyle>;
  tabSelectedStyle?: StyleProp<ViewStyle>;
  tabTextStyle?: StyleProp<TextStyle>;
  tabSelectedTextStyle?: StyleProp<TextStyle>;
};

// Supported screen options
type TabNavigationOptions = {
  title?: string;
};

// Map of event name and the type of data (in event.data)
//
// canPreventDefault: true adds the defaultPrevented property to the
// emitted events.
type TabNavigationEventMap = {
  tabPress: {
    data: { isAlreadyFocused: boolean };
    canPreventDefault: true;
  };
};

// The props accepted by the component is a combination of 3 things
type Props = DefaultNavigatorOptions<
  ParamListBase,
  TabNavigationState<ParamListBase>,
  TabNavigationOptions,
  TabNavigationEventMap
> &
  TabRouterOptions &
  TabNavigationConfig;

// This component is a custom navigator that renders tabs within a page, rather
// than just on the top/bottom using builtin navigators. It does this by
// accepting a WrapperComponent, so pass in whatever should contain the
// navigator and its pages (like a background component). Adapted from
// https://reactnavigation.org/docs/custom-navigators

function EmbeddedTabNavigator({
  initialRouteName,
  children,
  screenOptions,
  contentStyle,
  tabBarStyle,
  tabStyle,
  tabSelectedStyle,
  tabTextStyle,
  tabSelectedTextStyle,
  WrapperComponent,
  navigate
}: Props) {
  const { state, navigation, descriptors, NavigationContent } =
    useNavigationBuilder<
      TabNavigationState<ParamListBase>,
      TabRouterOptions,
      TabActionHelpers<ParamListBase>,
      TabNavigationOptions,
      TabNavigationEventMap
    >(TabRouter, {
      children,
      screenOptions,
      initialRouteName,
    });

  return (
    <NavigationContent>
      <WrapperComponent navigation={navigate}>
        <View style={[{ flexDirection: "row" }, tabBarStyle]}>
          {state.routes.map((route, i) => (
            <Pressable
              key={route.key}
              onPress={() => {
                const event = navigation.emit({
                  type: "tabPress",
                  target: route.key,
                  canPreventDefault: true,
                  data: {
                    isAlreadyFocused:
                      route.key === state.routes[state.index].key,
                  },
                });

                if (!event.defaultPrevented) {
                  navigation.dispatch({
                    ...CommonActions.navigate({
                      name: route.name,
                      merge: true,
                    }),
                    target: state.key,
                  });
                }
              }}
              style={[
                tabStyle,
                i === state.index ? tabSelectedStyle : undefined,
              ]}
            >
              <Text
                style={[
                  tabTextStyle,
                  i === state.index ? tabSelectedTextStyle : undefined,
                ]}
              >
                {descriptors[route.key].options.title || route.name}
              </Text>
            </Pressable>
          ))}
        </View>
        <View style={[{ flex: 1 }, contentStyle]}>
          {state.routes.map((route, i) => {
            return (
              <View
                key={route.key}
                style={[
                  StyleSheet.absoluteFill,
                  { display: i === state.index ? "flex" : "none" },
                ]}
              >
                {descriptors[route.key].render()}
              </View>
            );
          })}
        </View>
      </WrapperComponent>
    </NavigationContent>
  );
}

export const createEmbeddedTabNavigator = createNavigatorFactory<
  TabNavigationState<ParamListBase>,
  TabNavigationOptions,
  TabNavigationEventMap,
  typeof EmbeddedTabNavigator
>(EmbeddedTabNavigator);
