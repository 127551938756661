import { Resolver } from "react-hook-form";
import { CreateClassFormData } from "./createClassForm.component";
import { stringsEng } from "../../../../assets/text/strings-english";

const resolver: Resolver<CreateClassFormData> = async (values) => {
  return {
    values: values.className
      ? values
      : values.classCode
      ? values
      : values.classDescription
      ? values
      : values.classStartDate
      ? values
      : values.classEndDate
      ? values
      : values.schoolCode
      ? values
      : {},
    errors: !values.className
      ? {
          className: {
            type: "required",
            message: stringsEng.TEACHER.CREATE_CLASS.ERRORS.CLASSNAME,
          },
        }
      : !values.classCode
      ? {
          classCode: {
            type: "required",
            message: stringsEng.TEACHER.CREATE_CLASS.ERRORS.CLASSCODE,
          },
        }
      : values.classCode.length > 16
      ? {
          classCode: {
            type: "length",
            message: stringsEng.TEACHER.CREATE_CLASS.ERRORS.LONG_CODE,
          },
        }
      : !values.classDescription
      ? {
          classDescription: {
            type: "required",
            message: stringsEng.TEACHER.CREATE_CLASS.ERRORS.DESCRIPTION,
          },
        }
      : !values.classStartDate
      ? {
          classStartDate: {
            type: "required",
            message: stringsEng.TEACHER.CREATE_CLASS.ERRORS.START_DATE,
          },
        }
      : new Date(values.classStartDate).toString() === "Invalid Date"
      ? {
          classStartDate: {
            type: "invalid",
            message: stringsEng.TEACHER.CREATE_CLASS.ERRORS.INVALID_DATE,
          },
        }
        : new Date(values.classEndDate) <= new Date(values.classStartDate)
      ? {
        classEndDate: {
            type: "invalid",
            message: stringsEng.TEACHER.CREATE_CLASS.ERRORS.INVALID_END,
          },
        }
        : new Date(values.classEndDate).toString() === "Invalid Date"
      ? {
        classEndDate: {
            type: "invalid",
            message: stringsEng.TEACHER.CREATE_CLASS.ERRORS.INVALID_END,
          },
        }
      : !values.classEndDate
      ? {
          classEndDate: {
            type: "required",
            message: stringsEng.TEACHER.CREATE_CLASS.ERRORS.END_DATE,
          },
        }
      : !values.schoolCode
      ? {
          schoolCode: {
            type: "required",
            message: stringsEng.TEACHER.CREATE_CLASS.ERRORS.SCHOOL_CODE,
          },
        }
      : {},
  };
};

export default resolver;
