import React from "react";
import { View, Text } from "react-native-picasso";
import { useSelector } from "react-redux";
import { stringsEng } from "../../../../assets/text/strings-english";
import { Colors } from "../../../../theme";
import GradientBackground from "../../../components/Background/gradient-background.component";
import CFTButton from "../../../components/Button/cft-button.component";
import HeaderBanner from "../../../components/HeaderBanner/header-banner.component";
import { selectUser } from "../../../redux/userSlice";
import { styles } from "./editAssignment.styles";
import EditAssignmentForm from "./editAssignmentForm.component";

export default function EditAssignment({ navigation, route }) {
    const user = useSelector(selectUser);
    let assignments = user.teacherClassData.assignments;
    
    const assignmentId = route.params.params.assignmentId;
    const assignment = assignments.filter((a)=> a._id === assignmentId)[0]
    return (
        <>
            <GradientBackground />
            <HeaderBanner />
            <CFTButton
                iconSource={require("../../../../assets/images/icons/icon-arrow.png")}
                onPress={() => navigation.navigate("TeacherDashboard")}
                textColor={Colors.white}
                buttonStyle={styles.backButton}
            />
            <View style={styles.createClassMainFlexContainer}>

                <Text className="color-white align-center h3">
                    {stringsEng.TEACHER.HOME.EDIT_ASSIGNMENT}
                </Text>
                <EditAssignmentForm preExistingData={assignment}/>
            </View>
        </>
    );
}
