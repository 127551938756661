import { StyleSheet } from "react-native";
import { theme } from "../../../theme";

export const styles = StyleSheet.create({
  button: {
    flex: 0,
    flexGrow: 1,
    flexDirection: "row",
    borderRadius: 16,
    margin: 12,
    padding: 16,
    backgroundColor: "#574B60", //TODO: change button color
    justifyContent: "center",
    alignItems: "center",
  },
  buttonIconMd: {
    width: 20,
    height: 20,
    marginRight: 10,
  },
  buttonIconLg: {
    width: 30,
    height: 30,
    marginHorizontal: 12,
    marginVertical: 8,
  },
  buttonIconXl: {
    width: 45,
    height: 45,
    marginHorizontal: 12,
    marginVertical: 8,
  },
  buttonDisabled: {
    backgroundColor: "#574B60aa",
  },
  buttonText: {
    color: "white",
    fontSize: 18,
  },
  buttonTextLg: {
    fontSize: theme.font.sizes.xl,
  },
  buttonTextDisabled: {
    //TODO: color on disable
    color: "#ffffffaa",
  },
  pressableText: {
    textDecorationLine: "underline",
    textAlign: "center",
    paddingVertical: 20, // Increases size of clickable area
  },
});
