import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-evenly",
  },
  climactImage: {
    flex: 1,
    resizeMode: "contain",
    width: "100%",
  },
  textView: {
    marginTop: -20,
    padding: 20,
    flex: 1,
    alignItems: "center",
  },
  inputView: {
    display: "none",
    backgroundColor: "#FFFF",
    borderRadius: 10,
    width: "75%",
    height: 40,
    marginBottom: 20,
    alignSelf: "center",
    alignItems: "center",
  },
  inputContainer: {
    flex: 0.5,
    margin: 20,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "baseline",
  },
  TextInput: {
    width: "100%",
    flex: 1,
    textAlign: "center",
  },
  button: {
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    backgroundColor: "lightgreen",
    paddingHorizontal: 50,
    paddingVertical: 20,
    borderWidth: 1,
    borderColor: "white",
    borderRadius: 50,
    marginBottom: 20,
  },
});
