import { ColorValue, GestureResponderEvent, Text, View } from "react-native";
import React from "react";
import { Colors } from "../../../theme";
import { styles } from "./progress-numbers.styles";
import { TouchableOpacity } from "react-native-picasso";

// Shows a series of numbers in circles. The numbers are highlighted to show
// progress.
type Props = {
  numberOfValues: number; // e.g. if this is 3, it shows numbers 1, 2, and 3
  numberOfHighlighted: number; // e.g. if this is 2, numbers 1 and 2 are highlighted
  highlightColor: ColorValue;
  onClick: (event: GestureResponderEvent)=> void;
};
export default function ProgressNumbers({
  numberOfValues,
  numberOfHighlighted,
  highlightColor,
  onClick
}: Props) {
  // Create an array of numbers 1..numberOfValues
  const numbersArray: number[] = Array.from({ length: numberOfValues }).map(
    (_, i) => i + 1
  );
  return (
    <View style={styles.container}>
      {numbersArray.map((value, i) => (
        <TouchableOpacity
          onPress={onClick}
          key={i}
          style={[
            styles.bubble,
            {
              backgroundColor:
                i < numberOfHighlighted ? highlightColor : Colors.mediumGrey,
            },
          ]}
          disabled={i < numberOfHighlighted}
        >
          <Text style={styles.numberText}>{value}</Text>
        </TouchableOpacity >
      ))}
    </View>
  );
}
