import { View, TouchableOpacity } from "react-native-picasso";
import { Image, ImageSourcePropType, Text } from "react-native";
import React from "react";
import { Colors } from "../../../../theme";
import { styles } from "./teacher-tabbar.styles";
import { stringsEng } from "../../../../assets/text/dist/strings-english";

type TabData = {
    navigationPageName: string;
    icon: ImageSourcePropType;
    text: String;
};
const tabBarData: TabData[] = [
    {
        navigationPageName: "ClassDetails",
        icon: require("../../../../assets/images/icons/classroom.png"),
        text: stringsEng.TEACHER.TABBAR.CLASSROOM
    },
    {
        navigationPageName: "AssignmentDetails",
        icon: require("../../../../assets/images/icons/icon-challenge.png"),
        text: stringsEng.TEACHER.TABBAR.ASSIGNMENTS
    },
    {
        navigationPageName: "StudentDetails",
        icon: require("../../../../assets/images/icons/students.png"),
        text: stringsEng.TEACHER.TABBAR.STUDENTS
    },
];

type TabProps = {
    focused: boolean;
    navigateTo: (dest: string) => void;
} & TabData;

function Tab({ focused, navigateTo, navigationPageName, icon, text }: TabProps) {
    return (
        <View style={styles.tab}>
            <TouchableOpacity onPress={() => navigateTo(navigationPageName)}>
                <Image
                    source={icon}
                    resizeMode="contain"
                    style={[
                        styles.tabImage,
                        {
                            tintColor: focused ? Colors.deepSkyBlue : Colors.mediumGrey,
                        },
                    ]}
                />
                <Text style={[styles.iconText, {
                    color: focused ? Colors.deepSkyBlue : Colors.mediumGrey,
                },]}>{text}</Text>
            </TouchableOpacity>
        </View>
    );
}

export default function TeacherTabBar({ navigation, state }) {
    const navigateTo = (pageName: string) => {
        navigation.navigate(pageName);
    };

    return (
        <View style={styles.container}>
            <Tab
                focused={state.index === 0}
                navigateTo={navigateTo}
                {...tabBarData[0]}

            />

            <Tab
                focused={state.index === 1}
                navigateTo={navigateTo}
                {...tabBarData[1]}
            />

            <Tab
                focused={state.index === 2}
                navigateTo={navigateTo}
                {...tabBarData[2]}
            />
        </View>
    );
}
