import React from "react";
import { useWindowDimensions, ImageBackground } from "react-native";
import { View, Text } from "react-native-picasso";
import HeaderBanner from "../../components/HeaderBanner/header-banner.component";
import { stringsEng } from "../../../assets/text/strings-english";
import { styles } from "./quiz-welcome.styles";
import CFTButton from "../../components/Button/cft-button.component";
import { Colors } from "../../../theme";
import GradientBackground from "../../components/Background/gradient-background.component";

export default function QuizWelcome({ navigation }) {
  //For navigation to the quiz
  const navigateToQuiz = () => {
    navigation.navigate("QuizPages", { screen: "Quiz" });
  };

  //This part makes it so that the speech bubble lines up with the polar bear
  const { height, width } = useWindowDimensions();
  let distFromBottom = 0;
  if (width <= 450) {
    //If the full height of the image is shown...
    // ratio1 = (distance from bottom of image to bottom of speech bubble) / (height of image), measured in px
    let ratio1 = 750 / 2346;
    // distance from bottom = ratio1 * (height of screen)
    distFromBottom = ratio1 * height;
  } else {
    //If the full width of the image is shown...
    // ratio2 = (vertical distance from middle of image to bottom of speech bubble) / (width of image), measured in px
    let ratio2 = 410 / 1438;
    // distance from bottom = (half of screen height) - (ratio2 * (width of screen))
    distFromBottom = height / 2 - ratio2 * 450;
  }

  return (
    <>
      <GradientBackground />
      <ImageBackground
        source={require("../../../assets/quiz-welcome-polarbear.png")}
        style={styles.bgImage}
      />
      <ImageBackground
        source={require("../../../assets/background-shapes.png")}
        style={styles.bgImage}
      />
      <HeaderBanner />
      <View
        style={[
          styles.speechContainer,
          {
            bottom: distFromBottom,
          },
        ]}
      >
        <ImageBackground
          source={require("../../../assets/speech-bubble.png")}
          resizeMode="stretch"
        >
          <Text
            style={styles.speechTextLg}
            className="h3 color-white align-center bold"
          >
            {stringsEng.QUIZ_WELCOME.MEET_BEAR_LINE1} {"\n"}
            {stringsEng.QUIZ_WELCOME.MEET_BEAR_LINE2}
          </Text>
          <Text
            style={styles.speechTextSm}
            className="color-white align-center fontSize-xs"
          >
            {stringsEng.QUIZ_WELCOME.INTRODUCTION}
          </Text>

          <CFTButton
            text={stringsEng.QUIZ_WELCOME.START_QUIZ_BUTTON}
            textColor={Colors.cornflowerBlue}
            buttonStyle={styles.startButton}
            onPress={navigateToQuiz}
            iconSource={require("../../../assets/next-page-arrow.png")}
          />
        </ImageBackground>
      </View>
    </>
  );
}
