import { Text, View, Image, TouchableOpacity } from "react-native";
import React from "react";
import { useNavigation } from "@react-navigation/native";
import { Colors } from "../../../../theme";
import { stringsEng } from "../../../../assets/text/strings-english";
import { CountdownTimer } from "../../../components/CountdownTimer/countdown-timer.component";
import { AssignmentType } from "../../../types/assignment.types";
import { PercentProgressCircle } from "../../../components/PercentProgressCircle/percent-progress-circle.component";
import { styles } from "./assignment-item.styles";
import { format } from "date-fns";

function PointsCircle({ assignment }: { assignment: AssignmentType }) {
  const { progress, pointsGoal } = assignment;
  return (
    <PercentProgressCircle
      currentValue={progress}
      targetValue={pointsGoal}
      unit={stringsEng.ASSIGNMENT.ASSIGNMENT_ITEM.POINTS_UNIT}
      size={70}
      width={8}
      rotation={0} // start angle
      tintColor={Colors.purple}
      backgroundColor={Colors.lightGrey}
      lineCap="round"
    />
  );
}

type Props = { assignment: AssignmentType, userId: string, classId: string};
export default function AssignmentItem({ assignment, userId, classId }: Props) {
  assignment.updatedAt = new Date(assignment.updatedAt);
  const navigation = useNavigation() as any;

  const onPress = () => {

    navigation.navigate("AssignmentDetails", { assignment, userId, classId });
  };

  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <View style={styles.contentContainer}>
        <PointsCircle assignment={assignment} />

        <View style={styles.textContainer}>
          <Text style={styles.titleText}>{assignment.assignmentName}</Text>
          {assignment.status === "completed" ? (
            // View when assignment is completed shows "completed on" date
            <Text style={styles.subText}>
              {stringsEng.ASSIGNMENT.ASSIGNMENT_ITEM.COMPLETED_ON}{" "}
              {/* TODO: i18n for date; displays something like "August 2" */}
              {format(assignment.updatedAt, "MMMM d")}
            </Text>
          ) : (
            // View when assignment is not completed: shows time remaining
            <>
              <Text style={styles.subText}>
                {stringsEng.ASSIGNMENT.ASSIGNMENT_ITEM.TIME_REMAINING}
              </Text>
              <CountdownTimer deadline={assignment.dueDate} />
            </>
          )}
        </View>
      </View>

      <Image
        style={styles.image}
        resizeMode="contain"
        source={require("../../../../assets/images/assignment.png")}
      />
    </TouchableOpacity>
  );
}
