import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";
import { Platform, StyleSheet } from "react-native";
import { useSelector } from "react-redux";
import { Colors } from "../../theme";
import { createEmbeddedTabNavigator } from "../components/EmbeddedTabNavigator/embedded-tab-navigator.component";
import MenuDrawer from "../components/MenuDrawer/menu-drawer.component";
import { selectUser } from "../redux/userSlice";
import AssignmentDetails from "./Assignments/AssignmentDetails/assignment-details.component";
import Assignments from "./Assignments/assignments.page";
import ChallengeDetails from "./Challenges/ChallengeDetails/challenge-details.component";
import ChallengesBackground from "./Challenges/Common/challenges-background.component";
import ChallengesTabBar from "./Challenges/Common/challenges-tabbar.component";
import MyChallenges from "./Challenges/MyChallenges/my-challenges.page";
import NewChallenge from "./Challenges/NewChallenge/new-challenge.page";
import ChallengesDashboard from "./Dashboard/challenges-dashboard.page";
import HomeScreen from "./Home/home-screen.page";
import CreateAccount from "./Onboarding/CreateAccount/create-account.page";
import ForgotPassword from "./Onboarding/ForgotPassword/forgot-password.page";
import JoinClass from "./Onboarding/JoinClass/join-class.page";
import { Login } from "./Onboarding/Login/login.page";
import LoadingQuizResults from "./Quiz/loading-quiz-results.page";
import Quiz from "./Quiz/quiz.page";
import QuizResultsAverages from "./QuizResults/QuizResultsAverages/quiz-results-averages.page";
import QuizResultsBreakdown from "./QuizResults/QuizResultsBreakdown/quiz-results-breakdown.page";
import QuizResultsEquivalents from "./QuizResults/QuizResultsEquivalents/quiz-results-equivalents.page";
import AboutUs from "./AboutUs&Terms/about-us.page";
import Terms from "./AboutUs&Terms/terms-conditions.page";
import ContactUs from "./Contact/contact-us.page";
import ProfilePage from "./Profile/profile.page";
import Footprint from "./QuizResults/Footprint/footprint.page";
import { useNavigation } from "@react-navigation/native";
import SubmitCode from "./Onboarding/ForgotPassword/submit-code.page";
import JoinSchool from "./Onboarding/JoinSchool/join-school.page";
import CreateClass from "./Teacher/CreateClass/createClass.page";
import CreateAssignment from "./Teacher/CreateAssignment/createAssignment.page";
import TeacherDashboard from "./Teacher/Dashboard/dashboard";
import TeacherHomeScreen from "./Teacher/home/teacherhome.page";
import TeacherMenuDrawer from "../components/TeacherMenuDrawer/teacher-drawer.component";
import ClassDetails from "./Teacher/ClassDetails/class-details.component";
import TeacherTabBar from "./Teacher/Common/teacher-tabbar.component";
import ClassAssignmentList from "./Teacher/AssignmentList/assignment-list.page";
import ClassStudentList from "./Teacher/StudentList/student-list.page";
import EditAssignment from "./Teacher/EditAssignment/editAssignment.page";
import QuizWelcome from "./QuizWelcome/quiz-welcome.page";

const RootStackNav = createNativeStackNavigator();
const DashboardBottomTabNav = createBottomTabNavigator();
const MenuDrawerNav = createDrawerNavigator(); // Hamburger menu w/ settings
const ChallengesAssignmentsTabNav = createEmbeddedTabNavigator();

const ChallengesAndAssignments = () => (
  <ChallengesAssignmentsTabNav.Navigator
    WrapperComponent={ChallengesBackground}
    navigate={useNavigation()}
    tabBarStyle={styles.challengesAssignmentsTabBar}
    tabStyle={styles.challengesAssignmentsTab}
    tabSelectedStyle={styles.challengesAssignmentsSelectedTab}
    tabTextStyle={styles.challengesAssignmentsText}
    tabSelectedTextStyle={styles.challengesAssignmentsSelectedText}
  >
    <ChallengesAssignmentsTabNav.Screen
      name="Challenges"
      component={MyChallenges}
      options={{ title: "My Challenges" }}
    />
    <ChallengesAssignmentsTabNav.Screen
      name="Assignments"
      component={Assignments}
    />
  </ChallengesAssignmentsTabNav.Navigator>
);

//To differentiate between screens that have the bottom nav bar displayed and those that do not need it (but still must be acccessible)
const DashboardTabsContainer = () => (
  <MenuDrawerNav.Navigator
    initialRouteName="DashboardMenuDrawer"
    screenOptions={{ headerShown: false }}
    backBehavior="history"
  >
    <DashboardBottomTabNav.Screen
      name="DashboardTabs"
      component={DashboardTabs}
    />
    <MenuDrawerNav.Screen
      name="DashboardMenuDrawer"
      component={DashboardMenuDrawer}
    />
    <MenuDrawerNav.Screen name="QuizPages" component={QuizPages} />
  </MenuDrawerNav.Navigator>
);

const TeacherTabsContainer = () => (
  <RootStackNav.Navigator
    initialRouteName="TeacherDashboard"
    screenOptions={{ headerShown: false }}
  >

    <RootStackNav.Screen
      name="TeacherTabs"
      component={TeacherTabs}
    />
    <RootStackNav.Screen
      name="TeacherDashboard"
      component={TeacherDashboard}
    />
    <RootStackNav.Screen
      name="TeacherHomeScreen"
      component={TeacherHomeScreen}
    />
    <RootStackNav.Screen
      name="CreateClass"
      component={CreateClass}
    />
     <RootStackNav.Screen
      name="EditAssignment"
      component={EditAssignment}
    />
    <RootStackNav.Screen
      name="CreateAssignment"
      component={CreateAssignment}
    />
    <RootStackNav.Screen
      name="TeacherDashboardBottomTabs"
      component={TeacherDashboardBottomTabs}
    />
  </RootStackNav.Navigator>
);

{
  /* Quiz screens */
}
const QuizPages = () => (
  <RootStackNav.Navigator screenOptions={{ headerShown: false }}>
    <RootStackNav.Screen name="QuizWelcome" component={QuizWelcome} />
    <RootStackNav.Screen name="Quiz" component={Quiz} />
    <RootStackNav.Screen
      name="LoadingQuizResults"
      component={LoadingQuizResults}
    />
    <RootStackNav.Screen
      name="QuizResultsEquivalents"
      component={QuizResultsEquivalents}
    />
    <RootStackNav.Screen
      name="QuizResultsBreakdown"
      component={QuizResultsBreakdown}
    />
    <RootStackNav.Screen
      name="QuizResultsAverages"
      component={QuizResultsAverages}
    />
  </RootStackNav.Navigator>
);

// Render the page tabs on the bottom (dashboard, challenges, etc) along with
// any pages thatthe tabs should be displayed over
const DashboardTabs = () => (
  <DashboardBottomTabNav.Navigator
    initialRouteName="DashboardMenuDrawer"
    screenOptions={{ headerShown: false }}
    tabBar={(props) => <ChallengesTabBar {...props} />}
    backBehavior="history"
  >
    {/* These screens are seen by ChallengesTabBar based on their position in
     the list. Since it only considers 5 screens, any more screens are ignored 
     by the tab bar */}
    <DashboardBottomTabNav.Screen
      name="ChallengesDashboard"
      component={ChallengesDashboard}
    />
    <DashboardBottomTabNav.Screen
      name="ChallengesAndAssignments"
      component={ChallengesAndAssignments}
    />
    <DashboardBottomTabNav.Screen
      name="NewChallenge"
      component={NewChallenge}
    />

    {/* TODO: temporary, remove when page implemented
    <DashboardBottomTabNav.Screen
      name="NewChallenge1"
      component={NewChallenge}
    /> */}

    <DashboardBottomTabNav.Screen name="Footprint" component={Footprint} />

    {/* TODO: temporary, remove when page implemented */}
    <DashboardBottomTabNav.Screen name="ProfilePage" component={ProfilePage} />

    {/* These screens are defined here so that the other challenges pages
      have access to them, and so that the tab bar is still displayed when 
      these pages are rendered */}
    <DashboardBottomTabNav.Screen
      name="ChallengeDetails"
      component={ChallengeDetails}
    />

    <DashboardBottomTabNav.Screen
      name="AssignmentDetails"
      component={AssignmentDetails}
    />
  </DashboardBottomTabNav.Navigator>
);

const TeacherDashboardBottomTabs = () => (
  <DashboardBottomTabNav.Navigator
    initialRouteName="ClassDetails"
    screenOptions={{ headerShown: false }}
    tabBar={(props) => <TeacherTabBar {...props} />}
  >
    {/* These screens are seen by ChallengesTabBar based on their position in
     the list. Since it only considers 5 screens, any more screens are ignored 
     by the tab bar */}
    <DashboardBottomTabNav.Screen
      name="ClassDetails"
      component={ClassDetails}
    />
    <DashboardBottomTabNav.Screen
      name="AssignmentDetails"
      component={ClassAssignmentList}
    />
    <DashboardBottomTabNav.Screen
      name="StudentDetails"
      component={ClassStudentList}
    />
  </DashboardBottomTabNav.Navigator>
);

// This renders the bottom tabs which renders all of the dashboard screens,
// because if it didn't, the drawer would appear behind these elements.
// https://reactnavigation.org/docs/drawer-navigator#nesting-drawer-navigators-inside-others
const DashboardMenuDrawer = () => (
  <MenuDrawerNav.Navigator
    initialRouteName="DashboardTabsContainer"
    drawerContent={(props) => <MenuDrawer {...props} />}
    screenOptions={{
      headerShown: false,
      drawerPosition: "right",
      // TODO: find a way to not have all these styles in here
      drawerStyle: { backgroundColor: "transparent" },
      drawerActiveTintColor: Colors.white,
      drawerInactiveTintColor: Colors.white,
      drawerActiveBackgroundColor: Colors.purple,
    }}
    // useLegacyImplementation is necessary on the web for drawerPosition = "right"... for some reason
    useLegacyImplementation={Platform.select({ web: true, default: false })}
    
  >
    {/* Standard screen (not in a drawer) */}
    <MenuDrawerNav.Screen name="Home" component={DashboardTabs} />
    {/*edit Profile*/}
    <MenuDrawerNav.Screen name="Terms and Conditions" component={Terms} />
    {/*user guide*/}
    <MenuDrawerNav.Screen name="About Us" component={AboutUs} />
    <MenuDrawerNav.Screen name="Contact Us" component={ContactUs} />
  </MenuDrawerNav.Navigator>
);

const TeacherTabs = () => (
  <MenuDrawerNav.Navigator
    initialRouteName="TeacherTabsContainer"
    drawerContent={(props) => <TeacherMenuDrawer {...props} />}
    screenOptions={{
      headerShown: false,
      drawerPosition: "right",
      // TODO: find a way to not have all these styles in here
      drawerStyle: { backgroundColor: "transparent" },
      drawerActiveTintColor: Colors.white,
      drawerInactiveTintColor: Colors.white,
      drawerActiveBackgroundColor: Colors.purple,
    }}
    // useLegacyImplementation is necessary on the web for drawerPosition = "right"... for some reason
    useLegacyImplementation={Platform.select({ web: true, default: false })}
  >
    {/* Standard screen (not in a drawer) */}
    <MenuDrawerNav.Screen name="TeacherTabsContainer" component={TeacherTabsContainer} />
    <MenuDrawerNav.Screen name="Contact Us" component={ContactUs} />
  </MenuDrawerNav.Navigator>
);

const NavigationStack = () => {
  const user = useSelector(selectUser);
  const loggedIn = user.token !== "";
  const isQuizAttempted = user?.userData?.quizAttempted;
  const isTeacher = user.userType === "teacher";

  return (
    <NavigationContainer>
      <RootStackNav.Navigator screenOptions={{ headerShown: false }} backBehavior="history">
        {loggedIn && isQuizAttempted && !isTeacher ? (
          //  Screens for logged in users
          <RootStackNav.Screen
            name="DashboardMenuDrawer"
            component={DashboardTabsContainer}
          />
        ) : loggedIn && !isQuizAttempted && !isTeacher ? (
          <RootStackNav.Group screenOptions={{ headerShown: false }}>
            <RootStackNav.Screen name="QuizPages" component={QuizPages} />
            <RootStackNav.Screen
              name="DashboardMenuDrawer"
              component={DashboardTabsContainer}
            />
          </RootStackNav.Group>
        ) : !isTeacher ? (
          //  Auth screens (user not logged in)
          <RootStackNav.Group screenOptions={{ headerShown: false }}>
            <RootStackNav.Screen name="Home" component={HomeScreen} />
            <RootStackNav.Screen name="Login" component={Login} />
            <RootStackNav.Screen
              name="ForgotPassword"
              component={ForgotPassword}
            />
            <RootStackNav.Screen name="SubmitCode" component={SubmitCode} />
            <RootStackNav.Screen name="JoinClass" component={JoinClass} />
            <RootStackNav.Screen name="JoinSchool" component={JoinSchool} />
            <RootStackNav.Screen
              name="CreateAccount"
              component={CreateAccount}
            />
          </RootStackNav.Group>
        ) : (
          <RootStackNav.Group screenOptions={{ headerShown: false }}>
            <RootStackNav.Screen name="TeacherTabs" component={TeacherTabs} />

          </RootStackNav.Group>
        )}
      </RootStackNav.Navigator>
    </NavigationContainer>
  );
};

export default NavigationStack;

const styles = StyleSheet.create({
  challengesAssignmentsTabBar: {
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 20,
  },
  challengesAssignmentsTab: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#319fd2",
    borderColor: Colors.white,
    borderWidth: StyleSheet.hairlineWidth,
    borderRadius: 16,
    paddingHorizontal: 15,
    paddingVertical: 10,
    marginHorizontal: 15,
  },
  challengesAssignmentsSelectedTab: { backgroundColor: Colors.white },
  challengesAssignmentsText: { fontSize: 18, color: Colors.white },
  challengesAssignmentsSelectedText: { color: "#319fd2" },
});
