import { StyleSheet } from "react-native";
import { Colors } from "../../../../theme";

export const styles = StyleSheet.create({
  buttonContainer: {
    height: 55,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: -20,
    marginBottom: 20
  },

  header: {
    color: Colors.skyBlue,
},
numbers: {
    fontSize: 34,
    paddingTop: 10,
},
units: {
    color: Colors.icePurple,

},
iconLeaf: {
    width: 25,
    height: 25,
    alignSelf: "center",
    marginTop: 10,
    tintColor: Colors.icePurple,
    marginBottom: 10,

},
iconPaw: {
    width: 40,
    height: 40,
    alignSelf: "center",
    tintColor: Colors.icePurple,
    marginBottom: 10,
},
iconGraph: {
    width: 30,
    height: 22,
    alignSelf: "center",
    marginTop: 5,
    marginBottom: 10,
},


headerBox: {
    backgroundColor: Colors.white,
    borderRadius: 10,
    width: "85%",

},
headerStats: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-evenly",
},
headerContainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

}
});
