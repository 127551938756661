import MultiSlider from "@ptomasroos/react-native-multi-slider";
import React, { useState } from "react";
import { ColorValue, Text, View } from "react-native";
import { Colors } from "../../../../theme";

type ChallengeFilterSliderProps = {
  headerText: string;
  color: ColorValue;
  minSliderValue?: number;
  maxSliderValue: number;
  setFilter: SliderFilterSetter;
};

export type SliderFilterSetter = (minVal: number, maxVal: number) => void;

// This filter is a slider bar with two handles allowing the user to set a
// range
export default function ChallengeFilterSlider({
  headerText,
  color,
  minSliderValue = 0,
  maxSliderValue,
  setFilter,
}: ChallengeFilterSliderProps) {
  // Initialize values to the passed min and max
  const [sliderValues] = useState<[number, number]>([
    minSliderValue,
    maxSliderValue,
  ]);
  return (
    <View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginLeft: 10, // Move the 0 right a bit
        }}
      >
        <Text>{minSliderValue}</Text>
        <Text style={{ textAlign: "center", fontFamily: "Poppins-semibold" }}>
          {headerText}
        </Text>
        <Text>{maxSliderValue}+</Text>
      </View>
      <View style={{ marginHorizontal: 15, marginVertical: -10 }}>
        <MultiSlider
          onValuesChangeFinish={([minVal, maxVal]) => setFilter(minVal, maxVal)}
          allowOverlap={false}
          values={sliderValues}
          sliderLength={200}
          min={minSliderValue}
          max={maxSliderValue}
          markerOffsetY={4}
          markerStyle={{
            backgroundColor: color,
            width: 25,
            height: 25,
            borderRadius: 50,
            borderWidth: 0,
          }}
          trackStyle={{ backgroundColor: Colors.lightGrey, height: 8 }}
          selectedStyle={{ backgroundColor: color }}
        />
      </View>
    </View>
  );
}
