import { Resolver } from "react-hook-form";
import { stringsEng } from "../../../../assets/text/strings-english";
import { FormData } from "./forgot-password-form.component";
import { validEmail } from "../Common/regex";

const resolver: Resolver<FormData> = async (values) => {
  return {
    values: values.email ? values : {},
    errors: !values.email
      ? {
          email: {
            type: "required",
            message: stringsEng.ONBOARDING.CREATE_ACCOUNT.ERRORS.EMAIL,
          },
        }
      : !validEmail.test(values.email)
      ? {
          email: {
            type: "regex",
            message: stringsEng.ONBOARDING.FORGOT_PASSWORD.ERRORS.EMAIL,
          },
        }
      : {},
  };
};

export default resolver;
