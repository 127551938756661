import { StyleSheet } from "react-native";
import { Colors } from "../../../../theme";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    margin: 50,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  newButton: {
    position: 'absolute',
    bottom:0,
    right:0,
    marginHorizontal: 20,
    marginVertical: 10,
    shadowColor: Colors.darkGrey,
    shadowRadius: 1,
    shadowOpacity: 0.4,
    shadowOffset: {
      width: -1.5,
      height: 1.5,
    },
    height: 50,
    width: 50
  },
  backButton: {
    alignSelf: "flex-start",
    position: "absolute",
    margin: 0,
    marginBottom: 10,
    padding: 15,
    backgroundColor: "transparent",
  },
});
