import React, { useState } from "react";
import { ImageBackground } from "react-native";
import { View, Text } from "react-native-picasso";
import { stringsEng } from "../../../../assets/text/strings-english";
import { Colors } from "../../../../theme";
import CFTButton from "../../../components/Button/cft-button.component";
import ResultsBackground from "../Common/results-background.component";
import AverageBar from "./average-bar.component";
import { selectUser } from "../../../redux/userSlice";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../redux/store";
import Overlay from "../../../components/LoadingOverlay/overlay.component";
import { getUserData } from "../../../services/auth";
import { updateUserData } from "../../../redux/userSlice";

export default function QuizResultsAverages({ navigation, route }) {
  const [loading, setLoading] = useState(false);
  const user = useSelector(selectUser);
  let footprint = route?.params?.footprint;
  const userData = useSelector(selectUser).userData;
  if (!footprint && userData) {
    footprint = userData.footprint;
  }
  const dispatch = useAppDispatch();
  async function navigateToChallenges() {
    setLoading(true);
    const data = await getUserData(user.id);
    dispatch(updateUserData(data.data.userData));
    setTimeout(() => {
      setLoading(false);
      navigation.navigate("DashboardMenuDrawer", {
        screen: "DashboardMenuDrawer",
      });
    }, 500);
  }

  const maxFootprint = Math.max(footprint.total, stringsEng.QUIZ_RESULT.QUIZ_RESULTS_AVERAGES.DATA.WORLD_AVG, stringsEng.QUIZ_RESULT.QUIZ_RESULTS_AVERAGES.DATA.CANADA_AVG)

  function navigateToPreviousResult() {
    navigation.navigate("QuizPages", {screen: "QuizResultsBreakdown", params: { footprint: footprint }});
  }

  if (loading) return <Overlay display={true} />;
  return (
    <>
      <ImageBackground
        source={require("../../../../assets/quiz-welcome-polarbear.png")}
        style={{ position: "absolute", width: "100%", height: "100%" }}
      />
      <ResultsBackground
        navigation={navigation}
        backgroundImage={require("../../../../assets/gradient-bg.png")}
        footprint={footprint.total}
        sectionHeader={stringsEng.QUIZ_RESULT.QUIZ_RESULTS_AVERAGES.HEADER1}
        upArrow={true}
        downArrow={false}
        skipButton={false}
        navigateToNextResult={() => {}} //TODO: lazy workaround
        navigateToPreviousResult={navigateToPreviousResult}
      >
        <Text className="color-white align-center" style={{ marginTop: -20 }}>
          {stringsEng.QUIZ_RESULT.QUIZ_RESULTS_AVERAGES.HEADER2}
        </Text>
        <View
          style={{ flex: 1, justifyContent: "space-between", marginBottom: 40 }}
        >
          <View style={{ flex: 1, marginVertical: 50 }}>
            <AverageBar
              bgColor={Colors.orange}
              tonnesValue={footprint.total}
              averageText={
                stringsEng.QUIZ_RESULT.QUIZ_RESULTS_AVERAGES.YOUR_AVG
              }
              maxFootprint={maxFootprint}
            />
            <AverageBar
              bgColor={Colors.mossGreen}
              tonnesValue={
                stringsEng.QUIZ_RESULT.QUIZ_RESULTS_AVERAGES.DATA.WORLD_AVG
              }
              averageText={
                stringsEng.QUIZ_RESULT.QUIZ_RESULTS_AVERAGES.WORLD_AVG
              }
              maxFootprint={maxFootprint}
            />
            <AverageBar
              bgColor={Colors.red}
              tonnesValue={
                stringsEng.QUIZ_RESULT.QUIZ_RESULTS_AVERAGES.DATA.CANADA_AVG
              }
              averageText={
                stringsEng.QUIZ_RESULT.QUIZ_RESULTS_AVERAGES.CANADA_AVG
              }
              maxFootprint={maxFootprint}
            />
          </View>
          <View>
            <CFTButton
              text={
                stringsEng.QUIZ_RESULT.QUIZ_RESULTS_AVERAGES.START_BUTTON_TEXT
              }
              onPress={navigateToChallenges}
              buttonStyle={{ backgroundColor: Colors.turquoise }}
            />
          </View>
        </View>
      </ResultsBackground>
    </>
  );
}
