import React, { useEffect, useState } from "react";
import { ActivityIndicator, FlatList } from "react-native";
import { Text, View } from "react-native-picasso";
import { stringsEng } from "../../../../assets/text/strings-english";
import { CategoryColors, Colors } from "../../../../theme";
import { getAllChallenges } from "../../../services/challenge";
import { Challenge, ChallengeLevel, ChallengeProgress } from "../../../types/challenge.types";
import ChallengesBackground from "../Common/challenges-background.component";
import ChallengesBody from "../Common/challenges-body.component";
import { CategoryFilter } from "./challenge-category-filters.component";
import ChallengeFilters from "./challenge-filters.component";
import { ChallengeItem } from "./challenge-item.component";

import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/userSlice";
import { getUserData } from "../../../services/auth";
import { updateUserData } from "../../../redux/userSlice";
import { useAppDispatch } from "../../../redux/store";
import { useIsFocused } from "@react-navigation/native";
import { iteratorSymbol } from "immer/dist/internal";

function getScaledImpacts(challenges) {
  challenges.sort((a, b) => b.carbonImpact - a.carbonImpact);
  let max = challenges[0].carbonImpact;
  let min = challenges[challenges.length - 1].carbonImpact;
  let range = max - min;

  challenges.forEach(element => {
    element.scaledImpact = (element.carbonImpact - min) / range;
  });

  return challenges;
}

type DifficultyFilter = Record<ChallengeLevel, boolean>;
const defaultDifficultyFilter = {
  [ChallengeLevel.Easy]: true,
  [ChallengeLevel.Medium]: true,
  [ChallengeLevel.Hard]: true,
};

type SliderFilter = { minVal: number; maxVal: number };
const defaultImpactFilter: SliderFilter = { minVal: 0, maxVal: 100 };
const defaultPointsFilter: SliderFilter = { minVal: 0, maxVal: 100 };

// Includes search bar, filters, and list of challenges
function ChallengesContent() {
  const [loadingChallenges, setLoadingChallenges] = useState(false);
  const [challenges, setChallenges] = useState([]);
  const [challengeImpactScaled, setChallengeImpactScaled] = useState([])

  const [searchText, setSearchText] = useState<string>("");
  const [categoryFilter, setCategoryFilter] =
    useState<CategoryFilter>("recommended");
  const [difficultyFilter, setDifficultyFilter] = useState<DifficultyFilter>(
    defaultDifficultyFilter
  );
  const [impactFilter, setImpactFilter] =
    useState<SliderFilter>(defaultImpactFilter);
  const [pointsFilter, setPointsFilter] =
    useState<SliderFilter>(defaultPointsFilter);

  let user = useSelector(selectUser);
  const isFocused = useIsFocused();
  const dispatch = useAppDispatch();
  const [challengesProgress, setChallengesProgress] = useState<
    ChallengeProgress[]
  >([]);
  // Populate challenges list from database
  useEffect(() => {
    setLoadingChallenges(true);
    getAllChallenges().then((challenges) => {
      setChallenges(challenges);
      setLoadingChallenges(false);
      setChallengeImpactScaled(getScaledImpacts(challenges.map((x) => x)))
    });


    const fetchData = async () => {
      const data = await getUserData(user.id);
      dispatch(updateUserData(data.data.userData));
      setChallengesProgress(data.data.userData.inrolledchallenges);
    };

    // call the function
    isFocused &&
      fetchData()
        // make sure to catch any error
        .catch(console.error);

  }, [setChallenges, getAllChallenges, isFocused]);

  // Updater functions to be passed to search/filter components
  const updateCategoryFilter = (category: CategoryFilter) => {
    setCategoryFilter(category);
  };
  const updateSearchQuery = (text: string) => {
    // TODO: optimization: don't set every textbox update (debounce)
    setSearchText(text);
  };
  const updateDifficultyFilter = (
    difficulty: ChallengeLevel,
    checked: boolean
  ) => {
    setDifficultyFilter((oldFilter) => ({
      ...oldFilter,
      [difficulty]: checked,
    }));
  };
  const updateImpactFilter = (minVal: number, maxVal: number) => {
    setImpactFilter({ minVal, maxVal });
  };
  const updatePointsFilter = (minVal: number, maxVal: number) => {
    setPointsFilter({ minVal, maxVal });
  };

  // Predicate functions for filtering challenges list
  const filterCategory = (challenge: Challenge) => {
    return categoryFilter === "recommended"
      ? // TODO: recommendation logic, for now just show all challenges on the "For you" option
      true
      : challenge.category === categoryFilter;
  };
  const filterSearchText = (challenge: Challenge) => {
    // Can only search by title. Doesn't handle substrings, only searches from
    // the beginning of strings. Case insensitive.
    return searchText.toLowerCase() === ""
      ? challenge
      : (challenge.challengeName.toLowerCase()).includes(searchText.toLowerCase());
  };
  const filterDifficulty = (challenge: Challenge) => {
    return difficultyFilter[challenge.level] === true;
  };
  const filterImpact = (challenge: Challenge) => {
    return (
      challenge.carbonImpact >= impactFilter.minVal &&
      // If the impact is at its max, include challenges greater than max as well
      (impactFilter.maxVal === defaultImpactFilter.maxVal
        ? true
        : challenge.carbonImpact <= impactFilter.maxVal)
    );
  };
  const filterPoints = (challenge: Challenge) => {
    return (
      challenge.climaPoints >= pointsFilter.minVal &&
      // If the points value is at its max, include challenges greater than max
      // as well
      (pointsFilter.maxVal === defaultPointsFilter.maxVal
        ? true
        : challenge.climaPoints <= pointsFilter.maxVal)
    );
  };

  const filteredChallenges = challenges
    .filter(filterCategory)
    .filter(filterSearchText)
    .filter(filterDifficulty)
    .filter(filterImpact)
    .filter(filterPoints);

  const renderItem = ({ item }) => <ChallengeItem challenge={item} challengeProgress={challengesProgress.find(
    (c) => c.challengeCode === item.challengeCode
  )} challengeImpactScaled={item.scaledImpact} />;
  const categoryColor = CategoryColors[categoryFilter];

  return (
    <>
      <ChallengeFilters
        categoryColor={categoryColor}
        selectedCategory={categoryFilter}
        updateSearchQuery={updateSearchQuery}
        updateCategoryFilter={updateCategoryFilter}
        updateDifficultyFilter={updateDifficultyFilter}
        updateImpactFilter={updateImpactFilter}
        updatePointsFilter={updatePointsFilter}
      />
      <FlatList
        data={filteredChallenges}
        renderItem={renderItem}
        keyExtractor={(item) => item.challengeCode}
        ListEmptyComponent={
          loadingChallenges ? (
            <View style={{ flex: 1, justifyContent: "center" }}>
              <ActivityIndicator size="large" />
            </View>
          ) : undefined
        }
        ListFooterComponent={
          // Show at the bottom if any challenges are being filtered out
          filteredChallenges.length === challenges.length ? undefined : (
            <Text
              style={{
                color: Colors.mediumGrey,
                margin: 20,
                textAlign: "center",
              }}
            >
              Some challenges are hidden because of the current filters, try
              changing them to view the rest!
            </Text>
          )
        }
      />
    </>
  );
}

export default function NewChallenge({ navigation }) {
  return (
    <ChallengesBackground navigation={navigation}>
      <Text className="color-white h3 align-center mb-sm">
        {stringsEng.NEW_CHALLENGE.HEADER}
      </Text>
      <ChallengesBody backgroundColor={Colors.lightGrey}>
        <ChallengesContent />
      </ChallengesBody>
    </ChallengesBackground>
  );
}
