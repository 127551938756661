import { StyleSheet } from "react-native";
import { Colors } from "../../../../theme";

export const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
  tab: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  centerTabOuter: {
    top: -30,
    backgroundColor: Colors.lightGrey,
    overflow: "hidden",
    width: 90,
    aspectRatio: 1,
    borderRadius: 100,
  },
  centerTabInner: {
    // The special middle round tab
    position: "absolute",
    borderRadius: 100,
    top: -20,
    width: 70,
    aspectRatio: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  centerTabImage: {
    width: 30,
    height: 30,
    tintColor: Colors.white,
  },
  tabImage: {
    width: 45,
    height: 45,
  },
});
