import { Resolver } from "react-hook-form";
import { stringsEng } from "../../../../assets/text/strings-english";
import { FormData } from "./join-school-form.component";

const resolver: Resolver<FormData> = async (values) => {
  return {
    values: values.schoolCode ? values : {},
    errors: !values.schoolCode
      ? {
          schoolCode: {
            type: "required",
            message: stringsEng.ONBOARDING.JOIN_SCHOOL.ERRORS.SCHOOL_CODE,
          },
        }
      : {},
  };
};

export default resolver;
