import React from "react";
import CFTButton from "../../../components/Button/cft-button.component";

export default function HamburgerDrawerButton({ navigation }) {
  return (
    <CFTButton
      iconSource={require("../../../../assets/images/icons/icon-burger.png")}
      buttonStyle={{ padding: 0, backgroundColor: "transparent" }}
      buttonSize={40}
      onPress={() => navigation.toggleDrawer()}
    />
  );
}
