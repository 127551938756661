import { Text, View } from "react-native-picasso";
import { Colors } from "../../../theme";
import GradientBackground from "../../components/Background/gradient-background.component";
import CFTButton from "../../components/Button/cft-button.component";
import ChallengesBackground from "../Challenges/Common/challenges-background.component";
import { styles } from "./contact-styles";
import CFTTextInput from "../../components/TextInput/text-input.component";
import { stringsEng } from "../../../assets/text/strings-english";
import React, { useState } from "react";
import { Modal, Pressable, ScrollView } from "react-native";
import { Controller, useForm } from "react-hook-form";
import { modalStyles } from "../../components/Modal/modal.styles";
import Emoji from "../../components/Emoji/emoji.component";

export default function ContactUs({ navigation }) {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name: "",
            email: "",
            msg: ""
        },
    });

    const [modalVisible, setModalVisible] = useState(false);
    const [modalText, setModalText] = useState("");
    const [modalEmoji, setModalEmoji] = useState("");

    const onSubmit = handleSubmit(async (form) => {
        let data = {
            "name": form.name,
            "email": form.email,
            "msg": form.msg
        }
        await sendMail(JSON.stringify(data));
    })

    const sendMail = async (mail) => {
        await fetch("https://climact-api.herokuapp.com/api/v1/email/send", {
            method: "POST", //2.
            body: mail, //3.
            headers: {
                "Content-Type": "application/json",
            }
        }).then(async (response) => {
            let res = await response.json();
            if (res.success) {
                setModalText(stringsEng.CONTACT_US.SUCCESS_SENT);
                setModalEmoji(stringsEng.TEACHER.STUDENT_LIST.MODAL_CONFIRM + stringsEng.CONTACT_US.SUCESS_EMOJI)
                setModalVisible(!modalVisible);
            }
            else {
                setModalText(stringsEng.CONTACT_US.FAILURE);
                setModalEmoji(stringsEng.TEACHER.STUDENT_LIST.MODAL_CONFIRM + stringsEng.CONTACT_US.FAILURE_EMOJI)
            }
        }).catch((error) => {
            setModalText(stringsEng.CONTACT_US.FAILURE);
            setModalEmoji(stringsEng.TEACHER.STUDENT_LIST.MODAL_CONFIRM + stringsEng.CONTACT_US.FAILURE_EMOJI)
            console.log("Error: " + error)
        })

        setModalVisible(!modalVisible);
    };


    return (
        <>
            <GradientBackground />
            <ChallengesBackground navigation={navigation}>
                <View style={styles.container}>

                    <View style={styles.textView}>
                        <Text style={styles.textHeader} className="align-center h1">
                            {stringsEng.CONTACT_US.HEADER}
                        </Text>
                    </View>

                    <ScrollView>
                        <View style={styles.inputContainer}>

                            <Modal
                                animationType="slide"
                                transparent={true}
                                visible={modalVisible}
                                onRequestClose={() => {
                                    setModalVisible(!modalVisible);
                                }}
                            >
                                <View style={modalStyles.centeredView}>
                                    <View style={modalStyles.modalView}>
                                        <Text style={modalStyles.modalText}>{modalText}</Text>
                                        <Pressable
                                            style={[modalStyles.button, modalStyles.buttonClose]}
                                            onPress={() => { setModalVisible(!modalVisible); }}
                                        >
                                            <Emoji symbol={modalEmoji} />
                                        </Pressable>
                                    </View>
                                </View>
                            </Modal>

                            <View style={styles.inputView}>

                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field: { onBlur, onChange, value } }) => (
                                        <CFTTextInput
                                            onBlur={onBlur}
                                            onChangeText={onChange}
                                            value={value}
                                            placeholder={stringsEng.CONTACT_US.NAME}
                                            iconSource={require("../../../assets/images/icons/icon_person.png")}
                                            placeholderTextColor={Colors.mediumGrey}
                                            style={[styles.input]}
                                            iconColor={Colors.darkGrey}
                                        />
                                    )} />
                            </View>
                            <View style={styles.inputView}>
                                <Controller
                                    name="email"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field: { onBlur, onChange, value } }) => (
                                        <CFTTextInput
                                            onBlur={onBlur}
                                            onChangeText={onChange}
                                            value={value}
                                            placeholder={stringsEng.CONTACT_US.EMAIL}
                                            iconSource={require("../../../assets/images/icons/icon_msg.png")}
                                            placeholderTextColor={Colors.mediumGrey}
                                            style={[styles.input]}
                                            iconColor={Colors.darkGrey}
                                        />
                                    )} />
                            </View>
                            <View style={[styles.inputView, styles.msg]}>
                                <Controller
                                    name="msg"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field: { onBlur, onChange, value } }) => (
                                        <CFTTextInput
                                            onBlur={onBlur}
                                            onChangeText={onChange}
                                            value={value}
                                            placeholder={stringsEng.CONTACT_US.MESSAGE}
                                            placeholderTextColor={Colors.mediumGrey}
                                            multiline={true}
                                            numberOfLines={6}
                                            style={[styles.input]}
                                        />
                                    )} />
                            </View>
                        </View>
                    </ScrollView>

                    <View style={styles.inputContainer}>
                        <CFTButton
                            text={stringsEng.CONTACT_US.SEND}
                            buttonStyle={[styles.button]}
                            textColor={Colors.white}
                            buttonSize={"lg"}
                            iconSource={require("../../../assets/next-page-arrow.png")}
                            onPress={onSubmit}
                        />
                    </View>

                </View>
            </ChallengesBackground>
        </>
    );
}
