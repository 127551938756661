import { StyleSheet } from "react-native";
import { Colors } from "../../../../theme";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column"
  },
  textView: {
    padding: 5,
    flex: 2.5
  },
  text: {
    color: Colors.darkGrey,
  },
  textHeader: {
    color: Colors.skyBlue,
  },
  inputContainer: {
    flex: 0.5,
    margin: 5,
    flexDirection: "row",
    alignItems: "stretch",
  },
  button: {
    justifyContent: "center",
    alignItems: "center",
    borderColor: Colors.skyBlue, 
    borderWidth: 2
  },
  buttonInactive: {
    backgroundColor: Colors.white, 
  },
  buttonActive: {
    backgroundColor: Colors.deepCornflowerBlue,
  },
});
