import React from "react";
import LabelledSlider from "../../components/LabelledSlider/labelled-slider.component";
import { QuizItem, ScaleAnswer, ScaleQuestion } from "../../types/quiz.types";

type QuizScaleProps = {
  currentQuizQuestion: QuizItem<ScaleQuestion>;
  setAnswer: (updatedAnswer: Partial<ScaleAnswer>) => void;
};

export default function QuizScale({
  currentQuizQuestion,
  setAnswer,
}: QuizScaleProps) {
  const onInputFinish = (sliderValue: number[]) => {
    setAnswer({ type: "Scale", value: sliderValue[0] });
  };

  // useEffect(() => {
  //   // Set a default answer on page load/new question
  //   if (currentQuizQuestion.defaultValue){
  //     onInputFinish([currentQuizQuestion.defaultValue]);
  //   }
  // }, [currentQuizQuestion]);

  return (
    <LabelledSlider
      scaleData={currentQuizQuestion}
      onValuesChangeFinish={onInputFinish}
    />
  );
}
