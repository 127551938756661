import { Colors } from "../../../theme";
import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  overlay: {
    opacity: 0.5,
    backgroundColor: Colors.darkGrey,
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 1, //ensures it covers everything
    justifyContent: "center",
    alignItems: "center",
  },
  spinner: {
    position: "absolute",
    zIndex: 2,
    alignSelf: "center",
    top: "50%",
    transform: [{ scale: 2 }],
  },
});
