import React, { useState } from "react";
import { ColorValue, StyleSheet, View } from "react-native";
import { stringsEng } from "../../../../assets/text/strings-english";
import { Colors } from "../../../../theme";
import CFTButton from "../../../components/Button/cft-button.component";
import { SearchBar } from "../../../components/SearchBar/search-bar.component";
import {
  CategoryFilter,
  CategoryFilterSetter,
  ChallengeCategoryFilters,
} from "./challenge-category-filters.component";
import {
  ChallengeDifficultyFilters,
  DifficultyFilterSetter,
} from "./challenge-difficulty-filters.component";
import ChallengeFilterSlider, {
  SliderFilterSetter,
} from "./challenge-filter-slider.component";

type Props = {
  categoryColor: ColorValue;
  selectedCategory: CategoryFilter;
  updateCategoryFilter: CategoryFilterSetter;
  updateSearchQuery: (text: string) => void;
  updateImpactFilter: SliderFilterSetter;
  updatePointsFilter: SliderFilterSetter;
  updateDifficultyFilter: DifficultyFilterSetter;
};

// Contains the search bar and all filter components
export default function ChallengeFilters({
  categoryColor,
  selectedCategory,
  updateCategoryFilter,
  updateSearchQuery,
  updateImpactFilter,
  updatePointsFilter,
  updateDifficultyFilter,
}: Props) {
  const [filterDrawerExpanded, setFilterDrawerExpanded] = useState(false);
  const handleToggleExpanded = () => {
    setFilterDrawerExpanded((val) => !val);
  };

  return (
    <>
      <View style={styles.searchFilterContainer}>
        <SearchBar
          placeholderText={stringsEng.NEW_CHALLENGE.SEARCH_BAR_PLACEHOLDER}
          // Updater functions to be passed to search/filter components
          onChange={updateSearchQuery}
        />
        <View>
          <CFTButton
            iconSource={require("../../../../assets/images/filter.png")}
            onPress={handleToggleExpanded}
            buttonSize={50}
            buttonStyle={styles.filterToggleButton}
          />
        </View>
      </View>
      <ChallengeCategoryFilters
        selectedCategory={selectedCategory}
        onCategorySelected={updateCategoryFilter}
      />
      {filterDrawerExpanded && (
        <View style={styles.filterContainer}>
          <View>
            <View style={styles.filterBox}>
              <ChallengeFilterSlider
                headerText={stringsEng.COMMON.IMPACT}
                color={categoryColor}
                maxSliderValue={100}
                setFilter={updateImpactFilter}
              />
            </View>
            <View style={styles.filterBox}>
              <ChallengeFilterSlider
                headerText={stringsEng.COMMON.CLIMAPOINTS}
                color={categoryColor}
                maxSliderValue={100}
                setFilter={updatePointsFilter}
              />
            </View>
          </View>
          <View style={styles.filterBox}>
            <ChallengeDifficultyFilters
              color={categoryColor}
              setFilter={updateDifficultyFilter}
            />
          </View>
        </View>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  searchFilterContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  filterToggleButton: {
    padding: 0,
    backgroundColor: "transparent",
  },
  filterContainer: {
    flexDirection: "row",
    alignSelf: "center",
    marginBottom: 10,
  },
  filterBox: {
    backgroundColor: Colors.white,
    borderRadius: 10,
    padding: 10,
    margin: 5,
    alignItems: "center",
    justifyContent: "center",
  },
});
