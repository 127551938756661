import { Image, ImageRequireSource, TouchableOpacity } from "react-native";
import { Text, View } from "react-native-picasso";
import React from "react";
import { CategoryColors, Colors } from "../../../theme";
import { stringsEng } from "../../../assets/text/strings-english";
import { Challenge, ChallengeProgress } from "../../types/challenge.types";
import { AnimatedCircularProgress } from "react-native-circular-progress";
import { QuestionCategory } from "../../types/quiz.types";
import { styles } from "./my-challenge-item.styles";

const categoryImages: Record<QuestionCategory, ImageRequireSource> = {
  [QuestionCategory.Food]: require("../../../assets/images/icons/icon-food.png"),
  [QuestionCategory.Transport]: require("../../../assets/images/icons/icon-transportation.png"),
  [QuestionCategory.Housing]: require("../../../assets/images/icons/icon-housing.png"),
  [QuestionCategory.Stuff]: require("../../../assets/images/icons/icon-stuff.png"),
  [QuestionCategory.Other]: require("../../../assets/images/icons/icon-other.png"),
};

// Renders a challenge that the user has decided to start (with category image)
export function MyChallengeItem({
  challenge,
  challengeProgress,
  onPress,
}: {
  challenge: Challenge;
  challengeProgress: ChallengeProgress;
  onPress: (challenge: Challenge, challengeProgress: ChallengeProgress) => void;
}) {
  const categoryColor = CategoryColors[challenge?.category];

  const onPressChallenge = () => {
    onPress(challenge, challengeProgress);
  };

  // TODO: replace the hardcoded "3"
  const percentCompleted: number = Math.floor(
    (challengeProgress?.logCounter / 3) * 100
  );

  return (
    <TouchableOpacity onPress={onPressChallenge}>
      <View style={styles.container}>
        {/* Colored strip on the left */}
        <View
          style={[styles.coloredStrip, { backgroundColor: categoryColor }]}
        />

        <View style={styles.contentContainer}>
          {/* Grey box with category image */}
          <View style={styles.categoryBox}>
            <Image
              source={categoryImages[challenge?.category]}
              resizeMode="center"
              style={{ width: 40, height: 40, tintColor: categoryColor }}
            />
          </View>

          {/* Middle bit with all the text */}
          <View style={{ flex: 1 }}>
            <Text style={{ color: Colors.mediumGrey, fontSize: 10 }}>
              {stringsEng.COMMON.CHALLENGE_CATEGORIES[challenge?.category]}
            </Text>
            <Text style={{ fontSize: 14, fontFamily: "Poppins-semibold" }}>
              {challenge?.challengeName}
            </Text>
            <View style={styles.percentCompletedContainer}>
              {/* TODO: hardcoded text */}
              <Text style={{ fontSize: 8 }}>{percentCompleted}% Completed</Text>
            </View>
          </View>

          <AnimatedCircularProgress
            size={60}
            width={8}
            fill={percentCompleted} // out of 100
            rotation={0} // start angle
            tintColor={categoryColor as string}
            backgroundColor={Colors.lightGrey}
          />
        </View>
      </View>
    </TouchableOpacity>
  );
}
