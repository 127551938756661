import React from "react";
import {
  Image,
  TouchableOpacity,
  ImageSourcePropType,
  ColorValue,
} from "react-native";
import { View } from "react-native-picasso";
import { styles } from "./quiz-results-equivalents.styles";

export type ButtonProps = {
  bgColour: ColorValue;
  imageSource: ImageSourcePropType;
  changeEquivalence: Function;
  isSelected: boolean;
};

export default function EquivalenceButton({
  bgColour,
  imageSource,
  changeEquivalence,
  isSelected,
}: ButtonProps) {
  return (
    <TouchableOpacity
      onPress={() => changeEquivalence()}
      style={[{ backgroundColor: bgColour }, styles.equivalenceButton]}
    >
      <View
        style={[
          {
            backgroundColor: isSelected ? "white" : "transparent",
          },
          styles.equivalenceButtonImageContainer,
        ]}
      >
        <Image source={imageSource} style={styles.equivalenceButtonImage} />
      </View>
    </TouchableOpacity>
  );
}
