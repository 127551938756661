import React from "react";
import { Image, ScrollView } from "react-native";
import { Text, View } from "react-native-picasso";
import { stringsEng } from "../../../assets/text/strings-english";
import { Colors } from "../../../theme";
import GradientBackground from "../../components/Background/gradient-background.component";
import CFTButton from "../../components/Button/cft-button.component";
import HeaderBanner from "../../components/HeaderBanner/header-banner.component";
import { styles } from "./home-screen.styles";
import PressableText from "../../components/Button/pressable-text.component";

export default function HomeScreen({ navigation }) {
  const onPressTeacherSignup = () => {
    navigation.navigate("JoinSchool", { name: "JoinSchool" });
  };
  return (
    <>
      <GradientBackground />
      <HeaderBanner />
      <View style={styles.container}>
        <ScrollView>
          <Image
            style={styles.climactImage}
            source={require("../../../assets/climate-change.png")}
          />
          <View style={styles.textView}>
            <Text className="h2 color-white align-center">
              {stringsEng.ONBOARDING.HOME_SCREEN.START_TEXT}
            </Text>
            <Text
              className="h2 color-white align-center"
              style={{ marginTop: -10 }}
            >
              {stringsEng.ONBOARDING.HOME_SCREEN.CLIMACTJOURNEY}
            </Text>
            <Text className="mt-md h4 color-white align-center">
              {stringsEng.ONBOARDING.HOME_SCREEN.WELCOMETEXT}
            </Text>
          </View>
        </ScrollView>
        <View style={styles.inputContainer}>
          {/* <View style={styles.inputView}>
            <TextInput
              style={styles.TextInput}
              placeholder="Email/Name"
              placeholderTextColor="#003f5c"
              //onChangeText={(email) => setEmail(email)}
            />
          </View> */}
          <CFTButton
            text={stringsEng.ONBOARDING.HOME_SCREEN.LOGIN}
            buttonStyle={{ backgroundColor: Colors.limeGreen }}
            onPress={() => navigation.navigate("Login", { name: "Login" })}
            iconSource={require("../../../assets/images/icons/icon_lock.png")}
          />
          <CFTButton
            text={stringsEng.ONBOARDING.HOME_SCREEN.SIGN_UP}
            buttonStyle={{ backgroundColor: Colors.turquoise }}
            onPress={() =>
              navigation.navigate("JoinClass", { name: "JoinClass" })
            }
            iconSource={require("../../../assets/images/icons/icon_writing.png")}
          />
        </View>
        <PressableText
          className="color-white pt-0"
          onPress={onPressTeacherSignup}
        >
          {stringsEng.ONBOARDING.HOME_SCREEN.TEACHER_SIGN_UP}
        </PressableText>
      </View>
    </>
  );
}
