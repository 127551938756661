import { DrawerItem, DrawerItemList } from "@react-navigation/drawer";
import React from "react";
import { Image } from "react-native";
import { Text, View } from "react-native-picasso";
import { stringsEng } from "../../../assets/text/strings-english";
import { useAppDispatch } from "../../redux/store";
import { logout } from "../../redux/userSlice";
import GradientBackground from "../Background/gradient-background.component";
import CFTButton from "../Button/cft-button.component";
import { styles } from "./menu-drawer.styles";

export default function MenuDrawer(props) {
  const dispatch = useAppDispatch();

  return (

    <View style={styles.container}>
      <GradientBackground />
      <CFTButton
        iconSource={require("../../../assets/images/icons/icon-burger.png")}
        buttonStyle={styles.backArrowButton}
        buttonSize={40}
        onPress={() => props.navigation.goBack()}
      />
      {/* <Text>TODO: Header info here (name, profile pic, light/dark mode)</Text> */}
      <View style={styles.content}>
        <View style={styles.categories}>
          {/* Using a DrawerItemList is the "proper" way of doing it, which 
          applies the styles defined in the navigation.stack, but it's 
          probably easier to create the content from scratch to make sure 
          it doesn't render extra list items (as it does with this). */}
          {/* proceeding with this way for now! */}

          {/* To apply custom styles to all of the menu items, must use DrawerItems*/}

          <DrawerItem label={() => (
            <Text className="color-white">
              {stringsEng.MENU_DRAWER.HOME}
            </Text>
          )} onPress={() => {
            props.navigation.navigate("ChallengesDashboard")
          }}
            icon={() => <Image source={require("../../../assets/images/icons/icon-home-menu.png")} style={styles.itemIcon} />}
          />

          
          {/*can display when the Edit Profile page is done*/}
          {/* <View style={styles.account}>
            <Text style={styles.drawerCategory} className="h4"> {stringsEng.MENU_DRAWER.CATEGORIES.ACCOUNT} </Text>
            <DrawerItem label={() => (
              <Text className="color-white">
                {stringsEng.MENU_DRAWER.EDIT_PROFILE}
              </Text>
            )} onPress={() => {
              props.navigation.navigate("") //TODO: navigate to the edit profile page
            }}
              icon={() => <Image source={require("../../../assets/images/icons/icon-profile-menu.png")} style={styles.itemIcon} />} />
          </View> */}


          <View style={styles.other}>
            <Text style={styles.drawerCategory} className="h4"> {stringsEng.MENU_DRAWER.CATEGORIES.OTHER} </Text>
            <DrawerItem label={() => (
              <Text className="color-white">
                {stringsEng.MENU_DRAWER.TERMS}
              </Text>
            )} onPress={() => {
              props.navigation.navigate("Terms and Conditions")
            }}
              icon={() => <Image source={require("../../../assets/images/icons/icon-terms.png")} style={styles.itemIcon} />} />

            {/*can display when the Edit Profile page is done*/}
            {/* <DrawerItem label={() => (
              <Text className="color-white">
                {stringsEng.MENU_DRAWER.USER_GUIDE}
              </Text>
            )} onPress={() => {
              props.navigation.navigate("") //TODO: navigate to the user guide page
            }}
              icon={() => <Image source={require("../../../assets/images/icons/icon-guide.png")} style={styles.itemIcon} />} /> */}

            <DrawerItem label={() => (
              <Text className="color-white">
                {stringsEng.MENU_DRAWER.ABOUT}
              </Text>
            )} onPress={() => {
              props.navigation.navigate("About Us")
            }}
              icon={() => <Image source={require("../../../assets/images/icons/icon-about.png")} style={styles.itemIcon} />} />

            <DrawerItem label={() => (
              <Text className="color-white">
                {stringsEng.MENU_DRAWER.CONTACT}
              </Text>
            )} onPress={() => {
              props.navigation.navigate("Contact Us")
            }}
              icon={() => <Image source={require("../../../assets/images/icons/icon-contact.png")} style={styles.itemIcon} />} />
          </View>


        </View>

        <View>
          {/*<View style={styles.separator} />*/}
          <DrawerItem
            icon={() => (
              <Image
                // TODO: icon
                source={require("../../../assets/images/icons/icon-logoff.png")}
                style={styles.itemIcon}
                resizeMode="contain"
              />
            )}
            label={() => (
              <Text className="color-white">
                {stringsEng.MENU_DRAWER.LOGOUT}
              </Text>
            )}
            onPress={() => dispatch(logout())}
          />
        </View>
      </View>
    </View>
  );
}
