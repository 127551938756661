import { Resolver } from "react-hook-form";
import { CreateAccountFormData } from "./create-account-form.component";
import { stringsEng } from "../../../../assets/text/strings-english";
import { validPassword, validEmail } from "../Common/regex";

const resolver: Resolver<CreateAccountFormData> = async (values) => {
    return {
        values: values.firstName ? values : values.lastName ? values : values.username ? values : values.email ? values :
            values.confirmEmail ? values : values.password ? values : values.confirmPassword ? values : {},
        errors: !values.firstName
        ? {
            firstName: {
                type: 'required',
                message: stringsEng.ONBOARDING.CREATE_ACCOUNT.ERRORS.FIRSTNAME
            },
        } :
        !values.lastName
        ? {
            lastName: {
                type: 'required',
                message: stringsEng.ONBOARDING.CREATE_ACCOUNT.ERRORS.LASTNAME
            },
        } :
        !values.username
        ? {
            username: {
                type: 'required',
                message: stringsEng.ONBOARDING.CREATE_ACCOUNT.ERRORS.USERNAME
            },
        } :
        !values.email
        ? {
            email: {
                type: 'required',
                message: stringsEng.ONBOARDING.CREATE_ACCOUNT.ERRORS.EMAIL
            },
        } : !validEmail.test(values.email) ? {
            password: {
                type: 'regex',
                message: stringsEng.ONBOARDING.FORGOT_PASSWORD.ERRORS.EMAIL
            }
        } 
        :!values.confirmEmail
        ? {
            confirmEmail: {
                type: 'required',
                message: stringsEng.ONBOARDING.CREATE_ACCOUNT.ERRORS.CONFIRM_EMAIL
            },
        } :
        !values.password ? {
            password: {
                type: 'required',
                message: stringsEng.ONBOARDING.CREATE_ACCOUNT.ERRORS.PASSWORD
            }
        } :
        !validPassword.test(values.password) ? {
            password: {
                type: 'regex',
                message: stringsEng.ONBOARDING.FORGOT_PASSWORD_CODE.ERRORS.PASSWORD
            }
        } :
        !values.confirmPassword ? {
            confirmPassword: {
                type: 'required',
                message: stringsEng.ONBOARDING.CREATE_ACCOUNT.ERRORS.CONFIRM_PASSWORD
            }
        } :
        values.confirmPassword !== values.password ? {
            passwordMatch: {
                type: 'required',
                message: stringsEng.ONBOARDING.CREATE_ACCOUNT.ERRORS.PASSWORD_MATCH
            }
        } :
        values.confirmEmail !== values.email ? {
            emailMatch: {
                type: 'required',
                message: stringsEng.ONBOARDING.CREATE_ACCOUNT.ERRORS.EMAIL_MATCH
            }
        } :

                {},
    };
};

export default resolver;