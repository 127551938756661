import React, { useEffect, useState } from "react";
import { View, Text } from "react-native-picasso";
import { Image, ScrollView } from "react-native";
import { stringsEng } from "../../../../assets/text/strings-english";
import { Colors } from "../../../../theme";
import CategoryButton from "../QuizResultsBreakdown/category-button.component";
import { styles } from "./footprint.styles";
import AverageBar from "../QuizResultsAverages/average-bar.component";
import CFTButton from "../../../components/Button/cft-button.component";
import FootprintBackground from "./footprint-background.component";
import Overlay from "../../../components/LoadingOverlay/overlay.component";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/userSlice";
import { getUserData } from "../../../services/auth";
import { useIsFocused } from "@react-navigation/native";
import { updateUserData } from "../../../redux/userSlice";
import { useAppDispatch } from "../../../redux/store";

export default function Footprint({ navigation, route }) {
  const [loding, setLoading] = useState(false);
  const [footprintData, setFootprintData] = useState({
    food: 0,
    housing: 0,
    other: 0,
    stuff: 0,
    total: 0,
    transit: 0,
  });
  const isFocused = useIsFocused();
  const user = useSelector(selectUser);
  // const footprintData = user.userData?.footprint;
  const dispatch = useAppDispatch();
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const userData = await getUserData(user.id);
      dispatch(updateUserData(userData.data.userData));
      setFootprintData(userData.data.userData.footprint);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    };
    isFocused && fetchData().catch((err) => console.log(err));
  }, [isFocused]);

  function navigateToQuiz() {
    navigation.navigate("QuizPages", { screen: "Quiz" });
  }

  //This part allows you to switch the modal content
  //Update array
  //Static for now
  // const categoryFootprints = [0, 1, 2, 3, 4];

  // const [modalOpen, toggleModal] = React.useState(false);
  // const [categoryIndex, changeCategoryIndex] = React.useState(0);
  // function closeModal() {
  //   toggleModal(false);
  // }
  // function switchFood() {
  //   changeCategoryIndex(0);
  //   toggleModal(true);
  // }
  // function switchTransportation() {
  //   changeCategoryIndex(1);
  //   toggleModal(true);
  // }
  // function switchHousing() {
  //   changeCategoryIndex(2);
  //   toggleModal(true);
  // }
  // function switchStuff() {
  //   changeCategoryIndex(3);
  //   toggleModal(true);
  // }
  // function switchOther() {
  //   changeCategoryIndex(4);
  //   toggleModal(true);
  // }

  //For navigation to the next results page
  //Change navigation destination
  // function navigateToNextResult() {
  //   navigation.navigate("QuizResultsAverages", { footprint });
  // }
  // function navigateToPreviousResult() {
  //   navigation.navigate("QuizResultsEquivalents", { footprint });
  // }

  const maxFootprint = Math.max(footprintData.total, stringsEng.QUIZ_RESULT.QUIZ_RESULTS_AVERAGES.DATA.WORLD_AVG, stringsEng.QUIZ_RESULT.QUIZ_RESULTS_AVERAGES.DATA.CANADA_AVG)

  const saved = user.userData.carbonImpact.toFixed(3);
  const total = footprintData.total;
  const offset = Math.floor((saved / total) * 100);

  if (loding) return <Overlay display={true} />;

  return (
    <>
      <FootprintBackground navigation={navigation}>
        {/* <CategoryPopUp
        onClose={closeModal}
        categoryIndex={categoryIndex}
        isOpen={modalOpen}
        categoryFootprint={categoryFootprints[categoryIndex]}
      /> */}
        <View style={{ justifyContent: "space-between", height: "85%" }}>
          <View style={styles.headerContainer}>


            <View style={styles.headerBox}>
              <Text className="h3 align-center mt-md" style={styles.header}>
                {stringsEng.FOOTPRINT.HEADER} </Text>
              <View style={styles.headerStats}>
                <View>
                  <Text className="h2 align-center" style={[styles.units, styles.numbers]} >{Math.round(total)}</Text>
                  <Text className="h6 align-center" style={styles.units}>{stringsEng.FOOTPRINT.YEARLY_FOOTPRINT_UNITS}</Text>
                  <Image source={require("../../../../assets/images/icons/icon-footprint.png")}
                    style={styles.iconPaw} />
                </View>
                <View>
                  <Text className="h2 align-center" style={[styles.units, styles.numbers]}>{saved}</Text>
                  <Text className="h6 align-cente" style={styles.units}>{stringsEng.FOOTPRINT.TOTAL_SAVED}</Text>
                  <Image source={require("../../../../assets/images/icons/icon-leaf.png")}
                    style={styles.iconLeaf} />
                </View>
                <View>
                  <Text className="h2 align-center" style={[styles.units, styles.numbers]}>{offset}%</Text>
                  <Text className="h6 align-center" style={styles.units}>{stringsEng.FOOTPRINT.OFFSET}
                  </Text>
                  <Image source={require("../../../../assets/images/graph-1.png")}
                    style={styles.iconGraph} />
                </View>

              </View>
            </View>
          </View>

          <View style={styles.buttonContainer}>
            {/* Food */}
            <CategoryButton
              percentage={(footprintData.food / footprintData.total) * 100}
              onPress={() => { }} //to keep buttons static
              bgColour={Colors.red}
              imageSource={require("../../../../assets/images/icons/icon-food.png")}
            />
            {/* Transportation */}
            <CategoryButton
              percentage={(footprintData.transit / footprintData.total) * 100}
              onPress={() => { }}
              bgColour={Colors.mossGreen}
              imageSource={require("../../../../assets/images/icons/icon-transportation.png")}
            />

            {/* Housing */}
            <CategoryButton
              percentage={(footprintData.housing / footprintData.total) * 100}
              onPress={() => { }}
              bgColour={Colors.yellow}
              imageSource={require("../../../../assets/images/icons/icon-housing.png")}
            />
            {/* Stuff */}
            <CategoryButton
              percentage={(footprintData.stuff / footprintData.total) * 100}
              onPress={() => { }}
              bgColour={Colors.orange}
              imageSource={require("../../../../assets/images/icons/icon-stuff.png")}
            />

            {/* Other */}
            <CategoryButton
              percentage={(footprintData.other / footprintData.total) * 100}
              onPress={() => { }}
              bgColour={Colors.skyBlue}
              imageSource={require("../../../../assets/images/icons/icon-other.png")}
            />
          </View>
          <ScrollView>
            <View style={{ marginTop: 15, flexDirection: "column" }}>

              <AverageBar
                bgColor={Colors.orange}
                tonnesValue={footprintData.total}
                averageText={
                  stringsEng.QUIZ_RESULT.QUIZ_RESULTS_AVERAGES.YOUR_AVG
                }
                maxFootprint={maxFootprint}
              />
              <AverageBar
                bgColor={Colors.mossGreen}
                tonnesValue={
                  stringsEng.QUIZ_RESULT.QUIZ_RESULTS_AVERAGES.DATA.WORLD_AVG
                }
                averageText={
                  stringsEng.QUIZ_RESULT.QUIZ_RESULTS_AVERAGES.WORLD_AVG
                }
                maxFootprint={maxFootprint}
              />
              <AverageBar
                bgColor={Colors.red}
                tonnesValue={
                  stringsEng.QUIZ_RESULT.QUIZ_RESULTS_AVERAGES.DATA.CANADA_AVG
                }
                averageText={
                  stringsEng.QUIZ_RESULT.QUIZ_RESULTS_AVERAGES.CANADA_AVG
                }
                maxFootprint={maxFootprint}
              />
              {/* <AverageBar
              bgColor={Colors.purple}
              tonnesValue={
                stringsEng.QUIZ_RESULT.QUIZ_RESULTS_AVERAGES.DATA.CLASS_AVG
              }
              displayKGs={true}
              averageText={stringsEng.FOOTPRINT.CLASS_AVG}
            /> */}

            </View>
          </ScrollView>

          <View style={{ textTransform: "uppercase", justifyContent: "flex-end" }}>
            <CFTButton
              text={stringsEng.FOOTPRINT.CALCULATE_AGAIN}
              textColor={Colors.white}
              onPress={navigateToQuiz}
              buttonSize={32}
              buttonStyle={{
                backgroundColor: Colors.mediumGrey,

              }}
            />
          </View>
        </View>
      </FootprintBackground>
    </>
  );
}
