import { api } from "./api";
import { Challenge } from "../types/challenge.types";

export const getAllChallenges = () =>
  api
    .get<{ challenges: Challenge[] }>("/challenges")
    .then((res) => res.data.challenges)
    .catch((e) => {
      console.log("Couldn't load challenges", e);
      return [];
    });

export const getUserChallenges = (userId: string) =>
  api
    .get<{ challenges: Challenge[] }>(`/user/${userId}/challenge`)
    .then((res) => res.data.challenges)
    .catch((e) => {
      console.log("Couldn't join the challenge", e);
      return [];
    });

export const enrollInChallenge = (
  userId: string,
  challengeCode: string,
  isRepeat: boolean
): Promise<boolean> =>
  api
    .post(`/user/${userId}/challenge/enroll`, { challengeCode, isRepeat })
    .then((res) => true)
    .catch((e) => {
      console.log("Couldn't join the challenge", e);
      return false;
    });

export const logChallenge = (
  userId: string,
  challengeCode: string
): Promise<boolean> =>
  api
    .post(`/user/${userId}/challenge/log`, { challengeCode })
    .then((res) => true)
    .catch((e) => {
      console.log("Couldn't log the challenge", e);
      return false;
    });

export const markAsCompleted = (
  userId: string,
  challengeCode: string
): Promise<boolean> =>
  api
    .post(`/user/${userId}/challenge/complete`, { challengeCode })
    .then((res) => true)
    .catch((e) => {
      console.log("Couldn't mark the challenge as complete", e);
      return false;
    });
