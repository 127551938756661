import {
  ColorValue,
  Image,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  ActivityIndicator,
} from "react-native";
import React, { useState } from "react";
import { AssignmentType } from "../../../types/assignment.types";
import { stringsEng } from "../../../../assets/text/strings-english";
import { Colors } from "../../../../theme";
import { styles } from "./assignment-reflection.styles";
import { useForm } from "react-hook-form";
import { submitFeedback } from "../../../services/feedback.service";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/userSlice";
import CFTButton from "../../../components/Button/cft-button.component";

export type FeedbackType = {
  feedback: string;
  difficulty: number;
};

type Difficulty = {
  text: string;
  color: ColorValue;
};
const difficulties: Difficulty[] = [
  { text: stringsEng.ASSIGNMENT.REFLECTION.SCORE_HIGH, color: Colors.red },
  { text: stringsEng.ASSIGNMENT.REFLECTION.SCORE_MEDIUM, color: Colors.yellow },
  {
    text: stringsEng.ASSIGNMENT.REFLECTION.SCORE_LOW,
    color: Colors.greenSmoke,
  },
];

type Props = { assignment: AssignmentType };
export default function AssignmentReflection({ assignment }: Props) {
  // TODO: nothing is currently being done with the difficulty selection or
  // reflection textbox
  const [selectedDifficulty, setSelectedDifficulty] = useState<number | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const [submited, setSubmitted] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FeedbackType>({
    defaultValues: {
      feedback: "",
      difficulty: -1,
    },
  });

  const user = useSelector(selectUser);

  const onSubmit = handleSubmit(async (data) => {
    // send request using feedback service
    setLoading(true);
    submitFeedback(
      user.id,
      assignment.assignmentId,
      user.userData?.class[0],
      data
    )
      .then(() => {
        setTimeout(() => {
          setSubmitted(true);
          setLoading(false);
        }, 1000);
      })
      .catch(() => {
        setLoading(false);
        setSubmitted(false);
        // something went wrong promt hadling
      });
  });

  return (
    <View>
      {!submited && !loading ? (
        <>
          <View style={styles.titleContainer}>
            <Image
              style={styles.titleIcon}
              resizeMode="contain"
              source={require("../../../../assets/images/assignment.png")}
            />
            <Text style={styles.title}>
              {stringsEng.ASSIGNMENT.REFLECTION.TITLE}
            </Text>
          </View>

          <View style={{ flexDirection: "row", marginVertical: 5 }}>
            {difficulties.map(({ text, color }, i) => (
              <TouchableOpacity
                key={i}
                onPress={() => setSelectedDifficulty(i)}
                style={[
                  styles.difficultyBox,
                  selectedDifficulty === i
                    ? { backgroundColor: color, borderColor: "transparent" }
                    : { borderColor: color },
                  { marginLeft: i === 0 ? 0 : 10 },
                ]}
              >
                <Text
                  style={[
                    styles.difficultyText,
                    { color: selectedDifficulty === i ? Colors.white : color },
                  ]}
                >
                  {text}
                </Text>
              </TouchableOpacity>
            ))}
          </View>
          <View style={styles.inputWrapper}>
            <Image
              style={styles.inputIcon}
              resizeMode="contain"
              source={require("../../../../assets/images/icons/icon_writing.png")}
            />
            <TextInput
              placeholderTextColor={Colors.cornflowerBlue}
              placeholder={stringsEng.ASSIGNMENT.REFLECTION.TEXT_PLACEHOLDER}
              style={styles.input}
            />
          </View>
          <View>
            <CFTButton
              disabled={selectedDifficulty === -1}
              text={stringsEng.ASSIGNMENT.REFLECTION.SUBMIT}
              buttonStyle={styles.submitButton}
              buttonSize="md"
              iconSource={require("../../../../assets/images/icons/icon_writing.png")}
              onPress={onSubmit}
              textColor={Colors.white}
            />
          </View>
        </>
      ) : loading ? (
        <View style={{ flex: 1, justifyContent: "center", padding: 10 }}>
          <ActivityIndicator size={40} />
        </View>
      ) : (
        <></>
      )}
    </View>
  );
}
