import { StyleSheet } from "react-native";
import { Colors } from "../../../theme";

export const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    backgroundColor: Colors.whiteSmoke,
    marginHorizontal: 20,
    paddingHorizontal: 15,
    paddingTop: 5,
    borderRadius: 8,
    width: "100%",
    alignSelf: "center"
  },
  sourceText: {
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    alignSelf: "flex-end",
    marginTop: -5,
  },
  sourceButtons: {
    justifyContent: "space-around",
    flexDirection: "row",
    margin: 10,
  },
  factText: {
    fontSize: 13
  }
});
