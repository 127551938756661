import { api } from "./api";

// class for the all the api calls related to the school
export default class SchoolService {
  // get the school data from the api
  static getSchoolData = async (code: string) => {
    try {
      const res = await api.get(`/school/${code}`);
      if (res.data.success) {
        return res.data;
      } else {
        console.log("School does not exist.");
        return null;
      }
    } catch (err) {
      console.log("Could not get school data. " + err);
      return null;
    }
  };
}
