import {
  ColorValue,
  Image,
  ImageSourcePropType,
  ImageStyle,
  StyleProp,
  StyleSheet,
} from "react-native";
import { Text, View } from "react-native-picasso";
import React from "react";
import { Colors } from "../../../theme";
import { stringsEng } from "../../../assets/text/strings-english";

type Props = {
  icon: ImageSourcePropType;
  iconStyle: StyleProp<ImageStyle>;
  backgroundColor: ColorValue;

  numberValue: number;
  unit: string;
  verb: string; // e.g. Saved, Earned
};
// Box showing ClimaPoints for example
function NumberValueBox({
  icon,
  iconStyle,
  backgroundColor,
  numberValue,
  unit,
  verb,
}: Props) {
  return (
    <View
      style={{
        backgroundColor,
        borderRadius: 16,
        margin: 10,
        paddingVertical: 10,
        overflow: "hidden",
        flexBasis: 110,
        flexShrink: 1,
      }}
    >
      <View style={StyleSheet.absoluteFill}>
        <Image source={icon} style={iconStyle} resizeMode="contain" />
      </View>
      <View style={{ justifyContent: "center", alignItems: "center" }}>
        <Text
          style={{
            color: Colors.white,
            fontSize: 30,
            marginBottom: -10,
            fontWeight: "bold",
          }}
        >
          {numberValue}
        </Text>
        <Text style={{ color: Colors.white, fontSize: 8 }}>{unit}</Text>
        <Text style={{ color: Colors.white, fontSize: 14, fontWeight: "bold" }}>
          {verb}
        </Text>
      </View>
    </View>
  );
}
export function CarbonOffsetBox({ numberValue }) {
  return (
    <NumberValueBox
      numberValue={numberValue}
      icon={require("../../../assets/images/icons/icon-leaf.png")}
      iconStyle={{
        width: 90,
        height: 90,
        left: 30,
        top: 0,
      }}
      backgroundColor={Colors.mossGreen}
      unit={stringsEng.COMMON.CARBON_UNIT}
      verb={stringsEng.COMMON.SAVED}
    />
  );
}
export function ClimaPointsBox({ numberValue }) {
  return (
    <NumberValueBox
      numberValue={numberValue}
      icon={require("../../../assets/images/icons/icon-star.png")}
      iconStyle={{
        width: 90,
        height: 90,
        left: 24,
        top: 0,
      }}
      backgroundColor={Colors.orange}
      unit={stringsEng.COMMON.CLIMAPOINTS}
      verb={stringsEng.COMMON.EARNED}
    />
  );
}
export function StreakBox({ numberValue }) {
  return (
    <NumberValueBox
      numberValue={numberValue}
      // TODO:
      icon={require("../../../assets/images/icons/streak-fire.png")}
      iconStyle={{
        width: 75,
        height: 75,
        left: 45,
        top: 0,
      }}
      backgroundColor={Colors.lightRed}
      unit={stringsEng.COMMON.DAY}
      verb={stringsEng.COMMON.STREAK}
    />
  );
}
