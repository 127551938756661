import React from "react";
import { Image } from "react-native";
import { Text, View } from "react-native-picasso";
import GradientBackground from "../../components/Background/gradient-background.component";
import ProfileStat from "./profileStat.component";
import ChallengesStat from "./challengesStat.component";
import { styles } from "./profile.styles";
import HamburgerDrawerButton from "../Challenges/Common/hamburger-drawer-button.component";
import { ScrollView } from "react-native-gesture-handler";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/userSlice";



export default function ProfilePage({ navigation }) {
  const user = useSelector(selectUser);
  return (
    <ProfilePageBackground navigation={navigation}>
      <Profile />
      <ScrollView>
        <ProfileStat statData={user.userData} />
        <ChallengesStat userData={user.userData}/>
      </ScrollView>
    </ProfilePageBackground>
  );
}

function ProfilePageBackground({ children, navigation }) {
  return (
    <>
      <GradientBackground />

      <View style={styles.logoContainer}>
        <Image
          source={require("../../../assets/images/climact-logo.png")}
          style={styles.logo}
          resizeMode="contain"
        />
      </View>

      <View style={styles.top}>
        <HamburgerDrawerButton navigation={navigation} />
      </View>
      {children}
    </>
  );
}

function Profile() {
  const user = useSelector(selectUser);
  return (
    <View className="mt-lg">
      <View style={styles.profileCard}>
        <Image
          source={require("../../../assets/images/polar-bear.png")}
          style={styles.avatar}
        />
        <Text className="h3 mt-xl align-center bold" style={styles.profileText}>
          {user.firstName}
        </Text>
        {/* <Text className="align-center" style={styles.profileText}>
          7th Grade
        </Text> */}
      </View>
    </View>
  );
}
