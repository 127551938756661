import { StyleSheet } from "react-native";
import { View, Text } from "react-native-picasso";
import React from "react";
import {
  NumberInputsAnswer,
  NumberInputsQuestion,
  QuizItem,
} from "../../types/quiz.types";
import NumericInput from "../../components/NumericInput/numeric-input.component";

export type QuizNumberInputsProps = {
  currentQuizQuestion: QuizItem<NumberInputsQuestion>;
  setAnswer: (updatedAnswer: NumberInputsAnswer) => void;
  maxLimit: number;
};

export default function QuizNumberInputs({
  currentQuizQuestion,
  setAnswer,
  maxLimit
}: QuizNumberInputsProps) {
  const onValueChanged = (inputIndex: number, newValue: number) => {
    const inputs = currentQuizQuestion.inputs;
    inputs[inputIndex].value = newValue;
    setAnswer({ ...currentQuizQuestion, inputs });
  };

  return (
    <View style={styles.container}>
      {currentQuizQuestion.inputs.map((numberInput, index) => (
        <View key={index} style={styles.input}>
          <Text className="color-white bold mb-sm">{numberInput.label}</Text>
          <NumericInput
            value={numberInput.value || 0}
            onValueChanged={(val: number) => onValueChanged(index, val)}
            maxLimit={maxLimit}
          />
        </View>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    alignContent: "flex-start",
    justifyContent: "space-between",
    width: "95%",
  },
  input: {
    flexBasis: "45%",
    marginBottom: 30,
  },
});
