import { funFacts } from "./fun-facts";
import {
  QuizItem,
  QuestionCategory,
  QuizQuestion,
} from "../../src/types/quiz.types";

// Edit this to add or remove quiz questions
export const quizData: ReadonlyArray<QuizItem<QuizQuestion>> = [
  {
    question: "How many flights did you take in the past year?",
    key: "flights",
    type: "NumberInputs",
    category: QuestionCategory.Transport,
    funFact: funFacts.farmAnimals, //TODO:
    inputs: [
      {
        label: "Short flights (<2h)",
        key: "short-flights",
        averageValue: 0, //TODO: average
      },
      {
        label: "Medium flights (<6h)",
        key: "medium-flights",
        averageValue: 0, //TODO: average
      },
      {
        label: "Long flights (<10h)",
        key: "long-flights",
        averageValue: 0, //TODO: average
      },
      {
        label: "Flights over 10h",
        key: "long-hr-flights",
        averageValue: 1, //TODO: average
      },
    ],
    max: 20,
  },
  {
    question:
      "How many trips (one way) did you take by ferry in the past year?",
    key: "ferries",
    type: "NumberInputs",
    category: QuestionCategory.Transport,
    funFact: funFacts.foodProduction, //TODO:
    inputs: [
      {
        label: "To/From Vancouver",
        key: "vancouver",
        averageValue: 10, //TODO: average
      },
      {
        label: "To/From Gulf Islands",
        key: "gulf-island",
        averageValue: 4, //TODO: average
      },
      {
        label: "To/From Salt Spring",
        key: "salt-spring",
        averageValue: 4, //TODO: average
      },
      {
        label: "To/From Seattle",
        key: "seattle",
        averageValue: 10, //TODO: average
      },
    ],
  },
  {
    question: "About how much do you ride a motorcycle weekly?",
    key: "motorcycle",
    groupKey: "motor-vehicles",
    type: "Scale",
    category: QuestionCategory.Transport,
    funFact: funFacts.treeMass, //TODO:
    unit: "hours",
    scaleValueSuffix: "h",
    max: 50,
    averageValue: 0, //TODO: average
  },
  {
    question: "About how many hours do you travel by car weekly?",
    key: "car",
    groupKey: "motor-vehicles",
    type: "Scale",
    category: QuestionCategory.Transport,
    funFact: funFacts.cups, //TODO:
    unit: "hours",
    scaleValueSuffix: "h",
    max: 50,
    averageValue: 8, //TODO: average
  },
  {
    question: "About how many trips (one way) do you take by bus weekly?",
    key: "bus",
    groupKey: "motor-vehicles",
    type: "Scale",
    category: QuestionCategory.Transport,
    funFact: funFacts.plastic, //TODO:
    unit: "trips",
    scaleValueSuffix: "",
    max: 40,
    averageValue: 1.5, //TODO: average
  },
  {
    question: "Please select the foods that you eat regularly.",
    key: "eat",
    type: "MultiSelect",
    category: QuestionCategory.Food,
    funFact: funFacts.humanCausedMyth, //TODO:
    choices: [
      {
        label: "Beef",
        key: "beef",
        defaultSelected: false,
        // TODO: average?
        averageSelected: true,
      },
      {
        label: "Dairy",
        key: "dairy",
        defaultSelected: false,
        // TODO: average?
        averageSelected: true,
      },
      {
        label: "Seafood",
        key: "seafood",
        defaultSelected: false,
        // TODO: average?
        averageSelected: true,
      },
      {
        label: "Eggs",
        key: "eggs",
        defaultSelected: false,
        // TODO: average?
        averageSelected: true,
      },
      {
        label: "Other meat",
        key: "other-meat",
        defaultSelected: false,
        // TODO: average?
        averageSelected: true,
      },
    ],
  },
  {
    question: "Do you usually compost?",
    key: "compost",
    type: "MultiSelect",
    category: QuestionCategory.Food,
    funFact: funFacts.greenland, //TODO:
    choices: [
      {
        label: "Yes, I usually compost",
        unselectedLabel: "No, I ususally don't",
        key: "compost",
        defaultSelected: false,
        // TODO: average?
        averageSelected: true,
      },
    ],
  },
  {
    question: "Please select what you usually recycle",
    key: "recycle",
    type: "MultiSelect",
    category: QuestionCategory.Stuff,
    funFact: funFacts.availableWater, //TODO:
    choices: [
      {
        label: "Paper", // TODO: average, default?
        key: "paper",
        defaultSelected: false,
        averageSelected: true,
      },
      {
        label: "Glass", // TODO: average, default?
        key: "glass",
        defaultSelected: false,
        averageSelected: true,
      },
      {
        label: "Metal", // TODO: average, default?
        key: "metal",
        defaultSelected: false,
        averageSelected: true,
      },
      {
        label: "Electronics", // TODO: average, default?
        key: "electronics",
        defaultSelected: false,
        averageSelected: true,
      },
      {
        label: "Plastic", // TODO: average, default?
        key: "plastic",
        defaultSelected: false,
        averageSelected: true,
      },
    ],
  },
  {
    question: "About how much do you spend on second-hand stuff per year?",
    key: "second-hand",
    type: "Scale",
    category: QuestionCategory.Stuff,
    funFact: funFacts.handWashing, //TODO:
    unit: "CAD",
    scaleValuePrefix: "$",
    max: 1500,
    averageValue: 744, //TODO: average
  },
  {
    question: "In the last year, did you buy (or get as a gift)...",
    key: "buy",
    type: "MultiSelect",
    category: QuestionCategory.Stuff,
    funFact: funFacts.oxygen, //TODO:
    choices: [
      {
        label: "Electronics", // TODO: average, default?
        key: "electronics",
        defaultSelected: false,
        averageSelected: true,
      },
      {
        label: "Clothes", // TODO: average, default?
        key: "clothes",
        defaultSelected: false,
        averageSelected: true,
      },
    ],
  },
];
