import React, { useEffect, useState } from "react";
import ChallengesBody from "../Common/challenges-body.component";
import { Colors } from "../../../../theme";
import { Challenge, ChallengeProgress } from "../../../types/challenge.types";
import { getUserChallenges } from "../../../services/challenge";
import { MyChallengeItem } from "../../../components/MyChallengeItem/my-challenge-item.component";
import { FlatList } from "react-native";
import {
  Filter,
  FilterTabs,
  useFilters,
} from "../../../components/FilterTabs/filter-tabs.component";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/userSlice";
import { getUserData } from "../../../services/auth";
import Overlay from "../../../components/LoadingOverlay/overlay.component";
import { updateUserData } from "../../../redux/userSlice";
import { useAppDispatch } from "../../../redux/store";
import { useIsFocused } from "@react-navigation/native";

// TODO: these filter functions are placeholders before we store challenge status
const filters: Filter<ChallengeProgress>[] = [
  {
    title: "Planned",
    filterFn: (challenge) => challenge.status === "inrolled",
  },
  {
    title: "In Progress",
    filterFn: (challenge) => challenge.status === "inprogress",
  },
  { title: "Done", filterFn: (challenge) => challenge.status === "completed" },
];

export default function MyChallenges({ navigation, route }) {
  var user = useSelector(selectUser);

  const [challenges, setChallenges] = useState<Challenge[]>([]);
  const [challengesProgress, setChallengesProgress] = useState<
    ChallengeProgress[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const isFocused = useIsFocused();

  const filterData = useFilters(challengesProgress, filters, 1);
  const dispatch = useAppDispatch();
  ////////////////////
  // Loads only the current user's challenges
  useEffect(() => {
    const fetchData = async () => {
      const data = await getUserData(user.id);
      dispatch(updateUserData(data.data.userData));
      getUserChallenges(user.id)
        .then((challenges) => {
          setChallenges(challenges);
        })
        .finally(() => {
          setIsLoading(false);
        });

      setChallengesProgress(data.data.userData.inrolledchallenges);
    };

    // call the function
    isFocused &&
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, [isFocused]);
  ////////////////////

  const navigateToDetails = (
    challenge: Challenge,
    challengeProgress: ChallengeProgress
  ) => {
    navigation.navigate("ChallengeDetails", {challenge: challenge, challengeProgress: challengeProgress });
  };

  const renderItem = ({ item }: { item: ChallengeProgress }) =>
    challenges[0] && (
      <MyChallengeItem
        key={item.challengeCode}
        challenge={challenges.find(
          (c) => c.challengeCode === item.challengeCode
        )} //TODO: make more efficient
        challengeProgress={item}
        onPress={navigateToDetails}
      />
    );

  if (isLoading) return <Overlay display={true} />;
  return (
    challenges[0] && (
      <>
        <Overlay display={isLoading} />
        <FilterTabs {...filterData} />
        <ChallengesBody backgroundColor={Colors.lightGrey}>
          <FlatList
            data={
              filterData.filterResults[filterData.selectedFilterIndex]
                .filteredItems
            }
            renderItem={renderItem}
            keyExtractor={(item) => item.challengeCode}
          />
        </ChallengesBody>
      </>
    )
  );
}
