import React from "react";
import { View, Text } from "react-native-picasso";
import { stringsEng } from "../../../../assets/text/strings-english";
import OnboardingBackground from "../Common/onboarding-background.component";
import PressableImage from "../../../components/Button/pressable-image.component";
import { styles } from "./join-class.styles";
import { styles as commonStyles } from "../Common/onboarding-common.styles";
import JoinClassForm from "./join-class-form.component";

export default function JoinClass({ navigation }) {
  function back() {
    navigation.navigate("Home", { name: "Home" });
  }

  return (
    <OnboardingBackground>
      <View style={commonStyles.mainFlexContainer}>
        <Text className="color-white align-center h3">
          {stringsEng.ONBOARDING.JOIN}
        </Text>
        <Text className="color-white align-center mb-md">
          {stringsEng.ONBOARDING.JOIN_CLASS.INSTRUCTIONS}
        </Text>

        <JoinClassForm navigation={navigation} />

        <Text
          className="color-white mt-lg align-center bold mb-sm"
          style={styles.classInfoText}
        >
          {stringsEng.ONBOARDING.JOIN_CLASS.CLASS_INFO}
        </Text>
        <Text className="color-white" style={styles.byAcceptingList}>
          {stringsEng.ONBOARDING.JOIN_CLASS.BY_ACCEPTING}
          {"\n \u2022"} {stringsEng.ONBOARDING.JOIN_CLASS.BY_ACCEPTING_1}
          {"\n \u2022"} {stringsEng.ONBOARDING.JOIN_CLASS.BY_ACCEPTING_2}
          {"\n \u2022"} {stringsEng.ONBOARDING.JOIN_CLASS.BY_ACCEPTING_3}
        </Text>
      </View>
      <View style={commonStyles.backButtonFlexContainer}>
        <PressableImage
          imageSource={require("../../../../assets/back-button.png")}
          onPress={back}
          imageStyle={commonStyles.backButton}
        />
      </View>
    </OnboardingBackground>
  );
}
