import { StyleSheet } from "react-native";
import { Colors } from "../../../theme";

export const styles = StyleSheet.create({
  bg: {
    width: "100%",
    height: "100%",
    position: "absolute",
  },
  top: {
    alignItems: "flex-end",
    marginEnd: 20,
    marginTop: 30,
  },
  logoContainer: {
    ...StyleSheet.absoluteFillObject,
    alignItems: "center",
  },
  logo: {
    width: "30%",
    top: -40,
    aspectRatio: 0.7,
  },
  profileCard: {
    padding: 10,
    marginBottom: 10,
    marginTop: 20,
    marginHorizontal: 10,
    borderRadius: 12,
    backgroundColor: Colors.white,
    justifyContent: "center",
  },
  avatar: {
    width: 110,
    height: 110,
    borderRadius: 63,
    borderWidth: 4,
    borderColor: Colors.lightBlueGrey,
    marginBottom: 10,
    alignSelf: "center",
    position: "absolute",
    marginTop: -110,
    padding: 15,
  },
  profileText: {
    color: Colors.cornflowerBlue,
  },
});
