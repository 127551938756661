import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { View, Text } from "react-native-picasso";
import { stringsEng } from "../../../../assets/text/strings-english";
import CFTButton from "../../../components/Button/cft-button.component";
import { Colors } from "../../../../theme";
import CFTTextInput from "../../../components/TextInput/text-input.component";
import { styles } from "./forgot-password.styles";
import resolver from "./submit-code-resolver";
import { ActivityIndicator, ScrollView } from "react-native";
import { errorStyle } from "../Common/error-styles";
import { resetPassword } from "../../../services/auth";
import Overlay from "../../../components/LoadingOverlay/overlay.component";

export type FormData = {
  code: string;
  newPassword: string;
  confirmNewPassword: string;
  validCode: boolean;
};

export default function SubmitCodeForm({ navigation, email }) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      code: "",
      newPassword: "",
      confirmNewPassword: "",
      validCode: true
    },
    resolver,
  });

  const [loader, setLoader] = useState(false);
  const [validCode, setCode] = useState(false);

  const submit = handleSubmit(async (data) => {
    setLoader(true);
    const response = await resetPassword(data.newPassword, data.code, email);
    if (response) {
      setTimeout(() => {
        setLoader(false);
        navigation.navigate("Login", { name: "Login" });
      }, 500);
    } else {
      setTimeout(() => {
        setLoader(false);
        control._formState.errors.validCode = {

          type: 'required',
          message: stringsEng.ONBOARDING.FORGOT_PASSWORD_CODE.ERRORS.INVALID_CODE,

        }
        setCode((validCode) => !validCode);
      }, 500);
    }
  });

  if (loader)
    return (
      <View style={{ flex: 1, justifyContent: "center", padding: 10 }}>
        <ActivityIndicator size={40} />
      </View>
    );

  return (
    <>
      <Controller
        name="code"
        control={control}
        rules={{ required: true }}
        render={({ field: { onBlur, onChange, value } }) => (
          <CFTTextInput
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            placeholderTextColor="white"
            autoCorrect={false}
            placeholder={stringsEng.ONBOARDING.FORGOT_PASSWORD_CODE.EMAIL}
            iconSource={require("../../../../assets/images/icons/icon_lock.png")}
            iconColor={Colors.white}
          />
        )}
      />
      <Controller
        name="newPassword"
        control={control}
        rules={{ required: true }}
        render={({ field: { onBlur, onChange, value } }) => (
          <CFTTextInput
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            placeholderTextColor="white"
            autoCorrect={false}
            placeholder={stringsEng.ONBOARDING.FORGOT_PASSWORD_CODE.PASSWORD}
            iconSource={require("../../../../assets/images/icons/icon_key.png")}
            iconColor={Colors.white}
            secureTextEntry={true}
          />
        )}
      />
      <Controller
        name="confirmNewPassword"
        control={control}
        rules={{ required: true }}
        render={({ field: { onBlur, onChange, value } }) => (
          <CFTTextInput
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            placeholderTextColor="white"
            autoCorrect={false}
            placeholder={
              stringsEng.ONBOARDING.FORGOT_PASSWORD_CODE.CONFIRM_PASSWORD
            }
            iconSource={require("../../../../assets/images/icons/icon_key.png")}
            iconColor={Colors.white}
            secureTextEntry={true}
          />
        )}
      />
      <View>
        <CFTButton
          text={stringsEng.ONBOARDING.FORGOT_PASSWORD_CODE.RESET}
          buttonStyle={styles.passwordSubmitButton}
          buttonSize="lg"
          iconSource={require("../../../../assets/images/icons/icon_lock.png")}
          onPress={submit}
          textColor={Colors.iceBlue}
        />
        <View style={{ height: 15 }}>
          <ScrollView>
            {errors.newPassword && (
              <Text style={errorStyle.error}>{errors.newPassword.message}</Text>
            )}
            {errors.confirmNewPassword && (
              <Text style={errorStyle.error}>
                {errors.confirmNewPassword.message}
              </Text>
            )}
            {errors.confirmNewPassword && (
              <Text style={errorStyle.error}>
                {errors.confirmNewPassword.message}
              </Text>
            )}
            {errors.code && (
              <Text style={errorStyle.error}>{errors.code.message}</Text>
            )}
            {errors.validCode && <Text style={errorStyle.error}>{errors.validCode.message}</Text>}
          </ScrollView>
        </View>
      </View>
    </>
  );
}
