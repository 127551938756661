import { Colors } from "../../../theme";
import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    overflow: "hidden",
  },
  backArrowButton: {
    alignSelf: "flex-start",
    flexGrow: 0,
    backgroundColor: "transparent",
    margin: 20,
    marginLeft: 10
  },
  content: {
    flex: 1,
    justifyContent: "space-between",
    marginHorizontal: 20,
  },
  separator: {
    borderBottomColor: "#ffffff33",
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  itemIcon: { width: 38, height: 38 },
  drawerCategory: {
    color: Colors.white,
  },
  account: {
    marginBottom: 20
  },
  other: {

  },
  categories: {
    flex: 1
  },
});
