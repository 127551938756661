import React from "react";
import { ColorValue, View, StyleSheet } from "react-native";

type Props = {
  // Number from 0 to 1. If outside this range, the value is clamped to 0 or 1
  progress: number;
  fillColor: ColorValue;
};
export default function ProgressBar({ progress, fillColor }: Props) {
  const clampedProgress = Math.max(0, Math.min(1, progress));

  return (
    <View style={styles.container}>
      <View
        style={[
          styles.backgroundColorContainer,
          { backgroundColor: fillColor },
        ]}
      >
        <View style={styles.backgroundColorOverlay} />
      </View>

      <View
        style={[
          styles.fill,
          {
            width: clampedProgress * 100 + "%",
            backgroundColor: fillColor,
          },
        ]}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: 8,
  },
  backgroundColorContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    borderRadius: 25,
  },
  backgroundColorOverlay: {
    height: "100%",
    backgroundColor: "#fffa",
  },
  fill: {
    borderRadius: 25,
    height: "100%",
  },
});
