import { Colors } from "../../../theme";
import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  label: {
    alignSelf: "flex-end",
    borderRadius: 20,
    backgroundColor: Colors.lightBlueGrey,
    width: "40%",
    padding: 5,
  },
  // The label above the slider, showing its value
  labelText: {
    color: Colors.darkGrey,
  },
  sliderContainer: {
    marginLeft: 10,
    marginRight: 25,
  },
  sliderMarker: {
    width: 30,
    height: 30,
    borderRadius: 50,
    backgroundColor: Colors.white,
    borderColor: Colors.white,
  },
  sliderTrack: {
    backgroundColor: Colors.purple,
    height: 3,
  },
  selectedSliderTrack: {
    backgroundColor: Colors.white,
  },
});
