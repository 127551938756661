import { Text, View } from "react-native";
import React, { ComponentPropsWithoutRef } from "react";
import { Colors } from "../../../theme";
import { AnimatedCircularProgress } from "react-native-circular-progress";

// Circular progress component with percentage completion displayed in the
// center. Accepts these listed props as well as props for the
// AnimatedCircularProgress component
type Props = {
  currentValue: number;
  targetValue: number;
  unit?: string; // Optional unit to display after the ratio, e.g. "pts" in "4/30 pts"
  hideRatio?: boolean;
} & Omit<ComponentPropsWithoutRef<typeof AnimatedCircularProgress>, "fill">;
export function PercentProgressCircle({
  currentValue,
  targetValue,
  unit,
  hideRatio = false,
  ...progressProps
}: Props) {
  const completedPercentage = (currentValue / targetValue) * 100;

  const ratioText = currentValue + "/" + targetValue + (unit ? " " + unit : "");

  return (
    <AnimatedCircularProgress
      fill={completedPercentage} // out of 100
      {...progressProps}
    >
      {(fill) => (
        <View style={{ justifyContent: "center", alignItems: "center" }}>
          <Text style={{ fontWeight: "bold" }}>
            {completedPercentage.toFixed(0)}%
          </Text>
          {hideRatio ? null : (
            <Text style={{ color: Colors.mediumGrey, fontSize: 8 }}>
              {ratioText}
            </Text>
          )}
        </View>
      )}
    </AnimatedCircularProgress>
  );
}
