import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  logoContainer: {
    ...StyleSheet.absoluteFillObject,
    alignItems: "center",
    paddingBottom: 15,
    position: "relative",
    height: "10%"
   
  },
  logo: {
    width: "30%",
    top: -40,
    aspectRatio: 0.7,
  },
});
