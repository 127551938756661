import React, { useContext } from "react";
import { Image, ImageSourcePropType, Modal } from "react-native";
import { Text, View } from "react-native-picasso";
import { Category } from "../../../../assets/text/categories";
import { stringsEng } from "../../../../assets/text/strings-english";
import CFTButton from "../../../components/Button/cft-button.component";
import { PageSizeContext } from "../../../contexts/page-size-context";
import { styles } from "./quiz-results-breakdown.styles";

export type CategoryData = {
  imageSource: ImageSourcePropType;
  category: string;
  text: string;
};

export type CategoryPopUpProps = {
  onClose: Function;
  categoryIndex: number;
  isOpen: boolean;
  categoryFootprint: number;
};

export default function CategoryPopUp({
  onClose,
  categoryIndex,
  isOpen,
  categoryFootprint,
}: CategoryPopUpProps) {
  const { width } = useContext(PageSizeContext);
  return (
    <Modal animationType="fade" visible={isOpen} transparent={true}>
      <View style={[styles.modalContainer1, { width: width }]}>
        <View style={styles.modalContainer2}>
          <Image
            source={Category[categoryIndex].imageSource}
            style={styles.modalImage}
            resizeMode="contain"
          />
          <Text className="bold align-center">
            {Category[categoryIndex].category} {"\n"}
            {categoryFootprint}{" "}
            {stringsEng.QUIZ_RESULT.QUIZ_RESULTS_2.CATEGORIES.TONNES_CO2}
          </Text>
          <Text className="align-center">{Category[categoryIndex].text}</Text>
          <View>
            <CFTButton
              text={"OK!"}
              onPress={() => onClose()}
              buttonSize={30}
              buttonStyle={styles.closeButton}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
}
