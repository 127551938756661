import React from "react";
import { View, Text } from "react-native-picasso";
import { stringsEng } from "../../../../assets/text/strings-english";
import OnboardingBackground from "../Common/onboarding-background.component";
import PressableImage from "../../../components/Button/pressable-image.component";
import { styles } from "./forgot-password.styles";
import { styles as commonStyles } from "../Common/onboarding-common.styles";
import ForgotPasswordForm from "./forgot-password-form.component";

export default function ForgotPassword({ navigation }) {
  function back() {
    navigation.navigate("Login", { name: "Login" });
  }

  return (
    <OnboardingBackground>
      <View style={commonStyles.mainFlexContainer}>
        <Text className="color-white align-center h4 mb-sm">
          {stringsEng.ONBOARDING.FORGOT_PASSWORD.HEADING}
        </Text>
        <Text
          className="color-white align-center mb-lg"
          style={styles.promptText}
        >
          {stringsEng.ONBOARDING.FORGOT_PASSWORD.PROMPT}
        </Text>

        <ForgotPasswordForm navigation={navigation} />
      </View>
      <View style={commonStyles.backButtonFlexContainer}>
        <PressableImage
          imageSource={require("../../../../assets/back-button.png")}
          onPress={back}
          imageStyle={commonStyles.backButton}
        />
      </View>
    </OnboardingBackground>
  );
}
