import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ActivityIndicator, Modal, Pressable } from "react-native";
import { View, Text } from "react-native-picasso";
import { stringsEng } from "../../../../assets/text/strings-english";
import CFTButton from "../../../components/Button/cft-button.component";
import { Colors } from "../../../../theme";
import CFTTextInput from "../../../components/TextInput/text-input.component";
import resolver from "./editAssignment.resolver";
import { styles } from "./editAssignment.styles";
import { errorStyle } from "../../Onboarding/Common/error-styles";
import TeacherService from "../../../services/teacher.service";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/userSlice";
import { modalStyles } from "../../../components/Modal/modal.styles";
import Emoji from "../../../components/Emoji/emoji.component";

export type EditAssignmentFormData = {
  assignmentName: string;
  dueDate: string; //Must be converted to date later
  level: string;
  pointsGoal: string;
  description: string;
};

export default function EditAssignmentForm({ preExistingData }) {

  function getDateString(date) {
    date = new Date(date)
    var changedDate = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
    return changedDate;
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<EditAssignmentFormData>({
    defaultValues: {
      assignmentName: preExistingData.assignmentName,
      dueDate: getDateString(preExistingData.dueDate),
      level: preExistingData.level,
      pointsGoal: preExistingData.pointsGoal,
      description: preExistingData.description,
    },
    resolver,
  });

  const [loader, setLoader] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalText, setModalText] = useState("");
  const [modalEmoji, setModalEmoji] = useState("");

  const user = useSelector(selectUser);
  const onSubmit = handleSubmit(async (data) => {
    setLoader(true);
    try {
      const response = await TeacherService.editAssignment(data, preExistingData._id, user.id);
      if (response) {
        setTimeout(() => {
          setLoader(false);
          setModalEmoji(stringsEng.TEACHER.STUDENT_LIST.MODAL_CONFIRM +
            stringsEng.TEACHER.CREATE_ASSIGNMENT.MODAL.SUCCESS.EMOJI
          );
          setModalText(stringsEng.TEACHER.EDIT_ASSIGNMENT_FORM.MODAL_TEXT.SUCCESS);
          setModalVisible(true);
        }, 500);
      } else {
        throw new Error("Error updating assignment");
      }
    } catch (error) {
      setLoader(false);
      setModalEmoji(stringsEng.TEACHER.STUDENT_LIST.MODAL_CONFIRM + stringsEng.TEACHER.CREATE_ASSIGNMENT.MODAL.FAILURE.EMOJI);
      setModalText(stringsEng.TEACHER.EDIT_ASSIGNMENT_FORM.MODAL_TEXT.FAILURE);
      setModalVisible(true);
    }
  });

  return (
    <>
      <Controller
        name="assignmentName"
        control={control}
        rules={{ required: true }}
        render={({ field: { onBlur, onChange, value } }) => (
          <CFTTextInput
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            placeholderTextColor={Colors.white}
            autoCorrect={false}
            placeholder={stringsEng.TEACHER.CREATE_ASSIGNMENT.NAME}
            iconColor={Colors.white}
          />
        )}
      />
      <Controller
        name="dueDate"
        control={control}
        rules={{ required: true }}
        render={({ field: { onBlur, onChange, value } }) => (
          <CFTTextInput
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            placeholderTextColor={Colors.white}
            autoCorrect={false}
            placeholder={stringsEng.TEACHER.CREATE_ASSIGNMENT.DUE_DATE}
            iconColor={Colors.white}
          />
        )}
      />
      <Controller
        name="level"
        control={control}
        rules={{ required: true }}
        render={({ field: { onBlur, onChange, value } }) => (
          <CFTTextInput
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            placeholderTextColor={Colors.white}
            autoCorrect={false}
            placeholder={stringsEng.TEACHER.CREATE_ASSIGNMENT.LEVEL}
            iconColor={Colors.white}
          />
        )}
      />

      <Controller
        name="pointsGoal"
        control={control}
        rules={{ required: true }}
        render={({ field: { onBlur, onChange, value } }) => (
          <CFTTextInput
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            placeholderTextColor={Colors.white}
            autoCorrect={false}
            placeholder={stringsEng.TEACHER.CREATE_ASSIGNMENT.POINTS}
            iconColor={Colors.white}
          />
        )}
      />

      <Controller
        name="description"
        control={control}
        rules={{ required: true }}
        render={({ field: { onBlur, onChange, value } }) => (
          <CFTTextInput
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            placeholderTextColor={Colors.white}
            autoCorrect={false}
            placeholder={stringsEng.TEACHER.CREATE_ASSIGNMENT.DESCRIPTION}
            iconColor={Colors.white}
          />
        )}
      />
      <View style={{ height: 15 }}>
        {errors.assignmentName && (
          <Text style={errorStyle.error}>{errors.assignmentName.message}</Text>
        )}
        {errors.dueDate && (
          <Text style={errorStyle.error}>{errors.dueDate.message}</Text>
        )}
        {errors.level && (
          <Text style={errorStyle.error}>{errors.level.message}</Text>
        )}
        {errors.pointsGoal && (
          <Text style={errorStyle.error}>{errors.pointsGoal.message}</Text>
        )}
        {errors.description && (
          <Text style={errorStyle.error}>{errors.description.message}</Text>
        )}
      </View>
      {loader ? (
        <View style={{ flex: 1, justifyContent: "center", padding: 10 }}>
          <ActivityIndicator size={40} />
        </View>
      ) : (
        <View>
          <CFTButton
            text={stringsEng.TEACHER.EDIT_ASSIGNMENT.SUBMIT}
            buttonStyle={styles.submitButton}
            buttonSize="md"
            onPress={onSubmit}
            textColor={Colors.iceBlue}
          />
        </View>
      )}
      <View style={{ flex: 1, marginLeft: 40 }}>
        <Modal
          animationType="slide"
          transparent={true}
          visible={modalVisible}
          onRequestClose={() => {
            setModalVisible(!modalVisible);
          }}
        >
          <View style={modalStyles.centeredView}>
            <View style={modalStyles.modalView}>
              <Text style={modalStyles.modalText}>{modalText}</Text>
              <Pressable
                style={[modalStyles.button, modalStyles.buttonClose]}
                onPress={() => {
                  setModalVisible(!modalVisible);
                }}
              >
                <Emoji symbol={modalEmoji} />
              </Pressable>
            </View>
          </View>
        </Modal>
      </View>
    </>
  );
}
