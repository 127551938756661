import React from "react";
import { Text, View } from "react-native-picasso";
import { EquivalentData } from "./quiz-results-equivalents.page";
import { styles } from "./quiz-results-equivalents.styles";
import InsetShadow from "react-native-inset-shadow";

export type ExplanationBoxProps = EquivalentData & {
  footprint: number;
};

export default function EquivalentExplanation({
  bgColour,
  footprint,
  ratio,
  text1,
  text2,
}: ExplanationBoxProps) {
  let formattedResult = (ratio * footprint).toLocaleString("en-US");

  return (
    <View style={{ backgroundColor: bgColour }}>
      <InsetShadow shadowOpacity={0.5} shadowRadius={15}>
        <View style={styles.equivalentExplanationTextContainer}>
          <Text className="color-white bold align-center">
            {footprint} {text1} {formattedResult} {text2}
          </Text>
        </View>
      </InsetShadow>
    </View>
  );
}
