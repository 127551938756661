import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { User } from "./../types/user.types";

const initialState = {
  token: "",
  id: "",
  username: "",
  firstName: "",
  lastName: "",
  email: "",
  userType: "",
  userData: {
    inrolledchallenges: [],
    class: [],
    footprint: null,
    quizAttempted: false,
    quizData: null,
    challengesCompleted: 0,
    assignmentCompleted: 0,
    streak: 0,
    climaPointsEarned: 0,
    carbonImpact: 0,
    user: "",
    challengesStat: null,
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login(state, action: PayloadAction<User>) {
      return action.payload;
    },
    logout(state, action: PayloadAction) {
      return initialState;
    },
    updateUserData(state, action: PayloadAction<User>) {
      return {
        ...state,
        userData: action.payload,
      };
    },
    storeTeacherData(state, action: PayloadAction) {
      return {
        ...state,
        teacherClassData: action.payload,
      };
    },
  },
});

// Thunks go here

// Selectors
export const selectUser = (state: RootState) => state.user;

// Actions & reducer exports
export const { login, logout, updateUserData, storeTeacherData } = userSlice.actions;
export default userSlice.reducer;
