import React from "react";
import { ColorValue } from "react-native";
import { View, Text } from "react-native-picasso";
import { styles } from "./quiz-results-averages.styles";

type AverageBarProps = {
  bgColor: ColorValue;
  tonnesValue: number;
  averageText: string;
  displayKGs?: Boolean;
  maxFootprint: number;
};

// Min/max bar sizes (percentages) for adjusting the bar width based on averages
const minWidth = 55;
const maxWidth = 100;
const minFootprint = 4;
const units = "KG";
export default function AverageBar({
  bgColor,
  tonnesValue,
  averageText,
  maxFootprint //decided based on context
}: AverageBarProps) {

  // Calculate width of bar, converting the tonnesValue from the range
  // minFootprint to maxFootprint, to the range minWidth to maxWidth
  //https://stackoverflow.com/a/929104
  let barWidth =
    ((tonnesValue - minFootprint) / (maxFootprint - minFootprint)) *
    (maxWidth - minWidth) +
    minWidth;

  if (barWidth > 100) {
    barWidth = 100;
  }

  return (
    <View
      style={[
        styles.averageBar,
        { backgroundColor: bgColor, width: barWidth - 5 + "%" },
      ]}
    >
      <Text
        className="color-white size-xs"
        style={{ textTransform: "uppercase", textAlign: "right", paddingRight: 5 }}
      >
        {Math.round(tonnesValue)} {units} {"\u2022"} {averageText}
      </Text>
    </View>
  );
}
