import { CreateAccountFormData } from "../pages/Onboarding/CreateAccount/create-account-form.component";
import { LoginFormData } from "../pages/Onboarding/Login/login-form.component";
import { api } from "./api";
import { UserData } from "./../types/user.types";

type UserRes = {
  token: string;
  payload: {
    id: string;
    firstName: string;
    lastName: string;
    username: string;
    email: string;
    userType: string;
  };
  userData: UserData;
};

// Register a new user account. Resolves with a success boolean
export const registerUser = async (
  formData: CreateAccountFormData,
  classCode: String
) => {
  return api
    .post<UserRes>(`/users/register`, {
      formData: formData,
      classCode: classCode,
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

// Register a new Teacher user account. Resolves with a success boolean
export const registerTeacher = async (
  formData: CreateAccountFormData,
  schoolCode: String
) => {
  return api
    .post<UserRes>(`/users/registerTeacher`, {
      formData: formData,
      schoolCode: schoolCode,
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const loginUser = async (
  formData: LoginFormData
): Promise<{
  token: string;
  id: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  userType: string;
  userData: UserData;
} | null> => {
  const userData = true;
  return api
    .post<UserRes>(`/users/login?userData=${userData}`, formData)
    .then((res) => {
      const {
        token,
        payload: { id, firstName, lastName, username, email, userType },
        userData,
      } = res.data;
      return {
        token,
        id,
        firstName,
        lastName,
        username,
        email,
        userData,
        userType,
      };
    })
    .catch((err) => {
      console.log("Could not login user. " + err);
      return null;
    });
};

export const getUserData = async (userId: string) => {
  return api
    .get(`/user/${userId}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const forgotPassword = (email: string) => {
  return api
    .post(`/users/forgot`, { email })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return false;
    });
};

export const resetPassword = (
  password: string,
  code: string,
  email: string
) => {
  return api
    .post(`/users/reset/${code}`, { password, email })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return false;
    });
};
