import { StyleSheet } from "react-native";
import { Colors } from "../../../../theme";

export const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    height: 75,
    justifyContent: "space-around"
  },
  iconText: {
    fontFamily: "Poppins",
    textAlign: "center",
    marginTop: -10,
    fontSize: 13
  },
  tab: {
    flexDirection: "column",
    padding: 10
    
  },
  tabImage: {
    width: 45,
    height: 45,
    margin: "auto",
  },
});
