import { stringsEng } from "./strings-english";
import { Challenge } from "../../src/types/challenge.types";

// These are the messages that are shown on the challenge details page, that
// change depending on how many times you've completed the challenge

// A message can be made up of multiple parts, each part having optional
// properties (e.g. bold or not). You can access information about the current
// challenge and the number of times it's been completed by passing a
// string-returning function instead of just a string.
type ChallengeInfo = { challenge: Challenge; numberCompleted: number };

type ChallengeDetailsMessagePart = {
  text: string | (({ challenge, numberCompleted }: ChallengeInfo) => string);
  bold?: boolean;
};
type ChallengeDetailsMessages = {
  [key: number]: ChallengeDetailsMessagePart[];
};
// A default message for when there's not a specific message for that number
export const defaultMessage: ChallengeDetailsMessagePart[] = [
  { text: "Have you completed this challenge today?" },
];

// The key is the number of times this challenge has been completed and the
// value is the message.
export const challengeDetailsMessages: ChallengeDetailsMessages = {
  0: [
    { text: "Do this action 3 times to save" },
    {
      text: ({ challenge }) =>
        `${challenge.carbonImpact} ${
          stringsEng.COMMON.CARBON_UNIT
        }`,
      bold: true,
    },
    { text: "of emissions" },
  ],
};
