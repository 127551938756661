import { StyleSheet } from "react-native";
import { Colors } from "../../../../theme";

export const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: "row",
    borderRadius: 8,
    marginVertical: 10,
    backgroundColor: Colors.white,
    overflow: "hidden",
  },
  itemLeftContainer: {
    padding: 20,
    alignItems: "center",
    justifyContent: "center",
  },
  itemPlusIcon: {
    width: 40,
    height: 40,
    tintColor: Colors.white,
  },
  itemCenterContainer: {
    flex: 1,
    padding: 10,
    justifyContent: "space-between",
  },
  itemRightContainer: {
    backgroundColor: Colors.whiteSmoke,
    paddingHorizontal: 15,
    paddingVertical: 10,
  },
  itemRightTop: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  itemRightBottom: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  difficultyImage: { width: 50, height: 50, margin: -10 },
});
