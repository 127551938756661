import { StyleSheet } from "react-native";
import { Colors } from "../../../../theme";

export const styles = StyleSheet.create({
  buttonContainer: {
    width: "100%",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonRow: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "70%",
    padding: 10,
    marginHorizontal: 100,
  },

  //category-button
  categoryButton: {
    height: "100%",
    aspectRatio: 1,
    borderRadius: 3,
    marginHorizontal: 10,
  },
  categoryButtonImageContainer: {
    padding: 10,
    paddingBottom: 0,
  },
  categoryButtonImage: {
    marginLeft: "auto",
    marginRight: "auto",
    height: "80%",
    width: "80%",
    resizeMode: "center",
    aspectRatio: 1,
  },

  //category-pop-up component
  modalContainer1: {
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  modalContainer2: {
    backgroundColor: Colors.white,
    width: "80%",
    height: 400,
    alignSelf: "center",
    borderRadius: 8,
    justifyContent: "space-evenly",
    padding: 20,
  },
  closeButton: {
    backgroundColor: Colors.red,
    borderRadius: 8,
    width: "60%",
    padding: 10,
    alignSelf: "center",
    marginTop: 20,
  },
  modalImage: {
    width: 80,
    height: 80,
    alignSelf: "center",
  },
});
