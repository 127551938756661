import { StyleSheet } from "react-native";
import { Colors } from "../../../../theme";

export const styles = StyleSheet.create({
  promptText: {
    fontSize: 12,
  },
  passwordSubmitButton: {
    backgroundColor: Colors.white,
    margin: 0,
    marginTop: 10,
  },
});
