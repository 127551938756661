import TextPage from "./Common/text-page.component"
import { stringsEng } from "../../../assets/text/strings-english";


export default function Terms({ navigation }) {
    return (
        <>
            <TextPage
                navigation={navigation}
                headerA={stringsEng.TERMS.TERMS}
                headerB={stringsEng.TERMS.PRIV_POLICY}
                bodyA={stringsEng.TERMS.INFO_TERMS}
                bodyB={stringsEng.TERMS.INFO_PRIV_POLICY}
            />
        </>
    );
}

