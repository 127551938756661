import { ActivityIndicator } from "react-native";
import { View } from "react-native-picasso";
import { styles } from "./loading-overlay.styles";
import { useContext } from "react";
import { PageSizeContext } from "../../contexts/page-size-context";
import { Colors } from "../../../theme";

export default function LoadingCircle({style}) {

    return (
        <ActivityIndicator size="large" color={Colors.navyBlue} style={[styles.spinner, style]} />
    );
}