import { Resolver } from "react-hook-form";
import { EditAssignmentFormData } from "./editAssignmentForm.component";
import { stringsEng } from "../../../../assets/text/strings-english";

const resolver: Resolver<EditAssignmentFormData> = async (values) => {
  return {
    values: values.assignmentName
      ? values
      : values.dueDate
      ? values
      : values.level
      ? values
      : values.pointsGoal
      ? values
      : values.description
      ? values
      : {},
    errors:  new Date(values.dueDate).toString() === "Invalid Date"
      ? {
          dueDate: {
            type: "invalid",
            message: stringsEng.TEACHER.CREATE_ASSIGNMENT.ERRORS.INVALID_DUE_DATE,
          },
        }
        : (values.level !== "easy" && values.level !== "medium" && values.level !== "hard")
      ? {
          level: {
            type: "invalid",
            message: stringsEng.TEACHER.CREATE_ASSIGNMENT.ERRORS.INVALID_LEVEL,
          },
        }
        : parseInt(values.pointsGoal) <= 0
      ? {
          pointsGoal: {
            type: "invalid",
            message: stringsEng.TEACHER.CREATE_ASSIGNMENT.ERRORS.INVALID_POINTS,
          },
        }
      : {},
  };
};

export default resolver;
