import React, { useEffect } from "react";
import { ActivityIndicator, ImageBackground } from "react-native";
import { View } from "react-native-picasso";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/userSlice";
import { sendQuizResults } from "../../services/quiz";
import { QuizAnswers } from "../../types/quiz.types";

export default function LoadingQuizResults({ navigation, route }) {
  const { id } = useSelector(selectUser);

  useEffect(() => {
    // On page load, send quiz data to server to be calculated
    let quizResults: QuizAnswers = route.params.quizResults;
    if (!quizResults) return;

    // Send the quiz results to the server to be calculated, then navigate to
    // the equivalents results page. Stay on this loading page for at least 1
    // second (so the page doesn't just flash) or as long as the calculations
    // take, whichever is longer.
    Promise.all([
      sendQuizResults(id, quizResults),
      new Promise((res) => setTimeout(res, 1000)),
    ]).then(([quizResults]) => {
      // TODO: make sure there's a quiz results value before navigating
      navigation.navigate("QuizPages", {screen: "QuizResultsEquivalents", params: { quizResults }});
    });
  }, [route.params.quizResults]);

  return (
    <>
      <ImageBackground
        style={{ width: "100%", height: "100%", position: "absolute" }}
        source={require("../../../assets/gradient-bg.png")}
      />

      <View style={{ flex: 1, justifyContent: "center", padding: 10 }}>
        <ActivityIndicator size={50} />
      </View>
    </>
  );
}
