import React from "react";
import { View, Text } from "react-native-picasso";
import { stringsEng } from "../../../../assets/text/strings-english";
import OnboardingBackground from "../Common/onboarding-background.component";
import PressableImage from "../../../components/Button/pressable-image.component";
import { styles } from "./create-account.styles";
import CreateAccountForm from "./create-account-form.component";
import { ScrollView } from "react-native";

export default function CreateAccount({ navigation, route }) {
  const classCode = route.params?.classCode;
  const isTeacher = route.params?.isTeacher;
  const schoolCode = route.params?.schoolCode;

  function back() {
    if (isTeacher) {
      navigation.navigate("JoinSchool", { name: "JoinSchool" });
    } else {
      navigation.navigate("JoinClass", { name: "JoinClass" });
    }
  }

  function onSignupSuccess() {
    navigation.navigate("Login");
  }

  return (
    <View>

      <View style={[{ position: "absolute" }]}>
        <PressableImage
          imageSource={require("../../../../assets/images/icons/icon-arrow.png")}
          onPress={back}
          imageStyle={styles.backButton}
        />
      </View>
      <OnboardingBackground>
        <View style={styles.createAccountMainFlexContainer}>
          <Text className="color-white align-center h3">
            {stringsEng.ONBOARDING.JOIN}
          </Text>
          <ScrollView>
            <CreateAccountForm
              classCode={classCode}
              onSignupSuccess={onSignupSuccess}
              isTeacher={isTeacher}
              schoolCode={schoolCode}
            />
          </ScrollView>
        </View>

      </OnboardingBackground>
    </View>
  );
}
