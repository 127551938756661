import { useState } from "react";
import { ScrollView } from "react-native";
import { Text, View } from "react-native-picasso";
import { Colors } from "../../../../theme";
import GradientBackground from "../../../components/Background/gradient-background.component";
import CFTButton from "../../../components/Button/cft-button.component";
import ChallengesBody from "../../Challenges/Common/challenges-body.component";
import ChallengesBackground from "../../Challenges/Common/challenges-background.component";
import { styles } from "./text-page.styles";

export default function TextPage({ navigation, headerA, headerB, bodyA, bodyB }) {

  const [text, setText] = useState({ headerText: headerA, bodyText: bodyA });
  const [style, setStyle] = useState({ leftButton: styles.buttonActive, rightButton: styles.buttonInactive });
  const [buttonTextColor, setButtonTextColor] = useState({ leftButton: Colors.white, rightButton: Colors.deepCornflowerBlue });

  return (
    <>
      <GradientBackground />

      <View style={styles.container}>
        <ChallengesBackground navigation={navigation}>
          <ChallengesBody>
            <View style={styles.inputContainer}>
              <CFTButton
                text={headerA}
                buttonStyle={[style.leftButton, styles.button]}
                textColor={buttonTextColor.leftButton}
                buttonSize={"md"}
                onPress={() => {
                  setText({ headerText: headerA, bodyText: bodyA });
                  setStyle({ leftButton: styles.buttonActive, rightButton: styles.buttonInactive });
                  setButtonTextColor({ leftButton: Colors.white, rightButton: Colors.deepCornflowerBlue });
                }}
              />
              <CFTButton
                text={headerB}
                buttonStyle={[style.rightButton, styles.button]}
                textColor={buttonTextColor.rightButton}
                buttonSize={"md"}
                onPress={() => {
                  setText({ headerText: headerB, bodyText: bodyB });
                  setStyle({ leftButton: styles.buttonInactive, rightButton: styles.buttonActive });
                  setButtonTextColor({ leftButton: Colors.deepCornflowerBlue, rightButton: Colors.white });
                }}
              />
            </View>

            <View style={styles.textView}>
              <ScrollView>
                <Text style={styles.textHeader} className="align-left h2">
                  {text.headerText}
                </Text>
                <Text style={styles.text} className="size-md">
                  {text.bodyText}

                </Text>
              </ScrollView>
            </View>
          </ChallengesBody>
        </ChallengesBackground>
      </View>
    </>
  );
}
