import React, { useContext } from "react";
import { StyleSheet, FlatList } from "react-native";
import { Text, View } from "react-native-picasso";
import { Colors } from "../../../theme";
import { PageSizeContext } from "../../contexts/page-size-context";
import { stringsEng } from "../../../assets/text/strings-english";

export default function ProfileStat({statData}) {
  const { width } = useContext(PageSizeContext);
  const items = [
    {
      text: stringsEng.PROFILE.ASSIGNMENTS,
      value: statData.assignmentCompleted,
    },
    {
      text: stringsEng.PROFILE.CHALLENGES,
      value: statData.challengesCompleted,
    },
    {
      text: stringsEng.PROFILE.STREAK,
      value: statData.streak,
    },
  ];

  return (
    <View className="mt-sm">
      <FlatList
        data={items}
        style={[styles.gridView, { width }]}
        numColumns={3}
        keyExtractor={(item) => item.text}
        renderItem={({ item }) => (
          <View style={styles.itemContainer} className="ml-sm mr-sm">
            <Text className="h2 align-center" style={styles.text}>
              {item.value}
            </Text>
            <Text className="size-xs align-center" style={styles.text}>
              {item.text}
            </Text>
          </View>
        )}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  gridView: {
    marginTop: 10,
    flex: 1,
  },
  itemContainer: {
    borderRadius: 10,
    padding: 10,
    backgroundColor: Colors.white,
    width: "30%",
  },
  text: {
    color: Colors.cornflowerBlue,
  },
});
