import React from "react";
import { Text, View } from "react-native-picasso";
import { styles } from "./quiz-results.styles";
import PressableImage from "../../../components/Button/pressable-image.component";

export default function NextResultButton({ text, navigateToNextResult }) {
  return (
    <View style={styles.nextArrowContainer}>
      <Text
        className="align-center color-white mb-sm"
        style={styles.textNextPage}
      >
        {text}
      </Text>

      <PressableImage
        imageSource={require("../../../../assets/result-page-arrow.png")}
        onPress={() => navigateToNextResult()}
        imageStyle={styles.downArrow}
      />
    </View>
  );
}
