import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { View, Text } from "react-native-picasso";
import { stringsEng } from "../../../../assets/text/strings-english";
import CFTButton from "../../../components/Button/cft-button.component";
import { Colors } from "../../../../theme";
import CFTTextInput from "../../../components/TextInput/text-input.component";
import BouncyCheckbox from "react-native-bouncy-checkbox";
import { styles } from "./create-account.styles";
import OnboardingService from "../onboarding.service";
import resolver from "./register-resolver";
import { errorStyle } from "../Common/error-styles";
import { ScrollView } from "react-native";

export type CreateAccountFormData = {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  confirmEmail: string;
  password: string;
  confirmPassword: string;
  passwordMatch: boolean;
  emailMatch: boolean;
  uniqueUser: boolean;
};

export default function CreateAccountForm({
  classCode,
  onSignupSuccess,
  isTeacher,
  schoolCode,
}: {
  classCode: string;
  onSignupSuccess: () => void;
  isTeacher: boolean;
  schoolCode: string;
}) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateAccountFormData>({
    defaultValues: {
      firstName: "",
      lastName: "",
      username: "",
      email: "",
      confirmEmail: "",
      password: "",
      confirmPassword: "",
      passwordMatch: false,
      emailMatch: false,
      uniqueUser: true,
    },
    resolver,
  });

  const [acceptTermsChecked, setAcceptTermsChecked] = useState<boolean>(false);
  const [uniqueUser, setUser] = useState<boolean>(false);
  const [hidePass, setHidePass] = useState(true);

  const onSignUp = handleSubmit(async (data) => {
    // To do : create account and join class on signup
    if (data !== null) {
      if (!isTeacher) {
        OnboardingService.createAccountAndJoinClass(data, classCode).then(
          (res) => {
            if (res !== null) {
              onSignupSuccess();
            } else {
              control._formState.errors.uniqueUser = {
                type: "required",
                message: stringsEng.ONBOARDING.CREATE_ACCOUNT.ERRORS.EXISTS,
              };
              setUser((unique) => !unique);
            }
          }
        );
      } else {
        OnboardingService.createTeacherAccountAndJoinSchool(
          data,
          schoolCode
        ).then((res) => {
          if (res !== null) {
            onSignupSuccess();
          } else {
            control._formState.errors.uniqueUser = {
              type: "required",
              message: stringsEng.ONBOARDING.CREATE_ACCOUNT.ERRORS.EXISTS,
            };
            setUser((unique) => !unique);
          }
        });
      }
    }
  });

  return (
    <>
      <Controller
        name="firstName"
        control={control}
        rules={{ required: true }}
        render={({ field: { onBlur, onChange, value } }) => (
          <CFTTextInput
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            placeholderTextColor={Colors.white}
            autoCorrect={false}
            placeholder={stringsEng.ONBOARDING.CREATE_ACCOUNT.FIRSTNAME}
            iconSource={require("../../../../assets/images/icons/icon_person.png")}
            iconColor={Colors.white}
          />
        )}
      />

      <Controller
        name="lastName"
        control={control}
        rules={{ required: true }}
        render={({ field: { onBlur, onChange, value } }) => (
          <CFTTextInput
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            placeholderTextColor={Colors.white}
            autoCorrect={false}
            placeholder={stringsEng.ONBOARDING.CREATE_ACCOUNT.LASTNAME}
            iconSource={require("../../../../assets/images/icons/icon_person.png")}
            iconColor={Colors.white}
          />
        )}
      />

      <Controller
        name="username"
        control={control}
        rules={{ required: true }}
        render={({ field: { onBlur, onChange, value } }) => (
          <CFTTextInput
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            placeholderTextColor={Colors.white}
            autoCorrect={false}
            placeholder={stringsEng.ONBOARDING.CREATE_ACCOUNT.USERNAME}
            iconSource={require("../../../../assets/images/icons/icon_person.png")}
            iconColor={Colors.white}
          />
        )}
      />

      <Controller
        name="email"
        control={control}
        rules={{ required: true }}
        render={({ field: { onBlur, onChange, value } }) => (
          <CFTTextInput
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            placeholderTextColor={Colors.white}
            autoCorrect={false}
            placeholder={stringsEng.ONBOARDING.CREATE_ACCOUNT.EMAIL}
            iconSource={require("../../../../assets/images/icons/icon_msg.png")}
            iconColor={Colors.white}
          />
        )}
      />

      <Controller
        name="confirmEmail"
        control={control}
        rules={{ required: true }}
        render={({ field: { onBlur, onChange, value } }) => (
          <CFTTextInput
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            placeholderTextColor={Colors.white}
            autoCorrect={false}
            placeholder={stringsEng.ONBOARDING.CREATE_ACCOUNT.CONFIRM_EMAIL}
            iconSource={require("../../../../assets/images/icons/icon_msg.png")}
            iconColor={Colors.white}
          />
        )}
      />

      <Controller
        name="password"
        control={control}
        rules={{ required: true }}
        render={({ field: { onBlur, onChange, value } }) => (
          <CFTTextInput
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            placeholderTextColor={Colors.white}
            autoCorrect={false}
            placeholder={stringsEng.ONBOARDING.CREATE_ACCOUNT.PASSWORD}
            iconSource={
              hidePass
                ? require("../../../../assets/images/icons/icon_eye-slash.png")
                : require("../../../../assets/images/icons/icon_eye.png")
            }
            iconColor={Colors.white}
            secureTextEntry={hidePass ? true : false}
            onIconPress={() => setHidePass(!hidePass)}
          />
        )}
      />

      <Controller
        name="confirmPassword"
        control={control}
        rules={{ required: true }}
        render={({ field: { onBlur, onChange, value } }) => (
          <CFTTextInput
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            placeholderTextColor={Colors.white}
            autoCorrect={false}
            placeholder={stringsEng.ONBOARDING.CREATE_ACCOUNT.CONFIRM_PASSWORD}
            iconSource={require("../../../../assets/images/icons/icon_key.png")}
            iconColor={Colors.white}
            secureTextEntry={hidePass ? true : false}
          />
        )}
      />

      {/*TODO: find a way to make it more efficient*/}
      <View style={{ height: 15 }}>
        <ScrollView>
          {errors.firstName && (
            <Text style={errorStyle.error}>{errors.firstName.message}</Text>
          )}
          {errors.lastName && (
            <Text style={errorStyle.error}>{errors.lastName.message}</Text>
          )}
          {errors.username && (
            <Text style={errorStyle.error}>{errors.username.message}</Text>
          )}
          {errors.email && (
            <Text style={errorStyle.error}>{errors.email.message}</Text>
          )}
          {errors.confirmEmail && (
            <Text style={errorStyle.error}>{errors.confirmEmail.message}</Text>
          )}
          {errors.password && (
            <Text style={errorStyle.error}>{errors.password.message}</Text>
          )}
          {errors.confirmPassword && (
            <Text style={errorStyle.error}>
              {errors.confirmPassword.message}
            </Text>
          )}
          {errors.passwordMatch && (
            <Text style={errorStyle.error}>{errors.passwordMatch.message}</Text>
          )}
          {errors.emailMatch && (
            <Text style={errorStyle.error}>{errors.emailMatch.message}</Text>
          )}
          {errors.uniqueUser && (
            <Text style={errorStyle.error}>{errors.uniqueUser.message}</Text>
          )}
        </ScrollView>
      </View>

      <BouncyCheckbox
        disableBuiltInState
        isChecked={acceptTermsChecked}
        onPress={() => setAcceptTermsChecked((checked) => !checked)}
        textComponent={
          <Text className="color-white ml-sm">
            {stringsEng.ONBOARDING.CREATE_ACCOUNT.ACCEPT}
          </Text>
        }
        style={styles.checkbox}
      />

      <View>
        <CFTButton
          disabled={!acceptTermsChecked}
          text={stringsEng.ONBOARDING.CREATE_ACCOUNT.SIGN_UP}
          buttonStyle={styles.signUpButton}
          buttonSize="md"
          iconSource={require("../../../../assets/images/icons/icon_writing.png")}
          onPress={onSignUp}
          textColor={Colors.iceBlue}
        />
      </View>
    </>
  );
}
