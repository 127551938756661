import React from "react";
import { FieldValues } from "react-hook-form";
import { Image, ImageSourcePropType, ColorValue } from "react-native";
import { TextInput, TouchableOpacity, View } from "react-native-picasso";
import { styles } from "./text-input.styles";

export type FormValues<TFieldValues extends FieldValues> = TFieldValues;

export type TextInputProps = React.ComponentProps<typeof TextInput> & {
  iconSource?: ImageSourcePropType;
  iconColor?: ColorValue;
  onIconPress?: () => void;
};

export default function CFTTextInput(props: TextInputProps) {
  const { iconSource, iconColor, onIconPress, ...textInputProps } = props;
  return (
    <View style={styles.textInputContainer}>
      <TextInput
        style={styles.textInput}
        placeholderTextColor="white"
        {...textInputProps}
      />
      {iconSource ? (
        <TouchableOpacity
          onPress={onIconPress}
          disabled={!onIconPress}>
          <Image
            resizeMode="contain"
            source={iconSource}
            style={[styles.textInputIcon, (iconColor ? [{ tintColor: iconColor }] : [])]}
          />
        </TouchableOpacity>
      ) : null}
    </View>
  );
}
