import { Resolver } from "react-hook-form";
import { stringsEng } from "../../../../assets/text/strings-english";
import { FormData } from "./join-class-form.component";

const resolver: Resolver<FormData> = async (values) => {
    return {
        values: values.classCode ? values : {},
        errors: !values.classCode
            ? {
                classCode: {
                    type: 'required',
                    message: stringsEng.ONBOARDING.JOIN_CLASS.ERRORS.CLASS_CODE
                },
            } :

                {},
    };
};

export default resolver;