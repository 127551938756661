import React from "react";
import { Controller, useForm } from "react-hook-form";
import { View, Text } from "react-native-picasso";
import { stringsEng } from "../../../../assets/text/strings-english";
import CFTButton from "../../../components/Button/cft-button.component";
import { Colors } from "../../../../theme";
import CFTTextInput from "../../../components/TextInput/text-input.component";
import { styles } from "./join-school.styles";
import OnboardingService from "../onboarding.service";
import { errorStyle } from "../Common/error-styles";
import resolver from "./joinSchool-resolver";

export type FormData = {
  schoolCode: string;
  validCode: boolean;
};

export default function JoinSchoolForm({ navigation }) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: { schoolCode: "", validCode: false },
    resolver,
  });

  const submit = handleSubmit(async (data) => {
    //TODO: uncomment once backend is connected
    if (data !== null) {
      let schoolStatus = await OnboardingService.checkIfSchoolExists(
        control._formValues.schoolCode
      );
      if (schoolStatus) {
        control._formValues.validCode = true;
        navigation.navigate("CreateAccount", {
          schoolCode: control._formValues.schoolCode,
          isTeacher: true,
        });
      } else {
        control._formValues.validCode = false;
        control._formState.errors.validCode = {
          type: "required",
          message: stringsEng.ONBOARDING.JOIN_SCHOOL.ERRORS.INVALID_CODE,
        };
      }
    }
  });

  return (
    <>
      <Controller
        name="schoolCode"
        control={control}
        rules={{ required: true }}
        render={({ field: { onBlur, onChange, value } }) => (
          <CFTTextInput
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            placeholderTextColor="white"
            autoCorrect={false}
            placeholder={stringsEng.ONBOARDING.JOIN_SCHOOL.SCHOOL_ID}
            iconSource={require("../../../../assets/images/icons/icon_join.png")}
            iconColor={Colors.white}
          />
        )}
      />

      <View>
        <CFTButton
          text={stringsEng.ONBOARDING.SUBMIT}
          buttonSize="lg"
          buttonStyle={styles.classSubmitButton}
          iconSource={require("../../../../assets/next-page-arrow.png")}
          textColor={Colors.iceBlue}
          onPress={submit}
        />
      </View>

      <View style={{ height: 20 }}>
        {errors.schoolCode && (
          <Text style={errorStyle.error}>{errors.schoolCode.message}</Text>
        )}
        {errors.validCode && (
          <Text style={errorStyle.error}>{errors.validCode.message}</Text>
        )}
      </View>
    </>
  );
}
