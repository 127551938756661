import { StyleSheet } from "react-native";
import { Colors } from "../../../theme";

export const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    overflow: "hidden",
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16,
    backgroundColor: Colors.white,
    marginVertical: 5,
  },
  coloredStrip: {
    width: 10,

    // hacky way to make the line appear
    height: 500,
    position: "absolute",
  },
  contentContainer: {
    padding: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flex: 1,
    overflow: "visible",
  },
  categoryBox: {
    marginHorizontal: 10,
    backgroundColor: Colors.whiteSmoke,
    borderRadius: 16,
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
  },
  percentCompletedContainer: {
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "flex-start",
    backgroundColor: Colors.lightGrey,
    borderRadius: 5,
    paddingVertical: 2,
    paddingHorizontal: 15,
  },
});
