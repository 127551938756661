import { Text, TouchableOpacity, View } from "react-native-picasso";
import { GestureResponderEvent, Image, ScrollView } from "react-native";
import React, { useEffect, useState } from "react";
import { Colors } from "../../../../theme";
import { stringsEng } from "../../../../assets/text/strings-english";
import { styles } from "./class-item.styles";
import PressableImage from "../../Button/pressable-image.component";
import ClassService from "../../../services/class.service";


export function DropDown({ classCode, archived, setArchived }) {
    const [open, setOpen] = useState(false);
    const [hoverA, setHoverA] = useState(false)
    const [hoverB, setHoverB] = useState(false)

    function handleClick() {
        setOpen(!open)
    }

    return (
        <View style={styles.link}>
            <PressableImage onPress={() => { handleClick() }} imageStyle={styles.contextMenu}
                imageSource={require("../../../../assets/images/icons/icon-other.png")} />
            <View style={[styles.menu, open ? styles.menuopen : '']}>
                <View style={styles.list}>
                    <Text style={[styles.item, { background: hoverA ? Colors.lightGrey : Colors.white }]} onMouseEnter={() => setHoverA(true)} onMouseLeave={() => setHoverA(false)} onPress={() => { navigator.clipboard.writeText(classCode); setOpen(false) }}>Copy class code</Text>
                    <Text style={[styles.item, { background: hoverB ? Colors.lightGrey : Colors.white }]} onMouseEnter={() => setHoverB(true)} onMouseLeave={() => setHoverB(false)} onPress={async () => { await ClassService.archiveClass(classCode, null); setArchived(true)}}>Archive</Text>{/*userId set to null because it isn't used but it makes sense to include in the route path.... unsure how to solve this*/}
                </View>
            </View>
        </View>
    )

}

type ClassProps = {
    startDate: Date;
    endDate: Date;
    className: string;
    activeAssignments: number;
    numStudents: number;
    classCode: string;
    onPress: Function;
    detailsPage?: boolean;
};
export function ClassItem({
    startDate,
    endDate,
    className,
    activeAssignments,
    numStudents,
    classCode,
    onPress,
    detailsPage
}: ClassProps) {

    const [archived, setArchived] = useState(false)

    function getDateString(date) {
        var changedDate = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
        return changedDate;
    }

    if (detailsPage) {
        return (<>
            <View>
                <TouchableOpacity onPress={() => { onPress() }} style={[styles.classBox, { backgroundColor: Colors.cornflowerBlue }]}>
                    <View style={{ flexDirection: "row", justifyContent: "space-between" }}>

                        <Text style={styles.header}>{className}</Text>
                    </View>
                    <Text style={styles.subtitle}>{stringsEng.TEACHER.CLASS_ITEM.FROM}{getDateString(startDate)}{stringsEng.TEACHER.CLASS_ITEM.TO}{getDateString(endDate)}</Text>
                    <Text style={[styles.subtitle, { marginTop: 20 }]}>{numStudents}{stringsEng.TEACHER.CLASS_ITEM.STUDENTS}</Text>
                </TouchableOpacity>
            </View>
        </>)
    }

    return (
        <>
            <View style={[{ marginBottom: 15 }, { display: archived ? "none" : "flex" }]}>
                <TouchableOpacity onPress={() => { onPress() }} style={[styles.classBox, { backgroundColor: Colors.cornflowerBlue }]}>

                    <View style={{ flexDirection: "row", justifyContent: "space-between" }}>

                        <Text style={styles.header}>{className}</Text>
                        <View className="nav">
                            <DropDown classCode={classCode} archived={archived} setArchived={setArchived}/>
                        </View>
                    </View>
                    <Text style={styles.subtitle}>{stringsEng.TEACHER.CLASS_ITEM.FROM}{getDateString(startDate)}{stringsEng.TEACHER.CLASS_ITEM.TO}{getDateString(endDate)}</Text>
                    <Text style={[styles.subtitle, { marginTop: 20 }]}>{numStudents}{stringsEng.TEACHER.CLASS_ITEM.STUDENTS}</Text>
                </TouchableOpacity>
                <View style={styles.assignmentsBox}>
                    <Text style={styles.assignments}>{activeAssignments}{stringsEng.TEACHER.CLASS_ITEM.ACTIVE}</Text>
                </View>
            </View>
        </>
    );
}