import { api } from "./api";

//class for the all the api calls related to the class
export default class ClassService {
  //get the class data from the api

  // this geting class data from the server
  static getClassData = async (code: string) => {
    try {
      const res = await api.get(`/class/${code}`);
      if (res.data.classStatus) {
        return res.data;
      } else {
        console.log("Class does not exist.");
        return null;
      }
    } catch (err) {
      console.log("Could not get class data. " + err);
      return null;
    }
  };

  // this geting class data from the server
  static getClassAverage = async (code: string, userId: string) => {
    try {
      const res = await api.get(`/user/${userId}/class/${code}`);
      if (res.data) {
        return res.data;
      } else {
        console.log("Class does not exist.");
        return null;
      }
    } catch (err) {
      console.log("Could not get class average. " + err);
      return null;
    }
  };

  // this is for the join class
  // to do:  refactor the code
  static joinClass = async (code: String, userId: string) => {
    try {
      const res = await api.post(`/user/${userId}/class/${code}`);
      if (res.status === 200) {
        return res.data;
      } else {
        return null;
      }
    } catch (err) {
      console.log("Could not join class. " + err);
      return null;
    }
  };

  static archiveClass = async (code: String, userId: string) => {
    try {
      const res = await api.post(`/user/${userId}/classes/${code}/archive`);
      if (res.status === 200) {
        return res.data;
      } else {
        return null;
      }
    } catch (err) {
      console.log("Could not archive class. " + err);
      return null;
    }
  };

  static removeStudent = async (code: String, userId: string) => {
    try {
      const res = await api.delete(`/user/${userId}/class/${code}`);
      if (res.status === 200) {
        return res.data;
      } else {
        return null;
      }
    } catch (err) {
      console.log("Could not remove student. " + err);
      return null;
    }
  };
}
