import React, { ComponentProps, ComponentType } from "react";

// Use to pass extra props into an element
export function injectProps<T>(
  Component: ComponentType<T>,
  extraProps: ComponentProps<ComponentType<T>>
) {
  // These props are passed by whoever renders the component
  const Inner = (props) => {
    return <Component {...props} {...extraProps} />;
  };
  return Inner;
}
