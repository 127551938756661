import { View, TouchableOpacity } from "react-native-picasso";
import { Image, ImageSourcePropType } from "react-native";
import React from "react";
import { Colors } from "../../../../theme";
import { styles } from "./challenges-tabbar.styles";

type TabData = {
  navigationPageName: string;
  icon: ImageSourcePropType;
};
const tabBarData: TabData[] = [
  {
    navigationPageName: "ChallengesDashboard",
    icon: require("../../../../assets/images/icons/icon-home.png"),
  },
  {
    navigationPageName: "ChallengesAndAssignments",
    icon: require("../../../../assets/images/icons/icon-challenge.png"),
  },
  {
    navigationPageName: "NewChallenge",
    icon: require("../../../../assets/images/icons/icon-plus-thin.png"),
  },
  {
    navigationPageName: "Footprint",
    icon: require("../../../../assets/images/icons/icon-footprint.png"),
  },
  {
    navigationPageName: "ProfilePage",
    icon: require("../../../../assets/images/icons/icon-profile.png"),
  },
];

type TabProps = {
  focused: boolean;
  navigateTo: (dest: string) => void;
} & TabData;

function CenterTab({
  focused,
  navigateTo,
  navigationPageName,
  icon,
}: TabProps) {
  return (
    <View style={styles.tab}>
      {/* // Draws the grey circle around floating center button (but not //
      overflowing outside of the tab bar) */}
      <View style={{ overflow: "hidden" }}>
        <View style={styles.centerTabOuter} />
      </View>
      <TouchableOpacity
        style={[
          styles.centerTabInner,
          {
            backgroundColor: focused
              ? Colors.deepSkyBlue
              : Colors.cornflowerBlue,
          },
        ]}
        onPress={() => navigateTo(navigationPageName)}
      >
        <Image
          source={icon}
          resizeMode="contain"
          style={styles.centerTabImage}
        />
      </TouchableOpacity>
    </View>
  );
}

function Tab({ focused, navigateTo, navigationPageName, icon }: TabProps) {
  return (
    <View style={styles.tab}>
      <TouchableOpacity onPress={() => navigateTo(navigationPageName)}>
        <Image
          source={icon}
          resizeMode="contain"
          style={[
            styles.tabImage,
            {
              tintColor: focused ? Colors.deepSkyBlue : Colors.mediumGrey,
            },
          ]}
        />
      </TouchableOpacity>
    </View>
  );
}

export default function ChallengesTabBar({ navigation, state, descriptors }) {
  const navigateTo = (pageName: string) => {
    navigation.navigate(pageName);
  };

  return (
    <View style={styles.container}>
      <Tab
        focused={state.index === 0}
        navigateTo={navigateTo}
        {...tabBarData[0]}
      />
      <Tab
        focused={state.index === 1 || state.index === 5 || state.index === 6} //5&6 are the assignment and challenge details pages
        navigateTo={navigateTo}
        {...tabBarData[1]}
      />
      <CenterTab
        focused={state.index === 2}
        navigateTo={navigateTo}
        {...tabBarData[2]}
      />
      <Tab
        focused={state.index === 3}
        navigateTo={navigateTo}
        {...tabBarData[3]}
      />
      <Tab
        focused={state.index === 4}
        navigateTo={navigateTo}
        {...tabBarData[4]}
      />
    </View>
  );
}
