import { View, StyleSheet } from "react-native";
import React from "react";
import { Colors } from "../../../../theme";

// Grey background with rounded top corners
export default function ChallengesBody({
  children,
  backgroundColor = Colors.white,
}) {
  return <View style={[styles.content, { backgroundColor }]}>{children}</View>;
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    borderTopLeftRadius: 50,
    borderTopRightRadius: 50,
    padding: 20,
    paddingBottom: 0,
  },
});
