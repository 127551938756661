import { Text, View, Image, ColorValue, ScrollView } from "react-native";
import React, { useEffect, useState } from "react";
import { AssignmentType } from "../../../types/assignment.types";
import ChallengesBackground from "../../Challenges/Common/challenges-background.component";
import ChallengesBody from "../../Challenges/Common/challenges-body.component";
import CFTButton from "../../../components/Button/cft-button.component";
import { Colors } from "../../../../theme";
import { stringsEng } from "../../../../assets/text/strings-english";
import { CountdownTimer } from "../../../components/CountdownTimer/countdown-timer.component";
import { PercentProgressCircle } from "../../../components/PercentProgressCircle/percent-progress-circle.component";
import { styles } from "./assignment-details.styles";
import AssignmentReflection from "../AssignmentReflection/assignment-reflection.component";
import { format } from "date-fns";
import Overlay from "../../../components/LoadingOverlay/overlay.component";
import { useIsFocused } from "@react-navigation/native";
import ClassService from "../../../services/class.service";

type BoxProps = {
  mainColor: ColorValue;
  secondaryColor: ColorValue;
  titleText: string;
  subText: string;
  boxText: string;
};
function AchievementBox({
  mainColor,
  secondaryColor,
  titleText,
  subText,
  boxText,
}: BoxProps) {
  return (
    <View style={[styles.achievement, { backgroundColor: mainColor }]}>
      <View
        style={[styles.achievementBox, { backgroundColor: secondaryColor }]}
      >
        <Text style={styles.achievementBoxNumber}>{boxText}</Text>
      </View>
      <View style={styles.achievementTextContainer}>
        <Text style={{ fontWeight: "700", color: Colors.white }}>
          {titleText}
        </Text>
        <Text style={{ color: Colors.white }}>{subText}</Text>
      </View>
    </View>
  );
}

// Component that displays the achievements boxes
type Props = { assignment: AssignmentType, classAverage: any };
function Achievements({ assignment, classAverage }: Props) {

 
  return (
    <>
      <View style={styles.yourAchievementsTextContainer}>
        <Image
          resizeMode="contain"
          style={styles.medalIcon}
          source={require("../../../../assets/images/icons/icon-medal.png")}
        />
        <Text style={styles.yourAchievementsText}>
          {stringsEng.ASSIGNMENT.ASSIGNMENT_DETAILS.YOUR_ACHIEVEMENTS}
        </Text>
      </View>
      <View>
        <View style={[styles.achievement, { backgroundColor: Colors.white }]}>
          <PercentProgressCircle
            hideRatio
            currentValue={assignment.progress}
            targetValue={assignment.pointsGoal}
            width={10}
            size={70}
            rotation={0} // start angle
            tintColor={Colors.purple}
            backgroundColor={Colors.lightGrey}
            lineCap="round"
            style={{ margin: 5 }}
          />
          <View style={styles.achievementTextContainer}>
            <Text style={{ color: Colors.darkGrey }}>
              {stringsEng.ASSIGNMENT.ASSIGNMENT_DETAILS.GOAL.YOU_REACHED}{" "}
              {assignment.progress}{" "}
              {stringsEng.ASSIGNMENT.ASSIGNMENT_DETAILS.GOAL.OUT_OF}{" "}
              {assignment.pointsGoal} {stringsEng.COMMON.CLIMAPOINTS}
            </Text>
          </View>
          <Image
            resizeMode="contain"
            style={{ width: 70, height: 70 }}
            source={require("../../../../assets/images/assignment.png")}
          />
        </View>
        <AchievementBox
          mainColor={Colors.summerSkyBlue}
          secondaryColor={Colors.navyBlue}
          titleText={
            stringsEng.ASSIGNMENT.ASSIGNMENT_DETAILS.RANK.CLASSROOM_RANK
          }
          subText={`${stringsEng.ASSIGNMENT.ASSIGNMENT_DETAILS.RANK.SUBTEXT1} ${Math.round(classAverage?.percentBetter)}% ${stringsEng.ASSIGNMENT.ASSIGNMENT_DETAILS.RANK.SUBTEXT2}`}
          boxText={`#${classAverage?.rank}`}
        />
        <AchievementBox
          mainColor={Colors.cornflowerBlue}
          secondaryColor={Colors.purple}
          titleText={
            stringsEng.ASSIGNMENT.ASSIGNMENT_DETAILS.AVERAGE.AVG_PER_DAY
          }
          subText={`${stringsEng.ASSIGNMENT.ASSIGNMENT_DETAILS.AVERAGE.SUBTEXT1} ${Math.round(classAverage?.classAverage)}${stringsEng.ASSIGNMENT.ASSIGNMENT_DETAILS.AVERAGE.SUBTEXT2}`}
          boxText={`${Math.round(classAverage?.classAverage)}`}
        />
        {/* commented out until the classroom total impact is calculated*/}
        {/* {assignment.status === "completed" && (
          <AchievementBox
            mainColor={Colors.greenSmoke}
            secondaryColor={Colors.olive}
            titleText={stringsEng.COMMON.CARBON_UNIT}
            // TODO: hardcoded numbers
            subText={`${stringsEng.ASSIGNMENT.ASSIGNMENT_DETAILS.IMPACT.SUBTEXT} 300 ${stringsEng.COMMON.CARBON_UNIT}`}
            boxText="300"
          />
        )} */}
      </View>
    </>
  );
}

export default function AssignmentDetails({ navigation, route }) {
  const assignment: AssignmentType = route.params.assignment;
  const userId: string = route.params.userId;
  const classId: string = route.params.classId;

  const [loaded, setLoaded] = useState(false);
  const isFocused = useIsFocused();
  const [classAverage, setClassAverage] = useState(null);

  useEffect(() => {
    const getAssignmentsData = async () => {
      const avg = await ClassService.getClassAverage(classId, userId).finally(() => {
        setTimeout(() => {
          setLoaded(true);
          setClassAverage(avg)
        }, 1500);
      });
    }

    isFocused &&
      getAssignmentsData()
        // make sure to catch any error
        .catch(console.error);
  }, [isFocused]);

  if (!loaded) return <Overlay display={true} />;

  // TODO: i18n for the date
  const goalString =
    stringsEng.ASSIGNMENT.ASSIGNMENT_DETAILS.SUBTITLE.AT_LEAST +
    " " +
    assignment.pointsGoal +
    " " +
    stringsEng.COMMON.CLIMAPOINTS +
    " " +
    stringsEng.ASSIGNMENT.ASSIGNMENT_DETAILS.SUBTITLE.BY +
    " " +
    format(assignment.dueDate, "MMMM d");
  return (
    <ChallengesBackground navigation={navigation}>
      <ChallengesBody backgroundColor={Colors.whiteSmoke}>
        <CFTButton
          iconSource={require("../../../../assets/images/icons/icon-arrow.png")}
          onPress={() => navigation.goBack()}
          textColor={Colors.summerSkyBlue}
          buttonStyle={styles.backButton}
        />
        <ScrollView
          contentContainerStyle={{ paddingHorizontal: 10, paddingBottom: 20 }}
        >
          <Text style={styles.title}>{assignment.assignmentName}</Text>

          <Text>
            <Text style={[styles.goalText, { fontWeight: "700" }]}>
              {stringsEng.ASSIGNMENT.ASSIGNMENT_DETAILS.SUBTITLE.GOAL}{" "}
            </Text>
            <Text style={styles.goalText}>{goalString}</Text>
          </Text>
          {assignment.status !== "completed" && (
            <View style={styles.timeRemainingContainer}>
              <View style={styles.timeRemainingBox}>
                <Text style={styles.timeRemainingText}>
                  {stringsEng.ASSIGNMENT.ASSIGNMENT_ITEM.TIME_REMAINING}
                </Text>
              </View>
              <CountdownTimer large deadline={assignment.dueDate} />
            </View>
          )}

          <Achievements assignment={assignment} classAverage={classAverage}/>

          {assignment.status === "completed" && !assignment.feedbackStatus && (
            <AssignmentReflection assignment={assignment} />
          )}
        </ScrollView>
      </ChallengesBody>
    </ChallengesBackground>
  );
}
