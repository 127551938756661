import React from "react";
import { View } from "react-native-picasso";
import { stringsEng } from "../../../../assets/text/strings-english";
import GradientBackground from "../../../components/Background/gradient-background.component";
import CFTButton from "../../../components/Button/cft-button.component";
import HeaderBanner from "../../../components/HeaderBanner/header-banner.component";
import { styles } from "./teacherhome.style";
import { Colors } from "../../../../theme";
import { useAppDispatch } from "../../../redux/store";
import { logout } from "../../../redux/userSlice";

export default function TeacherHomeScreen({ navigation }) {
  const dispatch = useAppDispatch();
  // create two CFT button in the center of the screen

  // one button for "Create a class"
  // one button for "Create a Assignment"

  // when the user click on the button, it will navigate to the corresponding page

  function createClass() {
    navigation.navigate("CreateClass");
  }

  function createAssignment() {
    navigation.navigate("CreateAssignment");
  }

  return (
    <>
      <GradientBackground />
      <HeaderBanner />
      <CFTButton
        iconSource={require("../../../../assets/images/icons/icon-arrow.png")}
        onPress={() => navigation.goBack()}
        textColor={Colors.white}
        buttonStyle={styles.backButton}
      />

      <View style={styles.container}>

        <View style={styles.buttonContainer}>

          <CFTButton
            text={stringsEng.TEACHER.HOME.CREATE_CLASS}
            buttonStyle={{
              backgroundColor: Colors.limeGreen,
            }}
            onPress={createClass}
          />
          <CFTButton
            text={stringsEng.TEACHER.HOME.CREATE_ASSIGNMENT}
            buttonStyle={{
              backgroundColor: Colors.turquoise,
            }}
            onPress={createAssignment}
          />
        </View>
      </View>
    </>
  );
}
