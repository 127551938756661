import axios, { AxiosRequestConfig } from "axios";
import { store } from "../redux/store";
import { logout } from "../redux/userSlice";

const config: AxiosRequestConfig = {
  baseURL:  "https://climact-api.herokuapp.com/api/v1", //"http://localhost:3000/api/v1", 
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  //timeout: 2000,
};

// Add axios interceptors to logout user on a 401 error
const onFulfilled = (res) => res;
const onRejected = async (error) => {
  if (error.status === 401) {
    store.dispatch(logout());
  }
  return Promise.reject(error);
};

const api = axios.create(config);
api.interceptors.response.use(onFulfilled, onRejected);
api.interceptors.request.use(async (config) => {
  const token = store.getState().user.token;
  if (token) {
    config.headers.Authorization = `${token}`;
  }
  return config;
});

export { api };
