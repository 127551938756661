import { QuestionCategory } from "./quiz.types";

export enum ChallengeLevel {
  Easy = "easy",
  Medium = "medium",
  Hard = "hard",
}

export type Challenge = {
  challengeName: string;
  challengeCode: string; // Unique
  description?: string;
  level: ChallengeLevel;
  asociatedClassCode?: string;
  category: QuestionCategory;
  assumptions?: string;
  source?: string;
  climaPoints: number;
  carbonImpact: number;
  challengeDisplayData: ChallengeDisplayData;
};

export type ChallengeProgress = {
  challengeCode: string;
  challengeData: Challenge;
  status: string;
  logCounter: number;
  challengeCompletedOn: Date;
  lastLogged: Date;
};

export type ChallengeDisplayData = {
  fact: string;
  source: Array<URL>;
  assumptionSource: Array<URL>;
};
