import {
  View,
  Text,
  ImageBackground,
  StyleSheet,
  TouchableOpacity,
  FlatList,
  ScrollView,
} from "react-native";
import React, { useEffect, useState } from "react";
import ChallengesBackground from "../Challenges/Common/challenges-background.component";
import ChallengesBody from "../Challenges/Common/challenges-body.component";
import AssignmentItem from "../Assignments/AssignmentItem/assignment-item.component";
import { Colors } from "../../../theme";
import {
  CarbonOffsetBox,
  ClimaPointsBox,
  StreakBox,
} from "../../components/NumberValueBoxes/number-value-boxes.component";
import { stringsEng } from "../../../assets/text/strings-english";
import CFTButton from "../../components/Button/cft-button.component";
import { Challenge, ChallengeProgress } from "../../types/challenge.types";
import { getUserChallenges } from "../../services/challenge";
import { MyChallengeItem } from "../../components/MyChallengeItem/my-challenge-item.component";
import { selectUser } from "../../redux/userSlice";
import { useSelector } from "react-redux";
import { getUserData } from "../../services/auth";
import Overlay from "../../components/LoadingOverlay/overlay.component";
import { useIsFocused } from "@react-navigation/native";
import { AssignmentType } from "../../types/assignment.types";
import { getAllAssignments } from "../../services/assignment";
import { updateUserData } from "../../redux/userSlice";
import { useAppDispatch } from "../../redux/store";

function ChallengesContainer({ navigation, challengesProgress, challenges }) {
  ////////////////////
  // TODO: challenges in redux
  const onPressStartNew = () => {
    navigation.navigate("NewChallenge");
  };
  const onPressChallenge = (challenge, challengeProgress) =>
    navigation.navigate("ChallengeDetails", { challenge, challengeProgress });

  const renderItem = ({ item }) => (
    <MyChallengeItem
      challenge={item}
      onPress={onPressChallenge}
      challengeProgress={challengesProgress.find(
        (c) => c.challengeCode === item.challengeCode
      )}
    />
  );

  if (challenges.length === 0) {
    return (
      <View>
        <CFTButton
          text={stringsEng.DASHBOARD.START_NEW}
          iconSource={require("../../../assets/images/icons/icon-plus-thin.png")}
          onPress={onPressStartNew}
          buttonStyle={styles.startNewBtn}
        />
      </View>
    );
  } else {
    return (
      <FlatList
        data={challenges}
        renderItem={renderItem}
        keyExtractor={(item: Challenge) => item.challengeCode}
      />
    );
  }
}

export default function ChallengesDashboard({ navigation }) {
  const [loaded, setLoaded] = useState(false);
  var user = useSelector(selectUser);

  const [challenges, setChallenges] = useState<Challenge[]>([]);
  const [userChallenges, setUserChallenges] = useState<Challenge[]>([]);
  const [challengesProgress, setChallengesProgress] = useState<
    ChallengeProgress[]
  >([]);
  const [displayAssignment, setDisplayAssignment] = useState<AssignmentType>();
  const [climaPoints, setPoints] = useState(user.userData.climaPointsEarned);

  const isFocused = useIsFocused();
  const dispatch = useAppDispatch();
  // listen for isFocused, if useFocused changes
  // call the function that you use to mount the component.

  useEffect(() => {
    const fetchData = async () => {
      const data = await getUserData(user.id);
      dispatch(updateUserData(data.data.userData));
      setPoints(data.data.userData.climaPointsEarned);
      const allClassAssignments = await getAllAssignments(
        user.userData.class[0]
      );
      const classAssignments = [];
      allClassAssignments.forEach((assignment) => {
        const assignmentProgress =
          data.data.userData.assignedAssignmentsProgress.find(
            (assignmentProgress) =>
              assignmentProgress.assignmentId === assignment._id
          );
        if (assignmentProgress) {
          // convert the dueDate in the assignment to a date object
          assignment.dueDate = new Date(assignment.dueDate);
          classAssignments.push({ ...assignment, ...assignmentProgress });
        }
      });
      setDisplayAssignment(
        classAssignments
          .sort((a, b) => a.dueDate.getTime() - b.dueDate.getTime())
          .find((assignment) => assignment.status !== "completed")
      );
      getUserChallenges(user.id)
        .then((challenges) => {
          setChallenges(challenges.slice(Math.max(challenges.length - 3, 0))); // Only show the last 3
          setUserChallenges(challenges);
        })
        .finally(() => {
          setTimeout(() => {
            setLoaded(true);
          }, 500);
        });
      setChallengesProgress(data.data.userData.inrolledchallenges);
    };
    // call the function
    isFocused &&
      fetchData()
        // make sure to catch any error
        .catch(console.error);
  }, [isFocused]);
  ////////////////////
  const onPressSeeMore = () => {
    navigation.navigate("ChallengesAndAssignments", {
      screen: "Challenges" /*, params: { userChallenges, challengesProgress }*/,
    });
  };

  if (!loaded) return <Overlay display={true} />;

  return (
    <ScrollView  style={{backgroundColor: Colors.lightGrey}}>
      <ChallengesBackground navigation={navigation}>
        <ImageBackground
          source={require("../../../assets/images/polar-bear.png")}
          style={styles.polarBearImg}
          resizeMode="contain"
        />

        <View style={styles.helloContainer}>
          <Text style={{ color: Colors.white }}>
            <Text style={{ fontSize: 24 }}>{stringsEng.DASHBOARD.GREETING} </Text>
            <Text style={{ fontSize: 24, fontWeight: "bold" }}>
              {user.firstName}
            </Text>
          </Text>
          <Text style={{ color: Colors.white }}>
            <Text style={{ fontSize: 16 }}>{stringsEng.DASHBOARD.HOW}</Text>
            <Text style={{ fontSize: 16, fontWeight: "bold" }}>
              {stringsEng.DASHBOARD.CLIMATE_ACTION}
            </Text>
          </Text>
        </View>
        <View style={styles.numberBoxContainer}>
          <StreakBox numberValue={user.userData.streak} />
          <CarbonOffsetBox numberValue={user.userData.carbonImpact.toFixed(3)} />
          <ClimaPointsBox numberValue={user.userData.climaPointsEarned} />
        </View>
        <ChallengesBody backgroundColor={Colors.lightGrey}>
          <View>
            {displayAssignment && (
              <AssignmentItem assignment={displayAssignment} userId={user.id} classId={user.userData.class[0]} />
            )}
          </View>
          <View style={styles.challengesHeaderContainer}>
            <Text style={styles.chalengesText}>
              {stringsEng.DASHBOARD.CHALLENGES}
            </Text>
            <TouchableOpacity style={{ padding: 5 }} onPress={onPressSeeMore}>
              <Text style={styles.seeMoreText}>
                {stringsEng.DASHBOARD.SEE_MORE}
              </Text>
            </TouchableOpacity>
          </View>
          <ChallengesContainer
            navigation={navigation}
            challengesProgress={challengesProgress}
            challenges={challenges}
          />

        </ChallengesBody>

      </ChallengesBackground >
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  polarBearImg: {
    position: "absolute",
    top: -20,
    left: 0,
    width: "60%",
    aspectRatio: 1,
  },
  helloContainer: {
    height: 80,
    // left 40% because we want it right of polarBearImg which is width 60%, and
    // width 100-40= 60% to make it fit to the page
    left: "40%",
    width: "60%",
    paddingRight: 10,
    marginTop: -20,
    marginBottom: 10,
  },
  numberBoxContainer: {
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: 10,
    marginTop: -10,
  },
  challengesHeaderContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  chalengesText: {
    fontSize: 20,
    color: Colors.darkGrey,
    fontFamily: "Poppins-semibold",
  },
  seeMoreText: {
    fontSize: 11,
    color: Colors.mediumGrey,
    fontFamily: "Poppins-semibold",
  },
  startNewBtn: {
    // alignSelf: "center",
    marginHorizontal: 20,
    borderRadius: 50,
    backgroundColor: Colors.purple,
  },
});
