import React, { useEffect, useState } from "react";
import { StyleSheet } from "react-native";
import BouncyCheckbox from "react-native-bouncy-checkbox";
import { Text, View } from "react-native-picasso";
import {
  MultiSelectAnswer,
  MultiSelectOption,
  MultiSelectQuestion,
  QuizItem,
} from "../../types/quiz.types";
import { Colors } from "../../../theme";

export type QuizMultiSelectProps = {
  currentQuizQuestion: QuizItem<MultiSelectQuestion>;
  setAnswer: (updatedAnswer: MultiSelectAnswer) => void;
};

export default function QuizMultiSelect({
  currentQuizQuestion,
  setAnswer,
}: QuizMultiSelectProps) {
  const [checkboxData, setCheckboxData] = useState<MultiSelectOption[]>(() =>
    setDefaults(currentQuizQuestion.choices)
  );

  function setDefaults(choices: MultiSelectOption[]) {
    return choices.map((choice) => ({
      ...choice,
      selected:
        typeof choice.selected === "boolean"
          ? choice.selected
          : Boolean(choice.defaultSelected),
    }));
  }

  useEffect(() => {
    setCheckboxData(setDefaults(currentQuizQuestion.choices));
  }, [currentQuizQuestion.question]);

  // Update checkbox data when the current quiz question changes
  // useEffect(() => {
  //   const data: MultiSelectOption[] = currentQuizQuestion.choices;
  //   // Update with default selected options
  //   setCheckboxData(
  //     data.map((value) => ({
  //       ...value,
  //       selected: Boolean(value.defaultSelected),
  //     }))
  //   );
  // }, [currentQuizQuestion]);

  // Update the answer for this question on checkbox update
  useEffect(() => {
    setAnswer({ ...currentQuizQuestion, choices: checkboxData });
  }, [checkboxData]);

  const onChoicePressed = (choiceIndex: number) => {
    setCheckboxData((data) => {
      data[choiceIndex].selected = !data[choiceIndex].selected;
      return [...data];
    });
  };

  if (checkboxData === null) return null;

  return (
    <View style={styles.multipleChoiceContainer}>
      {checkboxData.map((checkboxData, index) => (
        <BouncyCheckbox
          key={index}
          disableBuiltInState
          useNativeDriver={false}
          isChecked={checkboxData.selected}
          onPress={() => onChoicePressed(index)}
          unfillColor={Colors.white}
          textComponent={
            <Text className="color-white size-sm ml-sm">
              {(checkboxData.unselectedLabel !== undefined && !checkboxData.selected) ? checkboxData.unselectedLabel : checkboxData.label}
            </Text>
          }
          size={40}
          style={styles.checkbox}
          iconStyle={{ borderRadius: 2, borderColor: Colors.iceBlue }}
        />
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  multipleChoiceContainer: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    alignContent: "flex-start",
    justifyContent: "space-between",
    width: "90%",
  },
  checkbox: {
    flexBasis: "50%",
    marginBottom: 20,
  },
});
