import React, { useEffect, useState, useCallback, useContext } from "react";
import { Alert, ScrollView, StyleSheet, Linking, Modal, Pressable, FlatList, Image } from "react-native";
import { Text, View } from "react-native-picasso";
import { CategoryColors, Colors } from "../../../../theme";
import ChallengesBody from "../../Challenges/Common/challenges-body.component";
import { funFacts } from "../../../../assets/text/fun-facts";
import { stringsEng } from "../../../../assets/text/strings-english";
import CFTButton from "../../../components/Button/cft-button.component";
import FunFact from "../../../components/FunFact/fun-fact.component";
import {
    CarbonOffsetBox,
    ClimaPointsBox,
} from "../../../components/NumberValueBoxes/number-value-boxes.component";
import ChallengesBackground from "../../Challenges/Common/challenges-background.component";
import ProgressNumbers from "../../../components/ProgressNumbers/progress-numbers.component";
import {
    challengeDetailsMessages,
    defaultMessage,
} from "../../../../assets/text/challenge-details-messages-english";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/userSlice";
import { useNavigation } from '@react-navigation/native';
import { modalStyles } from "../../../components/Modal/modal.styles";
import Emoji from "../../../components/Emoji/emoji.component";
import GradientBackground from "../../../components/Background/gradient-background.component";
import PressableImage from "../../../components/Button/pressable-image.component";
import TeacherService from "../../../services/teacher.service";

export function DropDown({ assignmentId, colorStyle, options, classCode, userId, setHideAssignment }) {
    const [open, setOpen] = useState(false);

    const navigation = useNavigation();    

    function handleClick() {
        setOpen(!open)
    }

    async function deleteAssignment(){
        //.log("assignmentID: " + assignmentId + " classCode: " + classCode + " userId: " + userId)
        await TeacherService.deleteAssignment(assignmentId, classCode, userId)
        setHideAssignment(true)
    }


    function renderItem({ item }) {
        return (<View>
            <Text style={[styles.item]}
                onPress={async () => {  item.name === "Delete" ? deleteAssignment() :  item.name === "Edit" ? navigation.navigate(item.function, {params: { assignmentId: assignmentId }}) : navigation.navigate(item.function) }}>
                {item.name}
            </Text>
        </View>
        )
    };

    return (
        <View style={styles.link}>
            <PressableImage onPress={() => { handleClick() }} imageStyle={[styles.contextMenu, colorStyle]}
                imageSource={require("../../../../assets/images/icons/icon-other.png")} />
            <View style={[styles.menu, open ? styles.menuopen : '']}>
                <View style={styles.list}>
                    <FlatList
                        data={options}
                        renderItem={renderItem}
                        scrollEnabled={false}
                        keyExtractor={(item) => item.name}
                    />

                </View>
            </View>
        </View>
    )

}

export function AssignmentItem({ item, date, userId }) {
    const [hideAssignment, setHideAssignment] = useState(false)
    return (<>
        <View style={[styles.listItem, hideAssignment ? {display: "none"} : {display: "flex"}]}>
            <View style={styles.userInfo}>
                <View><Image style={styles.pic}
                    resizeMode="contain" source={require("../../../../assets/images/assignment.png")} /></View>
                <View style={styles.assignmentText}>
                    <Text style={styles.listItemText}>{item.assignmentName}</Text>
                    <Text style={styles.listItemSubtitle}>{stringsEng.TEACHER.ASSIGNMENT_LIST.DUE}{date}</Text>
                </View>
            </View>
            <DropDown assignmentId={item._id} colorStyle={{ tintColor: Colors.darkGrey }} options={item.options} classCode={item.associatedClassCode} userId={userId} setHideAssignment={setHideAssignment} />
        </View>
    </>
    )

}


export default function ClassStudentList({ navigation }) {
    const user = useSelector(selectUser);

    //type error because i added temporary value teacherClassData; might make permanenet later
    let assignments = user.teacherClassData.assignments;

    let inProgressAssignments = [...assignments].filter((a) => new Date() <= new Date(a.dueDate))
    let completedAssignments = [...assignments].filter((a) => new Date() > new Date(a.dueDate))

    function formatDate(date) {
        let newDate = new Date(date)
        return newDate.toDateString();
    }

    const CreateNew = [{
        name: stringsEng.TEACHER.ASSIGNMENT_LIST.CREATE_NEW,
        function: "CreateAssignment"
    }]
    const EditDelete = [{
        name: stringsEng.TEACHER.ASSIGNMENT_LIST.EDIT,
        function: "EditAssignment"
    }, {
        name: stringsEng.TEACHER.ASSIGNMENT_LIST.DELETE,
        function: null
    }]
    const Delete = [{
        name: stringsEng.TEACHER.ASSIGNMENT_LIST.DELETE,
        function: null
    }]

    inProgressAssignments = inProgressAssignments.map((a) => ({...a, options: EditDelete}))
    completedAssignments = completedAssignments.map((a) => ({...a, options: Delete}))


    function renderItem({ item }) {
        return (
           <AssignmentItem item={item} date={formatDate(item.dueDate)} userId={user.id}/>
        )
    };

    return (
        <>
            <GradientBackground />
            <ChallengesBackground navigation={navigation}>
                <ChallengesBody backgroundColor={Colors.lightGrey}>
                    <ScrollView contentContainerStyle={styles.scroll}>
                        <View style={styles.assignmentContainer} >
                            <View style={styles.headerBox}>
                                <Text style={styles.header}>{stringsEng.TEACHER.ASSIGNMENT_LIST.IN_PROGRESS}</Text>
                                <DropDown assignmentId={null} colorStyle={{ tintColor: Colors.turquoise }} options={CreateNew} />
                            </View>
                            <FlatList
                                data={inProgressAssignments}
                                renderItem={renderItem}
                                keyExtractor={(item) => item._id}
                                scrollEnabled={false}
                            />
                        </View>


                        <View style={styles.assignmentContainer}>
                            <View style={styles.headerBox}>
                                <Text style={styles.header}>{stringsEng.TEACHER.ASSIGNMENT_LIST.COMPLETED}</Text>

                            </View>
                            <FlatList
                                data={completedAssignments}
                                renderItem={renderItem}
                                keyExtractor={(item) => item._id}
                                scrollEnabled={false}
                            />

                        </View>


                    </ScrollView>
                </ChallengesBody>
            </ChallengesBackground>
        </>
    );
}

const styles = StyleSheet.create({
    assignmentContainer: {
        marginBottom: 20
    },
    scroll: {
        margin: 10,
    },
    pic: {
        borderRadius: 100,
        height: 45,
        width: 45,
        alignSelf: "center"
    },
    assignmentText: {
        flexDirection: "column"
    },
    headerBox: {
        borderColor: Colors.deepSkyBlue,
        borderBottomColor: Colors.deepSkyBlue,
        borderBottomWidth: 1,
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center"
    },
    listItem: {
        flexDirection: "row",
        flexWrap: "nowrap",
        justifyContent: "space-between",
        padding: 5,
        alignItems: "center",
        margin: 5,
        marginVertical: 15,
        width: "100%"
    },
    userInfo: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    listItemText: {
        color: Colors.darkGrey,
        whiteSpace: "nowrap",
        marginLeft: 15,
        fontFamily: "Poppins-semibold"
    },
    listItemSubtitle: {
        color: Colors.darkGrey,
        marginLeft: 15,
        fontFamily: "Poppins"
    },
    header: {
        color: Colors.deepSkyBlue,
        fontSize: 24,
        fontFamily: "Poppins-semibold"
    },
    addStudent: {
        height: 20,
        width: 20,
        marginRight: 15
    },
    contextMenu: {
        transform: [{ rotate: "90deg" }],
        height: 25,
        width: 25,
        marginRight: 10,

    },
    link: {
        textAlign: "center",
        cursor: "pointer",
        position: "relative"
    },

    menu: {
        opacity: 0,
        position: "absolute",
        right: 25,
        top: -25,
        backgroundColor: Colors.white,
        padding: 10,
        textAlign: "center",
        transition: "all 200ms ease",
        borderRadius: 15,
        shadowColor: Colors.darkGrey,
        shadowOpacity: 0.3,
        shadowRadius: 5,
    },

    menuopen: {
        opacity: 1,
    },

    item: {
        padding: 5,
        whiteSpace: "nowrap",
    },
    list: {
        margin: 0,
        padding: 0,
        textAlign: "left",

    },

});


