import { createSlice } from "@reduxjs/toolkit";

// TODO: This is currently unimplemented, I just wanted to include it so you can
// easily see how to include multiple reducers

const initialState = {
  // TODO: filter here?
};

const challengesSlice = createSlice({
  name: "challenges",
  initialState,
  reducers: {},
});

export const {} = challengesSlice.actions;
export default challengesSlice.reducer;
