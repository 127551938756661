import React from "react";
import OnboardingBackground from "../Common/onboarding-background.component";
import LoginForm from "./login-form.component";
import { View } from "react-native-picasso";
import PressableImage from "../../../components/Button/pressable-image.component";
import { styles as commonStyles } from "../Common/onboarding-common.styles";

export function Login({ navigation }) {
  function back() {
    navigation.navigate("Home", { name: "Home" });
  }

  return (
    <OnboardingBackground>
      <View style={commonStyles.mainFlexContainer}>
        <LoginForm navigation={navigation} />
      </View>
      <View style={commonStyles.backButtonFlexContainer}>
        <PressableImage
          imageSource={require("../../../../assets/back-button.png")}
          onPress={back}
          imageStyle={commonStyles.backButton}
        />
      </View>
    </OnboardingBackground>
  );
}
