import React from "react";
import { ImageBackground, Image, ImageSourcePropType } from "react-native";
import { View } from "react-native-picasso";
import { styles } from "./footprint-background.styles";
import GradientBackground from "../../../components/Background/gradient-background.component";
import HamburgerDrawerButton from "../../Challenges/Common/hamburger-drawer-button.component";


export type BackgroundProps = {
    navigation: Navigator;
    children: any;
};

export default function FootprintBackground({
    children,
    navigation
}: BackgroundProps) {

    return (
        <>
            <GradientBackground />

            <View style={styles.logoContainer}>
                <Image
                    source={require("../../../../assets/images/climact-logo.png")}
                    style={styles.logo}
                    resizeMode="contain"
                />
            </View>

            <View style={styles.top}>
                <HamburgerDrawerButton navigation={navigation} />
            </View>

            <ImageBackground
                source={require("../../../../assets/images/polar-bear_calculator.png")}
                style={{ position: "absolute", height: "80%", width: "100%", top: "37%" }}
            />

            {children}


        </>
    );
}
