import { View } from "react-native-picasso";
import { styles } from "./loading-overlay.styles";
import LoadingCircle from "./loading-circle.component";

export default function Overlay({display}) {
    return (
        <>

            <View style={[styles.overlay, display ? {display: 'flex'} : {display: 'none'}]}  />
            <LoadingCircle style={ display ? {display: 'flex'} : {display: 'none'}}/>

        </>
    );
}