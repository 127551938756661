import { StyleSheet } from "react-native";
import { Colors } from "../../../theme";

export const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    margin: 30,
    marginTop: 5,
    flex: 1,
    justifyContent: "space-evenly"
  },
  textView: {
    padding: 5,
  },
  input: {
    backgroundColor: Colors.white,
    flex: 1,
    width: "45%",
  },
  inputView: {
    borderColor: Colors.skyBlue,
    borderWidth: 2,
    borderRadius: 15,
    backgroundColor: Colors.white,
    margin: 10,
    padding: 10,
    flex: 1,
    flexDirection: "column"
  },
  textHeader: {
    color: Colors.white,
  },
  inputContainer: {
    margin: 5,
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  button: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.mediumBlue, 
  },
  msg: {
    flex: 3,
    paddingTop: "15%",
  }
});
