import React from "react";
import { View } from "react-native-picasso";
import { Image } from "react-native";
import { styles } from "./header-banner.styles";

export default function HeaderBanner() {
  return (
    <View style={styles.logoContainer}>
        <Image
          source={require("../../../assets/images/climact-logo.png")}
          style={styles.logo}
          resizeMode="contain"
        />
      </View>
  );
}
