import { StyleSheet } from "react-native";
import { Colors } from "../../../../theme";

export const styles = StyleSheet.create({
  createAccountMainFlexContainer: {
    flex: 5,
    justifyContent: "space-between",
  },
  checkbox: {
    paddingVertical: 10,
  },
  signUpButton: {
    backgroundColor: Colors.white,
    marginTop: 10,
    width: "70%",
    alignSelf: "center",
    padding: 5,
  },
  backButton: {
    opacity: 0.8,
    width: 100,
    height: 20,
    borderRadius: 16,
    padding: 10,
    alignSelf: "center",
    marginVertical: 20,
    marginLeft: -20
  }
});
